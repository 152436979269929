// * redux and axios imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from '@axios'


export const getBooks = createAsyncThunk("bookManager/getBooks", async (params) => {
  const response = await axios.post(`/bookManager/books/getpagedbooks`, params)
  return response
})
export const getBook = createAsyncThunk("bookManager/getBook", async (bookId) => {
  const response = await axios.get(`/bookManager/books/getBook/${bookId}`)
  return response
})

export const addBook = createAsyncThunk(
  "bookManager/addBook",
  async (model) => {
    const response = await axios.post(`/bookManager/books/AddBook`, model, {showToast : true})
    return response
  }
)

export const updateBook = createAsyncThunk("common/updateBook",
  async (model) => {
    const response = await axios.put(`/bookManager/Books/Put`, model, {showToast : true})
    return response
  }
)

export const removeBook = createAsyncThunk('bookManager/removeBook', async  (id, { dispatch }) => {
  await axios.delete(`/bookManager/Books/Delete/${id}`, {showToast : true})
  await dispatch(getClasses())
  return id
})

// * Quiz
export const getQuiz = createAsyncThunk(
  "quizManager/getQuiz",
  async (quizId) => {
    const response = await axios.get(
      `/quizManager/Quiz/GetQuiz`,
      { params: { id: quizId, includeAchievements:true, includeQuestionCells: false } }
    )
    return response
  }
)

// * Course
export const getCourseContents = createAsyncThunk("bookManager/getCourseContents", async (courseId) => {
  const response = await axios.get(`/quizManager/Course/GetCourseContents`, {params:{ id: courseId }})
  return response
})

// * units
export const getBookUnits = createAsyncThunk("bookManager/getBookContents", async (id) => {
  const response = await axios.get(`/bookmanager/Units/GetUnits?bookId=${id}`)
  return response
})

export const addUnit = createAsyncThunk("bookManager/Post", async (modal) => {
  const response = await axios.post(`/bookmanager/Units/Post`, modal, {showToast : true})
  return response
})

export const updateUnit = createAsyncThunk("bookManager/Put", async (modal) => {
  const response = await axios.put(`/bookmanager/Units/Put`, modal, {showToast : true})
  return response
})

export const deleteUnit = createAsyncThunk("bookManager/Delete", async (id) => {
  await axios.delete(`/bookmanager/Units/Delete/${id}`, {showToast : true})
  return id 
})

export const appAchievementSlice = createSlice({
  name: "bookManager",
  initialState: {
    books: {},
    selectedBook: {},
    units: [],
    selectedUnit: {},
    selectedContent: {},
    selectedQuiz:{},
    courseContents:[],
    bookOptions: {
      pagingOptions: {
        sortClause: "",
        orderBy: "",
        pageIndex: 0,
        pageSize: 5
      },
      spec: {
        title: "",
        isbn: "",
        classIds: [],
        branchIds: []
      }
    }
  },
  reducers: {
    setSelectedBook: (state, action) => {
      state.selectedBook = action.payload
    },
    setBookOptions: (state, action) => {
      state.bookOptions = action.payload
    },
    setSelectedUnit: (state, action) => {
      state.selectedUnit = action.payload
    },
    setSelectedContent: (state, action) => {
      state.selectedContent = action.payload
    },
    updateQuizQuestion: (state, action) => {
      const quiz = state.selectedQuiz
      quiz.questions = quiz.questions.map(p => (p.id === action.payload.data.id ? action.payload.data : p))
      state.selectedQuiz = quiz
    },
    updateCourseContents: (state, action) => {
      let courseContents = state.courseContents
      courseContents = courseContents.map(p => (p.subject !== null ? (p.subject.id === action.payload.data.id ? {...p, subject: action.payload.data } : p) : (p.question.id === action.payload.data.id ? {...p, question: action.payload.data } : p)))
      state.courseContents = courseContents
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBooks.fulfilled, (state, action) => {
        state.books = action.payload.data
      })
      .addCase(getBook.fulfilled, (state, action) => {
        state.selectedBook = action.payload.data
      })
      .addCase(getBookUnits.fulfilled, (state, action) => {
        state.units = action.payload.data
      })
      .addCase(getCourseContents.fulfilled, (state, action) => {
        state.courseContents = action.payload.data
      })
      .addCase(getQuiz.fulfilled, (state, action) => {
        state.selectedQuiz = action.payload.data
      })
     
  }
})
export const { setSelectedBook, setBookOptions, setSelectedUnit, setSelectedContent, updateQuizQuestion, updateCourseContents } = appAchievementSlice.actions

export default appAchievementSlice.reducer
