// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** axios Imports
import axios from '@axios'

export const getScoreTypes = createAsyncThunk("common/getScoreTypes", async (model) => {
    const response = await axios.post(`/common/ScoreType/GetScoreTypes`, model)
    return response
})

const scoreTypeSlice = createSlice({
  name: 'scoreTypeStore',
  initialState: {
    scoreTypes: []
  }, 
  reducers: {
  },
  extraReducers: builder => {
    builder
    .addCase(getScoreTypes.fulfilled, (state, action) => {
      state.scoreTypes = action.payload.data
    })
  }
})
export const {} = scoreTypeSlice.actions
export default scoreTypeSlice.reducer