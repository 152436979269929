import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from '@axios'


export const getSolutionBooks = createAsyncThunk("solutionManager/getSolutionBooks", async (params) => {
  
  const response = await axios.post(`/solutionManager/solutionBook/getpagedbooks`, params, {showLoader: true})
  return response
})
export const getBook = createAsyncThunk("solutionManager/getBook", async (bookId) => {
  const response = await axios.get(`/solutionManager/solutionBook/getBook/${bookId}`, {showLoader: true})
  return response
})
export const getBookUnits = createAsyncThunk(
  'solutionManager/getBookUnits',
  async (params) => {
    
    const {bookId, includeQuestions, includeSubjects, isUnSolved} = params
    const response = await axios.get(
      `/solutionManager/solutionBook/GetBookContentsTree?bookId=${bookId} 
      &includeQuestions=${includeQuestions}&includeSubjects=${includeSubjects}&isUnSolved=${isUnSolved}`, {showLoader: true}
    )
    return response
  }
)
export const getBookUnitContents = createAsyncThunk(
  'solutionManager/GetQuizWithCourseQuestions',
  async (params) => {
    
    const response = await axios.post(`/solutionManager/solutionBook/GetQuizWithCourseQuestions`, params, { showLoader: true })
    return response
  }
)

export const approveBookSolutions = createAsyncThunk("solutionManager/ApproveBookSolutions", async (params) => {
  
  const response = await axios.post(`/solutionManager/solutionBook/ApproveBookSolutions`, params, {showLoader:true, showToast:true})
  return response
})
export const getBookQuestionComments = createAsyncThunk(
  'quizmanager/getPagedBookQuestionComments',
  async (params) => {
    
    const response = await axios.post(
      `/quizmanager/QuestionComment/GetPagedBookQuestionComments`,
      params, {showLoader: true}
    )
    return response
  }
)
export const getBookSolutionComments = createAsyncThunk(
  'solutionManager/getPagedBookSolutionComments',
  async (params) => {
    const response = await axios.post(
      `/solutionManager/SolutionComment/GetPagedBookSolutionComments`,
      params, {showLoader: true}
    )
    return response
  }
)
export const updateSolutionComment = createAsyncThunk(
  'solutionManager/PutSolutionComments',
  async (params) => {
    
    const response = await axios.put(
      `/solutionmanager/SolutionComment/Put`,
      params, {showLoader:true, showToast:true}
    )
    return response
  }
)
export const getPagedSolutionReports = createAsyncThunk(
  'solutionManager/GetSolutionReports',
  async (params) => {
    
    const response = await axios.post(
      `/solutionManager/solutionBook/GetSolutionReports`,
      params, {showLoader: true}
    )
    return response
  }
)
export const solutionBookSlice = createSlice({
  name:"solutionBooks",
  initialState:{
    books:{},
    selectedBook: {},
    bookUnits:{},
    bookUnitContents:{},
    selectedContent:{},
    questionComments:[],
    solutionComments:[],
    solutionReports:[],
    bookOptions: {
      pagingOptions: {
        sortClause: "Id",
        orderBy: "desc",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
      
      }
    },
    contentOptions:{
      quizId:null,
      courseId:null,
      isUnSolved:false
    },
    questionCommentOptions : {
      pagingOptions : {
        sortClause: "isSolved Asc ,updatedOn Desc, Id",
        orderBy: "desc",
        pageIndex: 0,
        pageSize: 10
      },
       spec:{
        id:null,
        userId:null,
        questionId:null,
        isSolved:null,
        bookIds:null
       }
    },
    solutionCommentOptions : {
      pagingOptions : {
        sortClause: "questionCommentStatus Asc, updatedOn Desc, Id",
        orderBy: "desc",
        pageIndex: 0,
        pageSize: 10
      },
       spec:{
        id:null,
        userId:null,
        questionId:null,
        bookId:null,
        commentStatus:null,
        commentType:null
       }
    },
    unitOptions:{
      includeQuestions:false,
      includeSubjects:false,
      isUnSolved:false,
      bookId:null
    },
    solutionReportOptions: {
      pagingOptions: {
        sortClause: "Id",
        orderBy: "desc",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        bookId: null
      }
    }
  },
  reducers :{
    setBookOptions : (state, action) => {    
        state.bookOptions = action.payload
    },
    setSelectedContent : (state, action) => {
      state.selectedContent = action.payload
    },
    setContentOptions: (state, action) => {  
      state.contentOptions = action.payload
    },
    setQuestionCommentOptions :(state, action) => {     
      state.questionCommentOptions = action.payload
    },
    setSolutionCommentOptions :(state, action) => {    
      state.solutionCommentOptions = action.payload
    },
    setUnitOptions:(state, action) => {
      state.unitOptions = action.payload
    },
    setSolutionReportOptions: (state, action) => {
      state.solutionReportOptions = action.payload
    }
  },
  extraReducers :(builder) => {
    builder.addCase(getSolutionBooks.fulfilled, (state, action) => {     
      state.books = action.payload.data
    })
    builder.addCase(getBook.fulfilled, (state, action) => {
      state.bookUnitContents = {}
      state.bookUnits = {}
      state.selectedContent = {}
      state.selectedBook = action.payload.data
      state.unitOptions.bookId = action.payload.data.id

    })
    builder.addCase(getBookUnits.fulfilled, (state, action) => {
      const data =  action.payload.data.children
      state.bookUnits = data
    })
    builder.addCase(getBookUnitContents.fulfilled, (state, action) => {
      
      state.bookUnitContents = action.payload.data
    })
    builder.addCase(getBookQuestionComments.fulfilled, (state, action) => {
      
      state.questionComments = action.payload.data
    })
    builder.addCase(getBookSolutionComments.fulfilled, (state, action) => {
      state.solutionComments = action.payload.data
    })
    builder.addCase(getPagedSolutionReports.fulfilled, (state, action) => {
      state.solutionReports = action.payload.data
    })
  }
})
export const { 
setBookOptions,
setContentOptions,
setSelectedContent,
setQuestionCommentOptions,
setSolutionCommentOptions,
setUnitOptions,
setSolutionReportOptions
 } = solutionBookSlice.actions

export default solutionBookSlice.reducer