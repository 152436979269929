// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from '@axios'


export const getPagedSectionVideos = createAsyncThunk("SectionVideos/GetPagedSectionVideos", async (sectionVideoOptions) => {
  const response = await axios.post(`/videolessons/SectionVideos/GetPagedSectionVideos`, sectionVideoOptions)
  return response 
})
export const createSectionVideos = createAsyncThunk("SectionVideos/Create", async (model) => {
  const response = await axios.post(`/videolessons/SectionVideos/Create`, model, {showToast : true})
  return response
})
export const bulkCreateSectionVideos = createAsyncThunk("SectionVideos/BulkCreate", async (model) => {
  const response = await axios.post(`/videolessons/SectionVideos/BulkCreate`, model, {showToast : true})
  return response
})
export const updateSectionVideos = createAsyncThunk("SectionVideos/Update", async (model) => {
  const response = await axios.put(`/videolessons/SectionVideos/Update`, model, {showToast : true})
  return response
})
export const deleteSectionVideos = createAsyncThunk("SectionVideos/Delete/", async (id) => {
  await axios.delete(`/videolessons/SectionVideos/Delete/${id}`, {showToast : true})
  return id
})

export const appSectionVideoSlice = createSlice({
  name: "sectionVideo",
  initialState: {
    sectionVideo: [],
    selectedSectionVideo : {},
    sectionVideoOptions: {
      pagingOptions: {
        sortClause: "desc",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        sectionId: null,
        videoItemId: null,
        isFree: null
      }
    }
  },
  reducers: {
    setSelectedSectionVideo: (state, action) => {
      state.selectedSectionVideo = action.payload
    },
    setSectionVideoOptions: (state, action) => {
      state.sectionVideoOptions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getPagedSectionVideos.fulfilled, (state, action) => {
      state.sectionVideo = action.payload.data
    })
  }
})
export const { 
  setSelectedSectionVideo, 
  setSectionVideoOptions
 } = appSectionVideoSlice.actions
export default appSectionVideoSlice.reducer
