import axios from "@axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { pagingOptions } from "@configs/appConstants"

export const getStudents = createAsyncThunk(
  "organization/getStudents",
  async (schoolOptions) => {
    const response = await axios.post(
      `/examStatistics/studentExams/getStudents`,
      schoolOptions,
      { showLoader: true }
    )
    return response
  }
)

export const getStudentExamResults = createAsyncThunk(
  `studentResults/getStudentExamResults`,
  async ({ studentId, categoryId }) => {
    const queryString = studentId ? `${categoryId}/${studentId}` : `${categoryId}`
    return axios.get(
      `examStatistics/studentExams/getStudentExamResults/${queryString}`,
      { showLoader: true }
    )
  }
)

const studentExamResultSlice = createSlice({
  name: "studentExamResultSlice",
  initialState: {
    options: {
      pagingOptions: { ...pagingOptions, pageSize: 16 },
      spec: {
        userType: 1,
        firstName: "",
        lastName: "",
        Email: "",
        isFilter: true,
        classIds: [],
        branchIds: [],
        classRoomIds: [],
        fullName: ""
      }
    },
    studentList: [],
    studentExamResults: {},
    studentExamResultLoadStatus: "idle"
  },
  reducers: {
    setStudentOptions: (state, action) => {
      state.options = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getStudents.fulfilled, (state, action) => {
      state.studentList = action.payload.data
    })
    builder.addCase(getStudentExamResults.pending, (state) => {
      state.studentExamResultLoadStatus = "loading"
    })
    builder.addCase(getStudentExamResults.fulfilled, (state, action) => {
      state.studentExamResultLoadStatus = "complete"
      state.studentExamResults = action.payload.data
    })

  }
})

export const {
  setStudentOptions
} = studentExamResultSlice.actions
export default studentExamResultSlice.reducer
