import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "@axios"

import { convertToSelectOption } from '@utils'

export const getExamFileTypes = createAsyncThunk('examFile/GetExamFileTypes', async () => {
    const response = await axios.get(`/onlineExams/examFile/getExamFileTypes`)
    return response
})

export const getPagedExamFiles = createAsyncThunk('examFile/getPagedExamFiles', async (options) => {
    const response = await axios.post(`/onlineExams/examFile/getPagedExamFiles`, options)
    return response
})

export const pdfToImage = createAsyncThunk('examFile/pdfToImage', async (id) => {
    const response = await axios.get(`/onlineExams/examFile/pdfToImage/${id}`)
    return response
})

export const addExamFile = createAsyncThunk('examFile/addExamFile', async (model) => {
    const response = await axios.post(`/onlineExams/examFile/addExamFile`, model, {showToast : true})
    return response
})

export const updateExamFile = createAsyncThunk('examFile/updateExamFile', async (model) => {
    const response = await axios.put(`/onlineExams/examFile/updateExamFile`, model, {showToast : true})
    return response
})

export const deleteExamFile = createAsyncThunk('examFile/deleteExamFile', async (id) => {
    const response = await axios.delete(`/onlineExams/examFile/deleteExamFile/${id}`, {showToast : true})
    return response
})

export const appExamFileSlice = createSlice({
    name: 'storage',
    initialState: {
        pagedExamFiles: {},
        examFileTypes: [],
        selectedExamFile: {},
        options: {
            pagingOptions: {
                sortClause: 'desc',
                orderBy: 'id',
                pageIndex: 0,
                pageSize: 10
            },
            spec: {
                onlineExamId: null,
                fileId: null,
                fileName: null,
                examFileType: null
            }
        }
    },
    reducers: {
        setOptions: (state, action) => {
            state.options = action.payload
        },
        setSelectedExamFile: (state, action) => {
            state.selectedExamFile = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPagedExamFiles.fulfilled, (state, action) => {
                state.pagedExamFiles = action.payload.data
            })
        builder
            .addCase(getExamFileTypes.fulfilled, (state, action) => {
                state.examFileTypes = convertToSelectOption(action.payload.data)
            })
    }
})

export const { setOptions, setSelectedExamFile } = appExamFileSlice.actions
export default appExamFileSlice.reducer