import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from '@axios'
import { Status } from "../../../../Utils/Enums"

export const getSchoolExamDetails = createAsyncThunk('examResultsDetails/getDetails', async ({ schoolId, examId }) => {

  return await axios.get(`/examStatistics/schoolExams/getSchoolExamResultDetails/${examId}/${schoolId ?? ""}`, { showLoader: true })
})

const schoolExamResultDetailsSlice = createSlice({
  name: 'schoolExamResultDetailsSlice',
  initialState: {
    schoolExamDetails: {},
    schoolExamDetailsStatus: Status.Idle
  },
  extraReducers: builder => {
    builder.addCase(getSchoolExamDetails.fulfilled, (state, action) => {

      state.schoolExamDetails = action.payload.data
      state.schoolExamDetailsStatus = Status.Complete
    })
    builder.addCase(getSchoolExamDetails.pending, (state) => {
      state.schoolExamDetailsStatus = Status.Pending
    })
  }
})

export default schoolExamResultDetailsSlice.reducer