import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import axios from '@axios'
//classes


export const getUsers = createAsyncThunk("userManager/getUsers", async (params) => {
    const response = await axios.post(`/identity/Users/GetPagedUsers`, params)
    return response
  })

export const getUserPermissions = createAsyncThunk(
  "userManager/getUserPermissions",
  async (userId) => {
    const response = await axios
      .get(`/identity/permission/getUserPermissions`, {params: {userId}})
    return response
  }
)

export const userManagerSlice = createSlice({
  name: "userManaager",
  initialState: {
    users: [],
    selectedUser :{},
    userPermissions: null
  },
  reducers: {
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.users = action.payload.data
    })
    builder.addCase(getUserPermissions.fulfilled, (state, action) => {
      state.userPermissions = action.payload.data
    })
  }
})
export const { 

  setSelectedUser
 } = userManagerSlice.actions

export default userManagerSlice.reducer
