// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from '@axios'


export const getpagedVideoErrorReports = createAsyncThunk("VideoErrorReport/GetPagedReports", async (VideoErrorReportOptions) => {
  const response = await axios.post(`/videolessons/VideoErrorReport/GetPagedReports`, VideoErrorReportOptions)
  return response
})
export const createVideoErrorReport = createAsyncThunk("VideoErrorReport/Create", async (model) => {
  const response = await axios.post(`/videolessons/VideoErrorReport/Create`, model, {showToast : true})
  return response
})
export const updateVideoErrorReport = createAsyncThunk("VideoErrorReport/Update", async (model) => {
  const response = await axios.put(`/videolessons/VideoErrorReport/Update`, model, {showToast : true})
  return response
})
export const deleteVideoErrorReport = createAsyncThunk("VideoErrorReport/Delete/", async (id) => {
  const response = await axios.delete(`/videolessons/VideoErrorReport/Delete/${id}`, {showToast : true})
  return response
})

export const appVideoErrorReportSlice = createSlice({
  name: "videoErrorReport",
  initialState: {
    VideoErrorReport: [],
    selectedVideoErrorReport : {},
    VideoErrorReportOptions: {
      pagingOptions: {
        sortClause: "desc",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        videoItemId: null,
        comment: "",
        status: null,
        errorType: null
      }
    }
  },
  reducers: {
    setSelectedVideoErrorReport: (state, action) => {
      state.selectedVideoErrorReport = action.payload
    },
    setVideoErrorReportOptions: (state, action) => {
      state.VideoErrorReportOptions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getpagedVideoErrorReports.fulfilled, (state, action) => {
      state.VideoErrorReport = action.payload.data
    })  
  }
})
export const { 
  setSelectedVideoErrorReport,
  setVideoErrorReportOptions
 } = appVideoErrorReportSlice.actions

export default appVideoErrorReportSlice.reducer
