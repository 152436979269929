import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import axios from "@axios"

export const getLessonSubjects = createAsyncThunk('common/getAllLessonSubjects', async (id) => {
  const response = await axios.get(`/common/lessonSubject/getLessonSubjectModel?lessonId=${id}`)
  return response
})

export const appSubjectSlice = createSlice({
  name: 'subject',
  initialState: {
    selectableSubjects: [],
    selectedSubjects: []
  },
  reducers: {
    setSelectableSubjects: (state, action) => {
      state.selectableSubjects = action.payload
    },
    setSelectedSubjects: (state, action) => {
      state.selectedSubjects = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getLessonSubjects.fulfilled, (state, action) => {
        
        state.selectableSubjects = action.payload.data.selectableSubjects.map(el => {
          return {
           ...el,
           hour:0,
           distribution:0
          }
         })
       
        state.selectedSubjects = action.payload.data.selectedSubjects.result
        console.log(action.payload.data.selectedSubjects.result)
      })
  }
})

export const { setSelectableSubjects, setSelectedSubjects } = appSubjectSlice.actions
export default appSubjectSlice.reducer