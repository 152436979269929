import axios from "@axios"

import jwtDefaultConfig from "./jwtDefaultConfig"
import jwt_decode from "jwt-decode"
export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // ** For Refreshing Token
  subscribers = [];

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) => callback(accessToken)
    )
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }
  removeTokens() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  parsePermissions(permissions) {
    if (permissions) {
      let checkedPermissions = []
      if (!Array.isArray(permissions)) {
        checkedPermissions.push(permissions)
      } else {
        checkedPermissions = permissions
      }
      return permissions.map((permission) => {
        const [subject, action] = permission.split(":")
        return { action, subject }
      })
    }
  
  }

  getUser() {
    const token = this.getToken()
    let user = {}
    let permissions = {}
    if (token) {
      const decodedToken = jwt_decode(token)
      user = {
        id: decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'],
        firstName: decodedToken.given_name,
        lastName: decodedToken.family_name,
        email: decodedToken.email,
        userName: `${decodedToken.given_name} ${decodedToken.family_name}`
      }
      permissions = this.parsePermissions(decodedToken.permission)
    }
    return { user, permissions }
  }

  async login(...args) {
    this.removeTokens()
    return axios
      .post(this.jwtConfig.loginEndpoint, ...args)
      .then((res) => {
        this.setToken(JSON.stringify(res.data.jsonWebToken.accessToken))
        this.setRefreshToken(JSON.stringify(res.data.refreshToken))
        return this.getUser() // Return the response object
      })
      .catch((err) => {
        // Handle any errors
        console.error(err)
        throw err
      })
  }

  logout() {
    this.revokeToken()
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    
    const refreshToken = JSON.parse(this.getRefreshToken())
    const accessToken = JSON.parse(this.getToken())
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    return axios.post(this.jwtConfig.refreshEndpoint, {
      accessToken: `${accessToken?.replace('"/"', "")}`,
      refreshToken: `${refreshToken.replace('"/"', "")}`
    })
  }
  revokeToken() {
    const refreshToken = JSON.parse(this.getRefreshToken())
    this.removeTokens()
    localStorage.removeItem("userData")
    return axios.post(this.jwtConfig.revokeTokenEndPoint, {
      refreshToken: `${refreshToken.replace('"/"', "")}`
    })
  }
}
