import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "@axios"
import { Status } from "../../Utils/Enums"

export const getSchoolExamResult = createAsyncThunk('schoolResults/getResults', async ({
  schoolId,
  categoryId
}) => {
  return await axios.get(`/examStatistics/schoolExams/getSchoolExamResults/${categoryId}${schoolId ? `/${schoolId}` : ""}`,
    { showLoader: true })
})

const schoolResultSlice = createSlice({
  name: 'schoolResultSlice',
  initialState: {
    schoolExamResults: {},
    schoolExamResultLoadStatus: Status.Idle
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSchoolExamResult.pending, (state) => {
      state.schoolExamResultLoadStatus = Status.Pending
    })
    builder.addCase(getSchoolExamResult.fulfilled, (state, action) => {
      state.schoolExamResults = action.payload.data
      state.schoolExamResultLoadStatus = Status.Complete
    })
  }
})

export const { } = schoolResultSlice.actions
export default schoolResultSlice.reducer

