// * redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// * axios Imports
import axios from "@axios"

export const getClassRooms = createAsyncThunk(
  "SelectUser/GetClassRooms",
  async (filters) => {
    const res = await axios.post(
      "/organisation/SelectUser/GetClassRooms",
      filters
    )
    return res
  }
)

export const getUsers = createAsyncThunk(
  "SelectUser/GetUsers",
  async (userFilters) => {
    const res = await axios.post(
      "/organisation/SelectUser/GetUsers",
      userFilters
    )
    return res
  }
)

const userSelectSlice = createSlice({
  name: "userSelect",
  initialState: {
    selectableSchools: [],
    selectableUserTypes: [],
    classRoomGroups: [],
    filters: {
      schoolIds: [],
      schoolUserTypes: [],
      classRoomIds: [],
      searchParams: ""
    },
    users: [],
    filteredUsers: [],
    selectedUsers: [],
    selectType: "classRoomSelect"
  },
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload
      if (
        state.filters.schoolIds.length !== 0 ||
        state.filters.schoolUserTypes.length !== 0 ||
        state.filters.classRoomIds.length !== 0
      ) {
        state.filteredUsers = state.users.filter((x) => {
          const schoolIds =
            state.filters.schoolIds.length === 0 ? true : state.users.schoolUserId === x.schoolId
          const schoolUserTypes =
            state.filters.schoolUserTypes.length === 0 ? true : state.filters.schoolUserTypes.includes(x.schoolUserType)
          const classRoomIds =
            state.filters.classRoomIds.length === 0 ? true : state.filters.classRoomIds.includes(x.classRoomId)
          return schoolIds && schoolUserTypes && classRoomIds
        })
      } else {
        state.filteredUsers = state.users
      }
    },
    setSelectedUsers: (state, action) => {
      state.selectedUsers = action.payload
    },
    setFilteredUsers: (state, action) => {
      state.filteredUsers = action.payload
    },
    setSelectType: (state, action) => {
      state.selectType = action.payload
    },
    setClassRooms: (state, action) => {
      state.classRooms = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClassRooms.fulfilled, (state, action) => {
        state.selectableSchools = action.payload.data.userSchoolList
        state.selectableUserTypes = action.payload.data.schoolUserTypeList
        if (
          action.payload.data?.classRoomList &&
          action.payload.data?.classRoomList.length > 0
        ) {
          const groupLabels = action.payload.data.classRoomList.map(
            (x) => x.groupLabel
          )
          const onlyUnique = (value, index, array) => array.indexOf(value) === index
          const classRoomGroups = groupLabels.filter(onlyUnique).map((x) => {
            return {
              label: x,
              classRooms:  action.payload.data.classRoomList.filter((y) => y.groupLabel === x)
            }
          })
          state.classRoomGroups = classRoomGroups
        }
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.filteredUsers = action.payload.data
        state.users = action.payload.data
      })
  }
})
export const { 
  setFilters, 
  setSelectedUsers, 
  setFilteredUsers, 
  setSelectType, setClassRooms } = userSelectSlice.actions
export default userSelectSlice.reducer