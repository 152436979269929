// * redux import
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// * axios import
import axios from "@axios"
// * constants
import { pagingOptions } from "@configs/appConstants"

export const getExams = createAsyncThunk("exams/getExams", async (examOptions) => {
  const response = await axios.post(`/onlineExams/OnlineExamPortal/GetPaged`, examOptions)
  return response
})

export const getExam = createAsyncThunk("exams/getExam", async () => {
  const response = await axios.get(`/onlineExams/OnlineExamPortal/Get?id=1`)
  return response.data
})

export const getExamCategories = createAsyncThunk('exams/getCategoriesForSelect', async () => {
  const response = await axios.get(`/common/examCategory/getCategoriesForSelect`)
  return response
})

const onlineExamSlice = createSlice({
  name: "onlineExams",
  initialState: {
    exams: [],
    examOptions: {
      pagingOptions,
      spec: {
        title: "",
        isbn: "",
        examCategoryIds: null
      }
    },
    currentExam: [],
    examTests: [],
    activeTest: "",
    currentQuestion: null
  },
  reducers: {
    setExamOptions: (state, action) => {
      state.examOptions = action.payload
    },

    setCurrentQuestion: (state, action) => {
      const questions = [].concat.apply([], state.examTests.map((test) => test.questions))
      state.currentQuestion = questions.find((x) => x.id === action.payload)
    },
    setUserAnswer: (state, action) => {
      const questions = [].concat.apply([], state.examTests.map((test) => test.questions))
      console.log(questions)
      const question = questions.find((x) => x.id === action.payload.questionId)
      if (question.userAnswer === action.payload.userAnswer) {
        question.userAnswer = null
      } else {
        question.userAnswer = action.payload.userAnswer
      }
      state.currentQuestion = question
    },
    setTest: (state, action) => {
      state.activeTest = action.payload
    },
    nextQuestion: (state) => {
      const questionList = []
      state.currentExam.sections.map((aa) => aa.branches.map((b) => questionList.push(b.questions))
      )
      const merged = [].concat.apply([], questionList)
      const currentIndex = merged.findIndex(
        (x) => x.id === state.currentQuestion.id
      )
      state.currentQuestion = merged[currentIndex + 1]
    },
    prevQuestion: (state) => {
      const questionList = []
      state.currentExam.sections.map((aa) => aa.branches.map((b) => questionList.push(b.questions))
      )
      const merged = [].concat.apply([], questionList)
      const currentIndex = merged.findIndex(
        (x) => x.id === state.currentQuestion.id
      )
      state.currentQuestion = merged[currentIndex - 1]
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExams.fulfilled, (state, action) => {
        state.exams = action.payload.data
        state.params = action.payload.params
        state.totalExams = action.payload.data.total
      })
      .addCase(getExam.fulfilled, (state, action) => {
        const testList = []
        state.currentExam = action.payload
        action.payload.sections.map((aa) => testList.push({
          id: aa.id,
          name: aa.title,
          questions: [].concat.apply([], aa.branches.map((branch) => branch.questions))
        }))
        state.examTests = testList
        state.currentQuestion = testList[0].questions[0]
        // state.activeTest = action.payload.exam.sections[0].title
      })
  }
})

export const {
  setExamOptions,

  setTest,
  prevQuestion,
  nextQuestion,
  setCurrentQuestion,
  setUserAnswer
} = onlineExamSlice.actions
export default onlineExamSlice.reducer
