import axios from "@axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { autoDownloader } from "@utils"

export const getPagedUserQuestionPools = createAsyncThunk('questionPoolPortal/getPagedUserQuestionPools', async (options) => {
  const response = await axios.post(`/questionPools/questionPoolPortal/getPagedUserQuestionPools`, options, {showLoader: true})

  return response
})

export const printPdf = (quizId, title) => {
  axios.get(`/questionPools/questionPoolPortal/printPdf/${quizId}`, { responseType: 'blob', showLoader: true }).then((response) => {
    autoDownloader(response, `${title}.pdf`)
  })
}

export const questionPoolPortalSlice = createSlice({
  name: 'questionPoolPortalStore',
  initialState: {
    userQuestionPools: [],
    userQuestionPoolsOptions: {
      pagingOptions: {
        sortClause: "Id",
        orderBy: "desc",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        title: ""
      }      
    }
  },
  reducers: {
    setQuestionPoolsOptions: (state, action) => {
      state.userQuestionPoolsOptions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPagedUserQuestionPools.fulfilled, (state, action) => {
      state.userQuestionPools = action.payload.data
    })

  }
})

export const { setQuestionPoolsOptions} = questionPoolPortalSlice.actions

export default questionPoolPortalSlice.reducer