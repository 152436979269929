// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/jwt'
import defineAbility from '@src/auth/defineAbility'


export const authSlice = createSlice({
  name: 'authStore',
  initialState: {
    userData: useJwt.getUser().user,
    abilities: defineAbility(useJwt.getUser().permissions)

  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload.user
      state.abilities = defineAbility(action.payload.permissions)

    },
    handleLogout: state => {
      state.userData = {}
    }
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
