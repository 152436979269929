import axios from '@axios'
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

export const getLessonSubjects = createAsyncThunk(
  'addQuestionPoolSlice/getLessonSubjects',
  async ({ lessonId, achievementIds }) => {
    try {
      const response = await axios.post(`/questionPools/questionPoolPortal/getLessonSubjects?id=${lessonId}`, achievementIds)
      return response.data // Sadece veriyi döndürüyoruz
    } catch (error) {
      throw error // Hata durumunda hatayı yeniden fırlatıyoruz
    }
  }
)

export const getSettings = createAsyncThunk('addQuestionPoolSlice/getSettings', async () => {
  const response = await axios.get(`/questionPools/questionPoolPortal/getSettings`)
  return response
})


export const getPagedQuestions = createAsyncThunk('questionPoolPortal/getPagedQuestions', async (options) => {
  const response = await axios.post(`/questionPools/questionPoolPortal/getPagedQuestions`, options, {showLoader : true})

  return response
})

/**
 * parent-child ilişkisiyle oluşturulmuş object array'i tamamen açar
 * 
 * @param subjects - toplu array
 * 
 * @returns açılmış array'i geri döner
 */
const flatSubjects = (subjects) => {
  let result = []

  subjects.forEach(subject => {
    result.push(subject)
    if (Array.isArray(subject.childAchievements)) {
      result = result.concat(flatSubjects(subject.childAchievements))
    }
  })
  return result
}

/**
 * verilen child'in tüm family memberlarına ulaşarak hepsinin quantitylerini toplayarak set eder.
 * 
 * @param subjects - subjectlerin bulunduğu object array
 * @param quantity - miktar
 * @param childId - miktarı değişen child'ın id'si
 * 
 */
const setQuantityFromChildToParent = (subjects, quantity, childId, isDisabled, questionIds) => {
  let childSubject = subjects.find(x => x.id === childId)
  childSubject.questionIds = questionIds
  childSubject.quantity = quantity
  if (isDisabled === true) {
    childSubject.isDisabled = true
  }
  while (childSubject.parentId !== null) {
    const parentSubject = subjects.find(s => s.id === childSubject.parentId)
    parentSubject.quantity = parentSubject.childAchievements.reduce((acc, curr) => acc + (curr.quantity ? curr.quantity : 0), 0)
    parentSubject.isDisabled = parentSubject.quantity > 0
    if (parentSubject.parentId !== null) {
      childSubject = parentSubject
    } else {
      break
    }
  }
}

/**
 * verilen parentin tüm node'larını, varsa node'larının da node'larının quantitysini resetleyen recursive metot
 * 
 * @param {*} parent  parent
 */
const resetQuantityAllNodes = (parent) => {

  parent.quantity = 0
  parent.isDisabled = false
  parent.childAchievements.forEach(achievement => {
    achievement.isDisabled = false
    achievement.quantity = 0
    if (Array.isArray(achievement.childAchievements)) {
      resetQuantityAllNodes(achievement)
    }
  })
}

export const createUserQuestionPoolSlice = createSlice({
  name: 'createUserQuestionPoolSlice',
  initialState: {
    poolSettings: {},
    lessonSubjects: [],
    questions: [],
    questionOptions: {
      pagingOptions: {
        sortClause: "Id",
        orderBy: "asc",
        pageIndex: 0,
        pageSize: 6
      },
      spec: {
        achievementId: null
      }      
    }
  },
  reducers: {
    setPoolSettings: (state, action) => {
      state.poolSettings = action.payload
    },
    setQuestionOptions: (state, action) => {
      state.questionOptions = action.payload
    },
    setQuestions : (state, action) => {
      state.questions = action.payload
    },
    setLessonSubjects: (state, action) => {
      //https://stackoverflow.com/questions/63885139/how-can-i-get-the-state-value-in-the-reducer-of-createslice
      const existingLessonSubjects = JSON.parse(JSON.stringify(state.lessonSubjects))
      const subjectQuantityChanged = action.payload
      if (action.payload.lessonId) {
        const lessonSubjects = existingLessonSubjects.find(x => x.lessonId === action.payload.lessonId).subjects
        const flattedSubjects = flatSubjects(lessonSubjects)

        setQuantityFromChildToParent(flattedSubjects, subjectQuantityChanged.data.quantity, subjectQuantityChanged.data.id, subjectQuantityChanged.data.isDisabled, subjectQuantityChanged.data.questionIds)
      } else {
        existingLessonSubjects.forEach(ls => {
          const deleteElement = ls.subjects.find(x => x.id === action.payload.data.id)
          if (!deleteElement) {
            return
          }
          resetQuantityAllNodes(deleteElement)
        })
      }

      state.lessonSubjects = existingLessonSubjects
    },
    resetLessonSubjects: (state) => {
      state.lessonSubjects = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPagedQuestions.fulfilled, (state, action) => {
      state.questions = action.payload.data
    })
    
    builder.addCase(getLessonSubjects.fulfilled, (state, action) => {
      
      const existingLessonSubjects = JSON.parse(JSON.stringify(state.lessonSubjects))      
      state.lessonSubjects = [...existingLessonSubjects, action.payload]
    })
    builder.addCase(getSettings.fulfilled, (state, action) => {
      const setting = action.payload.data.reduce((obj, cur) => ({ ...obj, [cur.key]: { displayName: cur.displayName, value: cur.objectValue } }), {})
      state.poolSettings = setting
    })
  }
})

export const { setPoolSettings, setQuestions, setLessonSubjects, setQuestionOptions, resetLessonSubjects } = createUserQuestionPoolSlice.actions
export default createUserQuestionPoolSlice.reducer
