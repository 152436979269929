// * redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// * axios Imports
import axios from '@axios'

//  * Lecture Endpoints
export const getLectures = createAsyncThunk('Lecture/GetAll', async (lectureOptions) => {
    const response = await axios.post("LectureManager/LectureDate/GetAll", lectureOptions, { showLoader: true })
    return response
})
// * Lecture Date Endpoints
export const updateLectureDate = createAsyncThunk('LectureDate/update', async (event) => {
    const model = {
        Id: event.extendedProps.dateId,
        StartDate: event.start,
        EndDate: event.end,
        LectureId: event.extendedProps.lectureId
    }
    const response = await axios.put("LectureManager/LectureDate/UpdateLectureDate", model, { showLoader: true, showToaster: true })
    return response
})

export const getLectureDate = createAsyncThunk('LectureDate/Get', async (id) => {
    
    const response = await axios.get(`LectureManager/LectureDate/Get?id=${id}`, {showLoader: true})
    return response
})

// * Lecture User Endpoints
export const getPagedLectureUsers = createAsyncThunk('LectureUser/GetPaged', async (lectureUserOptions) => {
    const response = await axios.post('/lecturemanager/LectureUser/GetPaged', lectureUserOptions, { showLoader: true })
    return response
})

export const getSelectedLectureUsers = createAsyncThunk('LectureUser/GetSelectedUser', async (lectureDateIds) => {
    
    const response = await axios.post('/lecturemanager/LectureUser/GetPaged', {
        pagingOptions: {
            sortClause: "desc",
            orderBy: "id",
            pageIndex: 0,
            pageSize: 100000
        },
        spec: {
            lectureDateIds
        }
    })
    return response
})

export const appLecturePortalSlice = createSlice({
    name: 'lectureStore',
    initialState: {
        // * lecture states
        lectures: {},
        lectureOptions: {
            lectureTitle: null,
            lectureDescription: null,
            lessonId: null,
            creatorUserId: null,
            meetingId: null,
            classRoomIds: null,
            branchIds: null,
            lectureType: null,
            schooldId: null,
            startDate: null,
            endDate: null,
            lectureId: null
        },
        events: [],
        selectedEvent: {},
        // * lecture date states
        lectureDates: {},
        lectureDateOptions: {
            Id: null,
            StartDate: null,
            EndDate: null,
            LectureId: null
        },
        selectedLectureDate: {},
        // * lecture user states
        lectureUsers: {},
        lectureUserOptions: {
            pagingOptions: {
                sortClause: "desc",
                orderBy: "id",
                pageIndex: 0,
                pageSize: 100000
            },
            spec: {
                lectureDateIds: null,
                joinDate: null,
                leaveDate: null,
                joinStatus: 0,
                classRoomId: 0
            }
        },
        selectedStudentList: {}
    },
    reducers: {
        setSelectedEvent: (state, action) => {
            state.selectedEvent = action.payload
        },
        setSelectedStudentList: (state, action) => {
            state.selectedStudentList = action.payload
        },
        setLectureOptions: (state, action) => {
            
            state.lectureOptions = action.payload
        },
        setSelectedLectureDate: (state, action) => {
            
            state.selectedLectureDate = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getLectures.fulfilled, (state, action) => {
                
                state.lectures = action.payload.data
                const eventList = []
                action.payload.data.map(x => {
                    const model = {
                        title: x.lecture.title,
                        start: x.startDate,
                        end: x.endDate,
                        allDay: false,
                        extendedProps: {
                            dateId: x.id,
                            lectureId: x.lecture.id,
                            calendar: x.lecture.lesson.branch.color,
                            description: x.lecture.description,
                            startDate: x.startDate,
                            endDate: x.endDate,
                            meetingUrl: x.meeting?.meetingUrl,
                            meetingId: x.meeting?.id
                        },
                        color: x.lecture.lesson.branch.color
                    }
                    eventList.push(model)
                })
                state.events = eventList
            })
            .addCase(getPagedLectureUsers.fulfilled, (state, action) => {
                state.lectureUsers = action.payload.data
            })
            .addCase(getSelectedLectureUsers.fulfilled, (state, action) => {
                
                state.selectedStudentList = action.payload.data
            })
            .addCase(getLectureDate.fulfilled, (state, action) => {
                
                state.selectedLectureDate = action.payload.data
            })
    }
})
export const {
    setSelectedStudentList,
    setSelectedEvent,
    setLectureOptions
} = appLecturePortalSlice.actions
export default appLecturePortalSlice.reducer