import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit"
import axios from "@axios"


import { autoDownloader } from "@utils"
// ** Immer import
import { setAutoFreeze } from 'immer'
import moment from 'moment'
setAutoFreeze(false)
export const getPagedOnlineExams = createAsyncThunk(
  "onlineExams/getPagedOnlineExams",
  async (pagingOptions) => {
    const response = await axios.post(
      `/onlineExams/onlineExam/getPagedOnlineExams`,
      pagingOptions
    )
    return response
  }
)

export const getExamCategories = createAsyncThunk(
  "examCategory/getCategoriesForSelect",
  async () => {
    const response = await axios.get(
      `/common/examCategory/getCategoriesForSelect`
    )
    return response
  }
)

export const getOnlineExam = createAsyncThunk(
  "onlineExams/getOnlineExam",
  async (id) => {
    const response = await axios.get(
      `/onlineExams/onlineExam/getOnlineExam/${id}`
    )
    return response
  }
)

export const addOnlineExam = createAsyncThunk(
  "onlineExams/addOnlineExam",
  async (model) => {
    const response = await axios.post(
      `/onlineExams/onlineExam/AddOnlineExam`,
      model,
      { showToast: true }
    )
    return response
  }
)

export const updateOnlineExam = createAsyncThunk(
  "onlineExams/putOnlineExam",
  async (model) => {
    const response = await axios.put(
      `/onlineExams/onlineExam/putOnlineExam`,
      model,
      { showToast: true }
    )
    return response
  }
)

export const deleteOnlineExam = createAsyncThunk(
  "onlineExams/deleteOnlineExam",
  async (id) => {
    const response = await axios.delete(
      `/onlineExams/onlineExam/deleteOnlineExam/${id}`,
      { showToast: true }
    )
    return response
  }
)

export const getExamQrCodes = createAsyncThunk(
  "onlineExams/getPagedExamQrCodes",
  async (pagingOptions) => {
    const response = await axios.post(
      `/onlineExams/examQrCode/GetPagedExamQrCodes`,
      pagingOptions
    )
    return response
  }
)
export const adQrCode = createAsyncThunk(
  "onlineExams/CreateExamQrCodes",
  async (model) => {
    const response = await axios.post(
      `/onlineExams/ExamQrCode/CreateExamQrCodes`,
      model,
      { showToast: true }
    )
    
    return response
  }
)
export const bulkDownloadExamQrCode = createAsyncThunk('onlineExams/downloadExamQrCode', async (data) => {
  const response = await axios.post(`/onlineExams/ExamQrCode/DownloadExamQrCodes`, data.model, {responseType: "blob", showLoader: true})
  .then((result) => {
    const {id, title} = data.selectedExam
    autoDownloader(result, `${id}-${title}-${moment(Date.now()).format("DD-MM-YYYY HH:mm")}`)
  })  
  return response
})
export const downloadQrCode = createAsyncThunk('qrcodes/downloadQrCode', async (data) => {
  const response = await axios.post(`/qrcodes/QRCodeDownload/DownloadQrCode`, data.model, {responseType: "blob", showLoader: true})
  .then((result) => {
    const {qrCodeTypeName, id} = data.selectedQrCode
    autoDownloader(result, `${id}-${qrCodeTypeName}-${moment(Date.now()).format("DD-MM-YYYY HH:mm")}`)
  })
  return response
})
export const getQrCodeDownType = createAsyncThunk('qrcodes/getQrCodeDownType', async () => {
  const response = await axios.get(`/qrcodes/QRCode/GetQrDownloadType`)
  return response
})
export const getQrCodeType = createAsyncThunk('bookmanager/getQrCodeType', async () => {
  const response = await axios.get(`/qrcodes/QRCode/GetQrCodeType`)
  return response
})

export const appOnlineExamSlice = createSlice({
  name: "storage",
  initialState: {
    pagedOnlineExams: [],
    examQrCodes: {},
    selectedExam: {},
    examContent: {},
    qrDownTypeOptions: [],
    options: {
      pagingOptions: {
        sortClause: "desc",
        orderBy: "Id",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        title: "",
        isbn: "",
        examCategoryId: undefined
      }
    },
    qrCodeOptions: {
      pagingOptions: {
        sortClause: "desc",
        orderBy: "Id",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        entityId: null,
        parentId: null,
        IsActive: null
      }
    }
  },
  reducers: {
    setExamContent: (state, action) => {
      state.selectedExam = action.payload
    },
    setOptions: (state, action) => {
      state.options = action.payload
    },
    setSelectedExam: (state, action) => {
      state.selectedExam = action.payload
    },
    updateExamQuestion: (state, action) => {
      const {sectionId, lessonId, question} = action.payload
       const currentExam = current(state.selectedExam)
      const updatedSections = currentExam.examSections.map((section) => {
        if (section.id === sectionId) {
          const updatedSectionLessons = section.sectionLessons.map((lesson) => {
            if (lesson.lessonId === lessonId) {
              // Create a new array of lessonQuestions with the updated question
              const updatedLessonQuestions = lesson.lessonQuestions.map((q) => {
                // Check if this is the question we want to update
                if (q.id === question.id) {
                  return question
                }
                // This is not the question we're updating, so just return it as is
                return q
              })
              // Return the updated lesson object
              return { ...lesson, lessonQuestions: updatedLessonQuestions }
            }
            // This is not the lesson we're updating, so just return it as is
            return lesson
          })
          // Return the updated section object
          return { ...section, sectionLessons: updatedSectionLessons }
        }
        // This is not the section we're updating, so just return it as is
        return section
      })
      state.selectedExam = {...state.selectedExam, examSections: updatedSections}
    },
    setQrCodeOptions: (state, action) => {
      state.qrCodeOptions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPagedOnlineExams.fulfilled, (state, action) => {
        state.pagedOnlineExams = action.payload.data
      })
      .addCase(getOnlineExam.fulfilled, (state, action) => {
        state.selectedExam = action.payload.data
      })
      .addCase(getExamQrCodes.fulfilled, (state, action) => {
        state.examQrCodes = action.payload.data
      })
      .addCase(getQrCodeDownType.fulfilled, (state, action) => {
        state.qrDownTypeOptions = action.payload.data
      })
  }
})

export const { setOptions, setExamContent, setSelectedExam, updateExamQuestion, setQrCodeOptions } =
  appOnlineExamSlice.actions
export default appOnlineExamSlice.reducer
