import axios from "axios"
import { toast } from "react-toastify"
import env from "../environments"
import useJwt from '@src/auth/jwt/jwt'
import jwtDefaultConfig from '@src/auth/jwt/jwtDefaultConfig'
import { Fragment, useState, useEffect } from "react"
import { Avatar } from "antd"
import { Check, X } from "react-feather"
import UILoader from "../@themes/vuexy/core/components/ui-loader"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

const instance = axios.create({
  baseURL: `${env.ResourceServer.Endpoint}`
})

const ErrorToast = ({ title }) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <Avatar size="sm" color="danger" icon={<X size={12} />} />
        <h6 className="toast-title">Hata!</h6>
      </div>
    </div>
    <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        {title ?? ''}
      </span>
    </div>
  </Fragment>
)

const SuccessToast = ({ title }) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <Avatar size="sm" color="success" icon={<Check size={12} />} />
        <h6 className="toast-title">Başarılı</h6>
      </div>
      <small className="text-muted"></small>
    </div>
    <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        {title}
      </span>
    </div>
  </Fragment>
)

export function AxiosInterceptor({ children }) {

  const [isLoading, setIsLoading] = useState(false)
  const MySwal = withReactContent(Swal)
  
  useEffect(() => {
    const requestInterceptor = instance.interceptors.request.use(async (config) => {

      const accessToken = JSON.parse(useJwt.getToken())
      if (accessToken) {
        config.headers.Authorization = `${jwtDefaultConfig.tokenType} ${accessToken.replace('"/"', "")}`
      }
      if (config.showLoader) {
        setIsLoading(true)
      }
      if (config.method === 'delete') {
        return new Promise((resolve) => {
          MySwal.fire({
            title: 'Silmek istediğinize emin misiniz?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Evet, sil !',
            cancelButtonText: "Hayır, iptal et",
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false
          }).then(function (result) {
            if (result.isConfirmed) {
              resolve(config)
            }
          })
        })
      } else {
        return config
      }
    }, (error) => {
      return Promise.reject(error)
    })

    const responseInterceptor = instance.interceptors.response.use((response) => {
      if (response.config.showLoader) {
        setIsLoading(false)
      }
      if (response.config.method === 'delete') {
        if ((response.data === '' || response.data.isSuccess)) {
          MySwal.fire({
            icon: 'success',
            title: 'Silindi !',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          })
        } else if (!response.data.isSuccess) {
          MySwal.fire({
            icon: 'error',
            title: 'Hata !',
            text: response?.data?.errors[0]?.message
          })
        }
      } else {
        if (response.config.showToast) {
          if (response.data.isSuccess === undefined) {
            if (response.status === 201 && response.data.errors === undefined) {
              toast.success(<SuccessToast title={response.data.message} />, {
                icon: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false
              })
            } else {
              toast.error(<ErrorToast title={response.data.errors[0].message} />, {
                icon: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false
              })
            }
          } else {
            if (response.data.isSuccess) {
              toast.success(<SuccessToast title={response.data.message} />, {
                icon: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false
              })
            } else if (response.data.errors && response.data.errors.length > 0) {
              toast.error(<ErrorToast title={response.data.errors[0].message} />, {
                icon: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false
              })
            }
          }
        }
      }
      return response
    }, async function (error) {
      const originalRequest = error.config
      if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        const newToken = await useJwt.refreshToken()
        
        setTimeout(() => {
          useJwt.setToken(JSON.stringify(newToken.data.jsonWebToken.accessToken))
          useJwt.setRefreshToken(JSON.stringify(newToken.data.refreshToken))
          axios.defaults.headers.common['Authorization'] = `${jwtDefaultConfig.tokenType} ${newToken.data.jsonWebToken.accessToken.replace('"/"', "")}`
          return instance(originalRequest)
        }, 200)
      }
      if (true) {
        setIsLoading(false)
      }
      if (error.config.method === 'delete') {
        MySwal.fire(
          'Hata!',
          'Silme İşleminde Hata Olmuştur. Tekrar Deneyiniz!',
          'error'
        )
      } else {
        if (originalRequest.showToast) {
          toast.error(<ErrorToast title={null} />, {
            icon: false,
            autoClose: 2000,
            hideProgressBar: true,
            closeButton: false
          })
        }
      }
      return Promise.reject(error)
    })

    return () => {
      instance.interceptors.request.eject(requestInterceptor)
      instance.interceptors.response.eject(responseInterceptor)
    }
  }, [])

  return (
    <UILoader
      blocking={isLoading}
      className={isLoading ? 'axios-loader' : ""}
      overlayColor='rgba(115, 103, 240, .1)'
    >
      {children}
    </UILoader>
  )
}

export default instance
