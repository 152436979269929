import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import axios from "@axios"
//classes
export const getSystemMenus = createAsyncThunk("common/getSystemMenus", async (params) => {
    const response = await axios.post(`/common/systemmenu/getpaged`, params)
    return response
  })


export const systemMenuStoreSlice = createSlice({
  name: "systemmenu",
  initialState: {
    systemMenus: [],
    selectedSystemMenu: {}
  
  },
  reducers: {
    setSelectedSystemMenu: (state, action) => {
        state.selectedSystemMenu = action.payload
      }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSystemMenus.fulfilled, (state, action) => {
        state.systemMenus = action.payload.data
      })
  }
})
export const {
    setSelectedSystemMenu
} = systemMenuStoreSlice.actions

export default systemMenuStoreSlice.reducer
