import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import axios from "@axios"

import { pagingOptions } from "../../../../../configs/appConstants"
import { autoDownloader } from "../../../../../utility/Utils"

//schools

export const getSchools = createAsyncThunk(
  "common/getSchools",
  async (schoolOptions) => {
    const response = await axios.post(
      `/common/School/GetPagedSchools`,
      schoolOptions
    )
    return response
  }
)

export const addSchool = createAsyncThunk("common/addSchool", async (model) => {
  const response = await axios.post(
    `/common/School/AddSchool`,
    model, { showToast: true }
  )
  return response
})

export const updateSchool = createAsyncThunk(
  "common/updateSchool",
  async (model) => {
    const response = await axios.put(
      `/common/School/UpdateSchool`,
      model, { showToast: true }
    )
    return response
  }
)

export const exportExcel = createAsyncThunk("common/exportExcel", async (spec) => {
  const response = await axios.post("/common/School/ExportAsExcel", spec, { responseType: 'blob', showLoader: true })
  .then((response) => autoDownloader(response, "Okullar_Listesi.xlsx"))
  return response
})

export const importExcel = createAsyncThunk("common/importExcel", async (model) => {
  const response = await axios.post("/common/School/ImportExcel", model, {showToast: true})
  return response
})

export const exportTemplateExcelSchools = createAsyncThunk("common/exportTemplateExcelSchools", async () => {
  const response = await axios.get("/common/School/ExportTemplateExcel", {
    responseType: 'blob'
  })
    .then((response) => autoDownloader(response, "Ornek_Okullar_Şablonu.xlsx"))
  return response
})

// School Setting
export const getSchoolSettings = createAsyncThunk(
  "common/getSchoolSettings",
  async (options) => {
    const response = await axios.post(
      `/common/SchoolSetting/GetPagedSchoolSettings`,
      options
    )
    return response
  }
)

export const getSchoolSetting = createAsyncThunk(
  "common/getSchoolSetting",
  async (id) => {
    const response = await axios.post(
      `/common/SchoolSetting/GetSchoolSetting/${id}`
    )
    return response
  }
)

export const addSchoolSetting = createAsyncThunk(
  "common/addSchoolSetting",
  async (model) => {
    const response = await axios.post(
      `/common/SchoolSetting/AddSchoolSetting`,
      model, { showToast: true }
    )
    return response
  }
)

export const updateSchoolSetting = createAsyncThunk(
  "common/updateSchoolSetting",
  async (model) => {
    const response = await axios.put(
      `/common/SchoolSetting/UpdateSchoolSetting`,
      model, { showToast: true }
    )
    return response
  }
)

export const removeSchoolSetting = createAsyncThunk(
  "common/removeSchoolSetting",
  async (id) => {
    await axios.delete(
      `/common/SchoolSetting/DeleteSchoolSetting/${id}`, { showToast: true }
    )
    return id
  }
)

export const appOrganizationSlice = createSlice({
  name: "organization",
  initialState: {
    schools: [],
    selectedSchool: {},
    schoolSettings: [],
    selectedSchoolSetting: {},
    schoolOptions: {
      pagingOptions,
      filterSpec: {
        districtId: null,
        schoolId: null,
        isMainSchool: null
      }
    },
    schoolSettingOptions: {
      pagingOptions,
      filterSpec: {
        educationYearName: "",
        schoolId: null
      }
    },
    schoolUserOptions: {
      pagingOptions: {
        sortClause: "Id",
        orderBy: "desc",
        pageIndex: 0,
        pageSize: 10
      },
      filterSpec: {
        name: ""
      }
    }
  },
  reducers: {
    setSelectedSchool: (state, action) => {
      state.selectedSchool = action.payload
    },
    setSchoolOptions: (state, action) => {
      state.schoolOptions = action.payload
    },
    setSelectedSchoolSetting: (state, action) => {
      state.selectedSchoolSetting = action.payload
    },
    setSchoolSettingOptions: (state, action) => {
      state.schoolSettingOptions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder

      .addCase(getSchools.fulfilled, (state, action) => {
        state.schools = action.payload.data
      })

      .addCase(getSchoolSettings.fulfilled, (state, action) => {
        state.schoolSettings = action.payload.data
      })
  }
})
export const {
  setSelectedSchool,
  setSchoolOptions,
  setSelectedSchoolSetting,
  setSchoolSettingOptions
} = appOrganizationSlice.actions

export default appOrganizationSlice.reducer
