import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import axios from "@axios"

export const getAnnouncements = createAsyncThunk(
  "announcements/getAnnouncements",
  async (pagingOptions) => {
    
    const response = await axios.post(
      `/announcements/Announcement/GetPaged`,
      pagingOptions,
      {showLoader:true}
    )
    return response
  }
)

export const announcementStoreSlice = createSlice({
  name: "announcementStore",
  initialState: {
    announcements: [],
    pagingOptions: {
      pagingOptions: {
        sortClause: "createOn",
        orderBy: "desc",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        title: "",
        classRoomIds: []
      }      
    }
  },
  reducers: {
    setPagingOptions: (state, action) => {
      state.pagingOptions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAnnouncements.fulfilled, (state, action) => {
      state.announcements = action.payload.data
    })
  }
})
export const {
  setPagingOptions
} =
announcementStoreSlice.actions

export default announcementStoreSlice.reducer
