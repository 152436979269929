// * redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// * axios Imports
import axios from "@axios"

// * assignment thunks
export const getPagedAssignment = createAsyncThunk(
  "Assignment/GetPaged",
  async (assignmentOptions) => {
    const response = await axios.post(
      "/assignments/Assignment/GetPaged",
      assignmentOptions,
      { showLoader: true }
    )
    return response
  }
)

export const getAssignmentResults = createAsyncThunk(
  "Assignment/GetAssignmentResults",
  async (assignmentId) => {
    const response = await axios.get(
      `/assignments/Assignment/GetAssignmentResults?assignmentId=${assignmentId}`,
      { showLoader: true }
    )
    return response
  }
)

export const getAssignmentContentResults = createAsyncThunk(
  "AssignmentContentResult/GetPaged",
  async (options) => {
    const res = await axios.post(
      "/assignments/AssignmentContentResult/GetPaged",
      options,
      { showLoader: true }
    )
    return res
  }
)

export const getAssignment = createAsyncThunk(
  "Assignment/GetById",
  async (assignmentId) => {
    const res = await axios.get(
      `assignments/Assignment/GetById?assignmentId=${assignmentId}`,
      { showLoader: true }
    )
    return res
  }
)

export const getAssignmentContentReport = createAsyncThunk(
  "AssignmentContentResult/GetById",
  async (contentId) => {
    const res = await axios.get(
      `assignments/AssignmentContentResult/GetAssignmentContentReport?assignmentContentId=${contentId}`,
      { showLoader: true }
    )
    return res
  }
)

const assignmentManageSlice = createSlice({
  name: "assignmentManage",
  initialState: {
    assignments: {},
    assignment: {},
    students: {},
    assignmentContentReport: {},
    contentResults: [],
    assignmentOptions: {
      pagingOptions: {
        sortClause: "Id",
        orderBy: "desc",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        title: "",
        lowStartDate: null,
        highStartDate: null,
        lowEndDate: null,
        highEndDate: null,
        creatorUserId: null,
        classRoomIds: [],
        branchIds: [],
        assignmentStatus: null
      }
    },
    assignmentResultOptions: {
      pagingOptions: {
        sortClause: "Id",
        orderBy: "desc",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        assignmentId:0,
        assignmentContentId:0
      }
    },
    assignmentResults: {},
    assignmentResultsFilters: {
      studentName: "",
      classRoomIds: [],
      assignmentStatus: []
    },
    assignmentContentPoint: []
  },
  reducers: {
    setAssignmentOptions: (state, action) => {
      state.assignmentOptions = action.payload
    },
    setAssignmentResultOptions :(state, action) => {
      state.assignmentResultOptions = action.payload
    },
    insertAssignmentContentPoints: (state, action) => {
      const entityId = action.payload.entityId
      const bookId = action.payload.bookId
      const points = action.payload.points

      const item = { entityId, bookId, points }

      const targetIndex = [...state.assignmentContentPoint].findIndex(
        (x) => x.entityId === entityId && x.bookId === bookId
      )
      if (targetIndex !== -1) {
        const updatedList = [...state.assignmentContentPoint]
        updatedList[targetIndex] = item

        state.assignmentContentPoint = updatedList
      } else {
        state.assignmentContentPoint = [...state.assignmentContentPoint, item]
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPagedAssignment.fulfilled, (state, action) => {
      state.assignments = action.payload.data
    })
    builder.addCase(getAssignment.fulfilled, (state, action) => {
      state.assignment = action.payload.data
    })
    builder.addCase(getAssignmentContentReport.fulfilled, (state, action) => {
      state.assignmentContentReport = action.payload.data
    })
    builder.addCase(getAssignmentContentResults.fulfilled, (state, action) => {
      state.contentResults = action.payload.data
    })
  
  }
})
export const {
  setAssignmentOptions,
  setAssignmentResultOptions,
  insertAssignmentContentPoints
} = assignmentManageSlice.actions
export default assignmentManageSlice.reducer
