import axios from '@axios'
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

//ExamCategory
export const getCategoriesForSelect = createAsyncThunk("common/getCategoriesForSelect", async () => {
    const response = await axios.get(`/common/ExamCategory/GetCategoriesForSelect`)
    return response
})

export const getCategories = createAsyncThunk("common/getCategories", async (model) => {
    const response = await axios.post(`/common/ExamCategory/GetCategories`, model)
    return response
})

export const getScoreTypeCoefficients = createAsyncThunk("common/getScoreTypeCoefficients", async (id) => {
    const response = await axios.get(`/common/ExamCategory/getScoreTypeCoefficients/${id}`, { showLoader: true })
    return response
})

export const removeCategory = createAsyncThunk("common/removeCategory", async (id) => {
    await axios.delete(`/common/ExamCategory/DeleteCategory/${id}`, { showToast: true })
    return id
})

export const addCategory = createAsyncThunk('common/createCategory', async (model) => {
    const response = await axios.post(`/common/ExamCategory/AddCategory`, model, { showToast: true })
    return response
})

export const updateCategory = createAsyncThunk('common/updateCategory', async (model) => {
    const response = await axios.put(`/common/ExamCategory/UpdateCategory`, model, { showToast: true })
    return response
})

// CategorySections
export const getSectionsByCategoryId = createAsyncThunk("common/getSectionsByCategoryId", async (model) => {
    const response = await axios.post(`/common/CategorySection/GetPagedSectionsByCategoryId?categoryId=${model.categoryId}`, model.params.pagingOptions)
    return response
})

export const removeSection = createAsyncThunk("common/removeSection", async (id) => {
    await axios.delete(`/common/CategorySection/DeleteSection/${id}`, { showToast: true })
    return id
})

export const addSection = createAsyncThunk('common/addSection', async (model) => {
    const response = await axios.post(`/common/CategorySection/AddSection`, model, { showToast: true })
    return response
})

export const updateSection = createAsyncThunk('common/updateSection', async (model) => {
    const response = await axios.put(`/common/CategorySection/UpdateSection`, model, { showToast: true })
    return response
})

//Branch
export const getBranches = createAsyncThunk("common/getBranches", async () => {
    const response = await axios.get(`/common/Branch/GetSelectableBranches`)
    return response
})


export const removeLesson = createAsyncThunk("common/removeLesson", async (id) => {
    await axios.delete(`/common/CategorySectionBranch/removeCategorySectionBranch/${id}`, { showToast: true })
    return id
})

export const addLesson = createAsyncThunk('common/addLesson', async (model) => {
    const response = await axios.post(`/common/CategorySectionBranch/AddCategorySectionBranch`, model, { showToast: true })
    return response
})

export const updateLesson = createAsyncThunk('common/updateLesson', async (model) => {
    const response = await axios.put(`/common/CategorySectionBranch/UpdateCategorySectionBranch`, model, { showToast: true })
    return response
})

//ScoreType
export const getScoreTypesForSelect = createAsyncThunk("common/getScoreTypesForSelect", async () => {
    const response = await axios.get(`/common/ScoreType/GetScoreTypesForSelect`)
    return response
})


//CategorySectionBranch
export const getLessonsBySectionId = createAsyncThunk("common/getLessonsBySectionId", async (model) => {
    const response = await axios.post(`/common/CategorySectionBranch/GetPagedBranchesByCategorySectionId?sectionId=${model.sectionId}`, model.params.pagingOptions)
    return response
})

export const getCategorySectionBranchesById = createAsyncThunk("common/getCategorySectionBranchesById", async (sectionId) => {
    const response = await axios.get(`/common/CategorySectionBranch/GetCategorySectionBranchesById/${sectionId}`)
    return response
})

// Coefficients
export const getCoefficientsByCategorySectionBranchIds = createAsyncThunk("common/GetCoefficientsByCategorySectionBranchIds", async (sectionBranchIdList) => {
    const response = await axios.post(`/common/BranchCoefficient/GetCoefficientsByCategorySectionBranchIds`, sectionBranchIdList)
    return response
})

export const updateBranchCoefficient = createAsyncThunk('common/UpdateBranchCoefficient', async (model) => {
    const response = await axios.put(`/common/BranchCoefficient/UpdateBranchCoefficient`, model, { showToast: true })
    return response
})
export const getPagedQuestionDistibutions = createAsyncThunk("common/getPagedQuestionDistibutions", async (params) => {
    const response = await axios.post(`/common/QuestionDistribution/GetPagedQuestionDistributions`, params)
    return response
})

export const appExamCategorySlice = createSlice({
    name: "examCategory",
    initialState: {
        categoriesForSelect: [],
        categories: [],
        category: null,
        categoryId: 0,
        selectedCategory: {},
        branchesForSelect: [],
        scoreTypesForSelect: [],
        sections: [],
        selectedSection: {},
        scoreTypes: [],
        selectedScoreType: {},
        branches: [],
        sectionBranchesModel: [],
        sectionBranch: {},
        selectedLesson: {},
        coefficients: [],
        questionDistributions: [],
        examCategoryOptions: {
            pagingOptions: {
                sortClause: "Id",
                orderBy: "desc",
                pageIndex: 0,
                pageSize: 10
            },
            spec: {
                isActive: true,
                classIds: []
            }
        },
        sectionOptions: {
            pagingOptions: {
                sortClause: "Id",
                orderBy: "desc",
                pageIndex: 0,
                pageSize: 10
            },
            spec: {

            }
        },
        lessonOptions: {
            pagingOptions: {
                sortClause: "Id",
                orderBy: "desc",
                pageIndex: 0,
                pageSize: 10
            },
            spec: {

            }
        },
        scoreTypeOptions: {
            pagingOptions: {
                sortClause: "Id",
                orderBy: "desc",
                pageIndex: 0,
                pageSize: 10
            },
            spec: {

            }
        },
        questionDistributionOptions: {
            pagingOptions: {
                sortClause: "Id",
                orderBy: "desc",
                pageIndex: 0,
                pageSize: 10
            },
            spec: {
            }
        }
    },
    reducers: {
        setSelectedScoreType: (state, action) => {
            state.selectedScoreType = action.payload
        },
        setCategoryId: (state, action) => {
            state.categoryId = action.payload
        },
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload
        },
        // setSelectedLessonArr : (state, action) => { gereksiz yazılmış
        //     state.selectedLessonArr = action.payload
        // },
        setSelectedSection: (state, action) => {
            state.selectedSection = action.payload
        },
        setSelectedLesson: (state, action) => {
            state.selectedLesson = action.payload
        },
        setExamCategoryOptions: (state, action) => {
            state.examCategoryOptions = action.payload
        },
        setSectionOptions: (state, action) => {
            state.sectionOptions = action.payload
        },
        setLessonOptions: (state, action) => {
            state.lessonOptions = action.payload
        },
        setScoreTypeOptions: (state, action) => {
            state.scoreTypeOptions = action.payload
        },
        setQuestionDistributionOptions: (state, action) => {
            state.questionDistributionOptions = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCategoriesForSelect.fulfilled, (state, action) => {
                state.categoriesForSelect = action.payload.data
            })
        builder
            .addCase(getCategories.fulfilled, (state, action) => {
                state.categories = action.payload.data
            })
        builder
            .addCase(getBranches.fulfilled, (state, action) => {
                state.branchesForSelect = action.payload
            })
        builder
            .addCase(getScoreTypesForSelect.fulfilled, (state, action) => {
                state.scoreTypesForSelect = action.payload.data
            })
        builder
            .addCase(getSectionsByCategoryId.fulfilled, (state, action) => {
                state.sections = action.payload.data
            })
        builder
            .addCase(getScoreTypeCoefficients.fulfilled, (state, action) => {
                state.category = action.payload.data

            })
        builder
            .addCase(getCategorySectionBranchesById.fulfilled, (state, action) => {
                state.sectionBranch = action.payload.data
            })
        builder
            .addCase(getLessonsBySectionId.fulfilled, (state, action) => {

                state.branches = action.payload.data
            })
        builder
            .addCase(getCoefficientsByCategorySectionBranchIds.fulfilled, (state, action) => {
                state.coefficients = action.payload.data
            })
        builder
            .addCase(getPagedQuestionDistibutions.fulfilled, (state, action) => {
                state.questionDistributions = action.payload.data
            })
    }
})
export const {
    setSelectedScoreType,
    setCategoryId,
    setSelectedCategory,
    setSelectedSection,
    setSelectedLesson,
    setExamCategoryOptions,
    setSectionOptions,
    setLessonOptions,
    setScoreTypeOptions,
    setQuestionDistributionOptions

} = appExamCategorySlice.actions

export default appExamCategorySlice.reducer