// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** axios Imports
import axios from '@axios'

export const getExamPlansPaged = createAsyncThunk('ExamPlan/GetPagedWithExams', async (examPlanOptions) => {
  const res = await axios.post('/onlineExams/ExamPlan/GetPagedWithExams', examPlanOptions)
  return res
})

const examPlanSlice = createSlice({
  name: 'examPlan',
  initialState: {
    examPlans: {},
    selectedExam: {},
    selectedExamPlan: {},
    examPlanOptions: {
      pagingOptions: {
        sortClause: "desc",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        title: "",
        startDate: null,
        endDate: null,
        isTimeLimited: null,
        onlineExamId: null,
        classRoomIds: null,
        examCategoryId: null
      }
    }
  },
  reducers: {
    setSelectedExam: (state, action) => {
      state.selectedExam = action.payload
    },
    setSelectedExamPlan: (state, action) => {
      state.selectedExamPlan = action.payload
    },
    setExamPlanOptions: (state, action) => {
      state.examPlanOptions = action.payload
    },
    setExamOptions: (state, action) => {
      state.examOptions = action.payload
    }
  },
  extraReducers: builder => {
    builder
    .addCase(getExamPlansPaged.fulfilled, (state, action) => {
      state.examPlans = action.payload.data
    })
  }
})
export const { 
  setSelectedExam,
  setSelectedExamPlan,
  setExamPlanOptions,
  setExamOptions
} = examPlanSlice.actions
export default examPlanSlice.reducer