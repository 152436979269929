import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "@axios"

export const getPagedSections = createAsyncThunk('common/getPagedSections', async (options) => {
  const response = await axios.post(`/common/section/getPagedSections`, options)
   
  return response
})

export const appSectionSlice = createSlice({
  name: 'section',
  initialState: {
    sections: []
  },
  reducers: {
 
  },
  extraReducers: builder => {
    builder
      .addCase(getPagedSections.fulfilled, (state, action) => {
         
        state.sections = action.payload.data
         
      })
  }
})

export const { } = appSectionSlice.actions
export default appSectionSlice.reducer