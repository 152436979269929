// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** axios Imports
import axios from '@axios'

export const getPagedAssignment = createAsyncThunk('Assignment/GetPaged', async (assignmentOptions) => {
  const response = await axios.post(`/assignments/Assignment/GetPaged`, assignmentOptions)
  return response
})

const appAssignmentSlice = createSlice({
  name: 'assignmentManager',
  initialState: {
    assignments: [],
    selectedAssignment: {},
    assignmentOptions : {
      pagingOptions: {
        sortClause: "desc",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        title: "",
        lowStartDate: null,
        highStartDate: null,
        lowEndDate: null,
        highEndDate: null,
        creatorUserId: null
      }
    }
  }, 
  reducers: {
    setAssignmentOptions: (state, action) => {
      state.assignmentOptions = action.payload
    },
    setSelectedAssignment: (state, action) => {
      state.selectedAssignment = action.payload
    }
  },
  extraReducers: builder => {
    builder
    .addCase(getPagedAssignment.fulfilled, (state, action) => {
      state.assignments = action.payload.data
    })
  }
})
export const { setAssignmentOptions, setSelectedAssignment } = appAssignmentSlice.actions
export default appAssignmentSlice.reducer