// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from '@axios'

import moment from 'moment'
// ** Immer import
import { setAutoFreeze } from 'immer'
import fileDownload from 'js-file-download'
setAutoFreeze(false)

//* QrCode paging
export const getPagedQrCodes = createAsyncThunk('qrcodes/getPagedQrCodes', async (qrOptions) => {
  const response = await axios.post(`/qrcodes/QRCode/GetPagedQrCodes`, qrOptions)
  return response
})
//* QrCode Crud
export const addQrCode = createAsyncThunk('qrcodes/addQrCode', async (model) => {
  const response = await axios.post(`/qrcodes/QRCode/AddQRCode`, model, {showToast : true})
  return response
})

export const updateQrCode = createAsyncThunk('qrcodes/updateQrCode', async (model) => {
  const response = await axios.put(`/qrcodes/QRCode/UpdateQRCode`, model, {showToast : true})
  return response
})

export const deleteQrCode = createAsyncThunk('qrcodes/deleteQrCode', async (id) => {
  await axios.delete(`/qrcodes/QRCode/Delete/${id}`, {showToast : true})
  return id
})
//* QrCode BulkAdd
export const bulkCreateQrCode = createAsyncThunk('qrcodes/bulkCreateQrCode', async (model) => {
  const response = await axios.post(`/qrcodes/QRCode/CreateQRCodes`, model, {
    responseType: "blob"
  }, 
  {
    showToast : true
  })

    .then((result) => {
      const url = window.URL.createObjectURL(result.data)
      const element = document.createElement('a')
      element.href = url
      element.download = `QRCodes_${moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a')}`
      document.body.appendChild(element)
      element.click()
    })
  return response
})
//* QrCode Download
export const downloadQrCode = createAsyncThunk('qrcodes/downloadQrCode', async (model) => {
  const response = await axios.post(`/qrcodes/QRCodeDownload/DownloadQrCode`, model, {
    responseType: "blob"
  }, 
  {
    showToast : true
  })
    .then((result) => {
      const url = window.URL.createObjectURL(result.data)
      const element = document.createElement('a')
      element.href = url
      element.download = `QRCode_${moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a')}`
      document.body.appendChild(element)
      element.click()
    })
  return response
})
//* Change QR code activity
export const changeIsActiveQrCode = createAsyncThunk('qrcodes/changeIsActiveQrCode', async (model) => {
  const response = await axios.put(`/qrcodes/QRCode/IsActiveChangeQrCode`, model)
  return response
})
//* Type Enum Class
export const getQrCodeType = createAsyncThunk('qrcodes/getQrCodeType', async () => {
  const response = await axios.get(`/qrcodes/QRCode/GetQrCodeType`)
  return response
})

export const getQrCodeDownType = createAsyncThunk('qrcodes/getQrCodeDownType', async () => {
  const response = await axios.get(`/qrcodes/QRCode/GetQrDownloadType`)
  return response
})

export const getQrCodeDownFileFormat = createAsyncThunk('qrcodes/getQrCodeDownFileFormat', async () => {
  const response = await axios.get(`/qrcodes/QRCode/GetQrAllDownType`)
  return response
})
//* QRCode Excel Transactions
export const templateExcelFile = createAsyncThunk('qrcodes/templateQrCode', async () => {
  const response = axios
    .get(`/qrCodes/QRCodeDownload/TemplateQrCode`, {
      responseType: "blob"
    })
    .then((res) => { fileDownload(res.data, `QR_Yükleme_Şablonu_${moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a')}.xlsx`) })
  return response
})

export const exportQrCodesExcel = createAsyncThunk('qrcodes/exportQrCodesExcel', async () => {
  const response = axios
    .get(`/qrCodes/QRCodeDownload/ExportQrCodesExcel`, {
      responseType: "blob"
    })
    .then((res) => { fileDownload(res.data, `QR_Kodlar_${moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a')}.xlsx`) })
  return response
})

export const appQRCodesSlice = createSlice({
  name: "qrCodes",
  initialState: {
    qrCodeData: {},
    selectedQrCode: {},
    getQrType: [],
    getQrDownType: [],
    getQrDownFileFormat: [],
    qrOptions: {
      pagingOptions: {
        sortClause: "desc",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        qrCodeString: "",
        fromUrl: "",
        redirectUrl: "",
        type: null,
        entityId: null,
        isActive: null,
        parentId: null,
        displayOrder: 0
      }
    }
  },
  reducers: {
    setSelectedQrCode: (state, action) => {
      state.selectedQrCode = action.payload
    },
    setQrCodeOptions: (state, action) => {
      state.qrOptions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPagedQrCodes.fulfilled, (state, action) => {
        state.qrCodeData = action.payload.data
      })
      .addCase(getQrCodeType.fulfilled, (state, action) => {
        state.getQrType = action.payload.data
      })
      .addCase(getQrCodeDownType.fulfilled, (state, action) => {
        state.getQrDownType = action.payload.data
      })
      .addCase(getQrCodeDownFileFormat.fulfilled, (state, action) => {
        state.getQrDownFileFormat = action.payload.data
      })
  }
})
export const { setSelectedQrCode, setQrCodeOptions } = appQRCodesSlice.actions
export default appQRCodesSlice.reducer