import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit"

import axios from "@axios"


export const getBookContentsTree = createAsyncThunk(
  "bookManager/GetBookContentsTree",
  async (params) => {
    const response = await axios.get(
      `/bookmanager/Books/GetBookContentsTree?bookId=${params.bookId}&includeQuestions=${params.includeQuestions}&includeSubjects=${params.includeSubjects}`
    )
    return response
  }
)
export const getQuiz = createAsyncThunk("quizmanager/GetQuiz", async (id) => {
  const response = await axios.get(
    `/quizmanager/Quiz/GetQuiz?id=${id}`,
    { showLoader: true }
  )
  return response
})

export const getCourse = createAsyncThunk(
  "quizmanager/GetCourse",
  async (id) => {
    const response = await axios.get(
      `/quizmanager/Course/GetCourse?id=${id}`,
      { showLoader: true }
    )
    return response
  }
)

export const bookDetailSlice = createSlice({
  name: "bookDetail",
  initialState: {
    bookContentsTree: [],
    selectedContent: {},
    classIds: {},
    branchIds: {}
  },
  reducers: {
    updateQuizQuestions: (state, action) => {
      const quiz = state.selectedContent
      quiz.questions = quiz.questions.map((p) => (p.id === action.payload.data.id ? action.payload.data : p)
      )
      state.selectedContent = quiz
    },
    updateCourseContents: (state, action) => {
      let course = current(state.selectedContent)
      course = {
        ...course,
        courseContents: course.courseContents.map((p) => (p.subject !== null ? p.subject.id === action.payload.data.id ? { ...p, subject: action.payload.data } : p : p.question.id === action.payload.data.id ? { ...p, question: action.payload.data } : p)
        )
      }
      state.selectedContent = course
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getBookContentsTree.fulfilled, (state, action) => {
      state.bookContentsTree = action.payload.data.children
      state.classIds = action.payload.data.classIds
      state.branchIds = action.payload.data.branchIds
    })
    builder.addCase(getQuiz.fulfilled, (state, action) => {
      state.selectedContent = { ...action.payload.data, type: "quiz" }
    })
    builder.addCase(getCourse.fulfilled, (state, action) => {
      state.selectedContent = { ...action.payload.data, type: "course" }
    })
  }
})
export const { updateQuizQuestions, updateCourseContents } =
  bookDetailSlice.actions
export default bookDetailSlice.reducer
