// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from '@axios'


export const getPagedInstructors = createAsyncThunk("VideoInstructor/GetPagedInstructors", async (videoInstructorOptions) => {
  const response = await axios.post(`/videolessons/VideoInstructor/GetPagedInstructors`, videoInstructorOptions)
  return response
})
export const getAllInstructors = createAsyncThunk("VideoInstructor/GetAll", async (videoInstructorOptions) => {
  const response = await axios.post(`/videolessons/VideoInstructor/GetAll`, videoInstructorOptions.spec)
  return response
})
export const getInstructorsSelectList = createAsyncThunk("VideoInstructor/GetSelectList", async (videoInstructorOptions) => {
  const response = await axios.post(`/videolessons/VideoInstructor/GetSelectList`, videoInstructorOptions.spec)
  return response
})
export const createVideoInstructor = createAsyncThunk("VideoInstructor/Create", async (model) => {
  const response = await axios.post(`/videolessons/VideoInstructor/Create`, model, {showToast : true})
  return response
})
export const updateVideoInstructor = createAsyncThunk("VideoInstructor/Update", async (model) => {
  const response = await axios.put(`/videolessons/VideoInstructor/Update`, model, {showToast : true})
  return response
})
export const deleteVideoInstructor = createAsyncThunk("VideoInstructor/Delete", async(id) => {
  await axios.delete(`/videolessons/VideoInstructor/Delete/${id}`, {showToast : true})
  return id
})


export const appVideoInstructorSlice = createSlice({
  name: "videoInstructor",
  initialState: {
    videoInstructor: [],
    selectedVideoInstructor : {},
    videoInstructorOptions: {
      pagingOptions: {
        sortClause: "desc",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        title: ""
      }
    }
  },
  reducers: {
    setSelectedVideoInstructor: (state, action) => {
      state.selectedVideoInstructor = action.payload
    },
    setVideoInstructorOptions: (state, action) => {
      state.videoInstructorOptions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getPagedInstructors.fulfilled, (state, action) => {
      state.videoInstructor = action.payload.data
    })  
  }
})
export const { 
  setSelectedVideoInstructor,
  setVideoInstructorOptions
 } = appVideoInstructorSlice.actions

export default appVideoInstructorSlice.reducer
