// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from '@axios'


export const getPagedVideoLessons = createAsyncThunk("VideoLesson/GetPagedVideoLessons", async (videoLessonOptions) => {
  const response = await axios.post(`/videolessons/VideoLesson/GetPagedVideoLessons`, videoLessonOptions)
  return response
})
export const addVideoLesson = createAsyncThunk("VideoLesson/AddVideoLesson", async (model) => {
  const response = await axios.post(`/videolessons/VideoLesson/Create`, model, {showToast : true})
  return response
})
export const updateVideoLesson = createAsyncThunk("VideoLesson/UpdateVideoLesson", async (model) => {
  await axios.put(`/videolessons/VideoLesson/Update`, model, {showToast : true})
  return model.id
})
export const deleteVideoLesson = createAsyncThunk("VideoLesson/DeleteVideoLesson", async (id) => {
  await axios.delete(`/videolessons/VideoLesson/Delete/${id}`, {showToast : true})
  return id
})

export const appVideoLessonSlice = createSlice({
  name: "videoLesson",
  initialState: {
    videoLesson: [],
    selectedVideoLesson : {},
    videoLessonOptions: {
      pagingOptions: {
        sortClause: "desc",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        title: "",
        isActive: null,
        isPublished: null,
        viewLimit: null,
        publisherId: null
      }
    }
  },
  reducers: {
    setSelectedVideoLesson: (state, action) => {
      state.selectedVideoLesson = action.payload
    },
    setVideoLessonOptions: (state, action) => {
      state.videoLessonOptions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getPagedVideoLessons.fulfilled, (state, action) => {
      state.videoLesson = action.payload.data
    })
  }
})
export const { 
  setSelectedVideoLesson, 
  setVideoLessonOptions
 } = appVideoLessonSlice.actions
export default appVideoLessonSlice.reducer