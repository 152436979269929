// * redux import
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { pagingOptions } from "@configs/appConstants"
// * axios import
import axios from "@axios"
export const getSchool = createAsyncThunk(
  "organization/getSchool",
  async (model) => {
    const response = await axios.post(`/common/School/GetSchool`, model)
    return response
  }
)
export const getClassRoom = createAsyncThunk(
  "organization/getClassRoom",
  async (id) => {
    const response = await axios.get(
      `/organisation/ClassRoom/GetClassRoom?id=${id}`
    )
    return response
  }
)
export const getClassRooms = createAsyncThunk(
  "organization/getClassRooms",
  async (options) => {
    const response = await axios.post(
      `/organisation/ClassRoom/GetPagedClassRooms`,
      options
    )
    return response
  }
)
export const getTeachers = createAsyncThunk(
  "organization/getTeachers",
  async (schoolOptions) => {
    
    const response = await axios.post(
      `/organisation/SchoolUser/GetSchoolUsers`,
      schoolOptions
    )
    return response
  }
)

export const getStudents = createAsyncThunk(
  "organization/getStudents",
  async (schoolOptions) => {
    const response = await axios.post(
      `/organisation/SchoolUser/GetSchoolUsers`,
      schoolOptions
    )
    return response
  }
)
export const getAssignments = createAsyncThunk('Assignment/GetPaged', async (assignmentOptions) => {
  const response = await axios.post(`/assignments/Assignment/GetPaged`, assignmentOptions)
  return response
})
export const getAssignmentContents = createAsyncThunk('AssignmentContent/GetPaged', async (assignmentContentOptions) => {
  const response = await axios.post('/assignments/AssignmentContent/GetPaged', assignmentContentOptions)
  return response
})
export const getActiveExams = createAsyncThunk("activeExams/getActiveExams", async (activeExamOptions) => {
  const response = await axios.post(`/onlineExams/ExamPlanClassRoom/GetPaged`, activeExamOptions)
  return response
})
export const getAnnouncements = createAsyncThunk(
  "announcements/getAnnouncements",
  async (pagingOptions) => {
    const response = await axios.post(
      `/announcements/Announcement/GetPaged`,
      pagingOptions
    )
    return response
  }
)
export const addClassRoom = createAsyncThunk(
  "organization/addClassRoom",
  async (model) => {
    const response = await axios
    .post(`/organisation/ClassRoom/AddClassRoom`, model, {showLoader: true, showToast: true})
    return response
  }
)
export const updateClassRoom = createAsyncThunk(
  "organization/updateClassRoom",
  async (model) => {
    const response = await axios
    .put(`/organisation/ClassRoom/UpdateClassRoom`, model, {showLoader: true, showToast: true})
    return response
  }
)
export const removeClassRoom = createAsyncThunk(
  "organization/removeClassRoom",
  async (id) => {
    await axios.delete(
      `/organisation/ClassRoom/DeleteClassRoom?id=${id}`
    )
  }
)
export const getClassRoomLessonStatistics = createAsyncThunk("organization/getClassRoomLessonStatistics", async (options) => {
  
  const response = await axios.post(`/organisation/ClassRoom/GetClassRoomLessonStatistics`, options)
  return response
})
const userSpec = {
  schoolId: 1,
  userType: 0,
  firstName: "",
  lastName: "",
  Email: "",
  isFilter: true,
  classIds: [],
  branchIds: [],
  classRoomIds: [],
  fullName: ''
}
const classRoomsManagerSlice = createSlice({
  name: "classRoomsManager",
  initialState: {
    school: {},
    classRooms: {},
    selectedClassRoom:{},
    teachers: {},
    students: {},
    assignments: {},
    announcements: {},
    activeExams: {},
    assignmentContents: {},
    lessonStatistics:{},
    classRoomOptions: {
      pagingOptions,
      spec: {
        id: null,
        classId: null,
        classIds: null,
        schoolId: null,
        title: ""
      }
    },
    teacherOptions: {
      pagingOptions,
      spec: { ...userSpec, userType: 2 }
    },
    studentOptions: {
      pagingOptions,
      spec: { ...userSpec, userType: 1 }
    },
    assignmentOptions: {
      pagingOptions,
      spec: {
        classRoomIds: null,
        assignmetStatus: 1
      }
    },
    announcementOptions: {
      pagingOptions,
      spec: {
        classRoomIds: null,
        IsActive: true
      }
    },
    assignmentContentOptions: {
      pagingOptions,
      spec: {
        title: "",
        assignmentId: null,
        entityId: null,
        assignmentType: null,
        branchId: null,
        userId: "00000000-0000-0000-0000-000000000000"
      }
    },
    activeExamOptions: {
      pagingOptions,
      spec: {
        classRoomId: null,
        examPlanId: null,
        examStatusType: 1
      }
    },
    lessonStatisticsOptions: {
        classRoomId:null,
        classId:null,
        branchId:null
    }
  },
  reducers: {
    setClassRoomOptions: (state, action) => {
      state.classRoomOptions = action.payload
    },
    setTeacherOptions: (state, action) => {
      state.teacherOptions = action.payload
    },
    setStudentOptions: (state, action) => {
      state.studentOptions = action.payload
    },
    setAssignmentOptions: (state, action) => {
      state.assignmentOptions = action.payload
    },
    setAnnouncementOptions: (state, action) => {
      state.announcementOptions = action.payload
    },
    setAssignmentContentOptions: (state, action) => {
      state.assignmentContentOptions = action.payload
    },
    setActiveExamOptions: (state, action) => {
      state.activeExamOptions = action.payload
    },
    setLessonStatisticsOptions: (state, action) => {
      state.lessonStatisticsOptions = action.payload
    },
    setSelectedClassRoom: (state, action) => {
      
      state.selectedClassRoom = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getSchool.fulfilled, (state, action) => {
      state.school = action.payload.data
    })
      .addCase(getClassRoom.fulfilled, (state, action) => {
        state.selectedClassRoom = action.payload.data
      })
      .addCase(getClassRooms.fulfilled, (state, action) => {
        state.classRooms = action.payload.data
      })
      .addCase(getTeachers.fulfilled, (state, action) => {
        state.teachers = action.payload.data
      })
      .addCase(getStudents.fulfilled, (state, action) => {
        state.students = action.payload.data
      })
      .addCase(getAssignments.fulfilled, (state, action) => {
        state.assignments = action.payload.data
      })
      .addCase(getAnnouncements.fulfilled, (state, action) => {
        state.announcements = action.payload.data
      })
      .addCase(getAssignmentContents.fulfilled, (state, action) => {
        state.assignmentContents = action.payload.data
      })
      .addCase(getActiveExams.fulfilled, (state, action) => {
        state.activeExams = action.payload.data
      })
      .addCase(getClassRoomLessonStatistics.fulfilled, (state, action) => {
        state.lessonStatistics = action.payload.data
      })
  }
})

export const {
  setClassRoomOptions,
  setTeacherOptions,
  setStudentOptions,
  setAssignmentOptions,
  setAnnouncementOptions,
  setAssignmentContentOptions,
  setActiveExamOptions,
  setLessonStatisticsOptions,
  setSelectedClassRoom
} = classRoomsManagerSlice.actions
export default classRoomsManagerSlice.reducer