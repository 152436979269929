// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Axios Imports
import axios from "@axios"

export const getBooks = createAsyncThunk(
  "bookManager/getBooks",
  async (params) => {
    const response = await axios.post(
      `/bookManager/userBooks/getpagedbooks`,
      params
    )
    return response
  }
)
export const getBook = createAsyncThunk("books/getBook", async (id) => {
  const response = await axios.get(`/bookManager/userbooks/getBook`, {params:{id}, showLoader:true})
  return response.data
})

export const getUnit = createAsyncThunk("books/getUnit", async (id) => {
  const response = await axios.get(`/bookManager/userbooks/getUnit`, {params:{id}})
  return response.data
})
export const getCourse = createAsyncThunk("books/getCourse", async (id) => {
  const response = await axios.get(`/bookManager/userbooks/getCourse`, {params:{id}})
  return response.data
})

export const getQuiz = createAsyncThunk("books/getQuiz", async (id) => {
  const response = await axios.get(`/bookManager/userbooks/getQuiz`, {params:{id}})
  return response.data
})

export const appBookSlice = createSlice({
  name: "userBooks",
  initialState: {
    userBooks: [],
    book: {},
    selectedUnit:  {},
    selectedContent: {},
    userBookOptions: {
      pagingOptions: {
        sortClause: "Id",
        orderBy: "desc",
        pageIndex: 0,
        pageSize: 7
      },
      spec: {
        title: "",
        classIds: null,
        branchIds: null
      }
    }
  },
  reducers: {
    setUserBookOptions: (state, action) => {
      state.userBookOptions = action.payload
    },
    // eslint-disable-next-line no-unused-vars
    updateSelectedContent: (state, courseContent) => {
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBooks.fulfilled, (state, action) => {
        state.userBooks = action.payload.data
      })
      .addCase(getBook.fulfilled, (state, action) => {
        state.book = action.payload
      })
      .addCase(getUnit.fulfilled, (state, action) => {
        state.selectedUnit = action.payload
      })
      .addCase(getCourse.fulfilled, (state, action) => {
        state.selectedContent = action.payload
      })
      .addCase(getQuiz.fulfilled, (state, action) => {
        state.selectedContent = action.payload
      })
  }
})

export const { setUserBookOptions, updateSelectedContent } = appBookSlice.actions
export default appBookSlice.reducer
