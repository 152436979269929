import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "@axios"
import { pagingOptions } from "../../../../../configs/appConstants"

//schools
export const getSchool = createAsyncThunk(
  "organization/getSchool",
  async (model) => {
    const response = await axios
    .post(`/common/School/GetSchool`, model, {showLoader: true})
    return response
  }
)
export const getManagers = createAsyncThunk(
  "organization/getManagers",
  async (schoolOptions) => {
    const response = await axios
    .post(`/organisation/SchoolUser/GetSchoolUsers`, schoolOptions, {showLoader: true})
    return response
  }
)
export const getTeachers = createAsyncThunk(
  "organization/getTeachers",
  async (schoolOptions) => {
    const response = await axios
    .post(`/organisation/SchoolUser/GetSchoolUsers`, schoolOptions, {showLoader: true})
    return response
  }
)
export const getStudents = createAsyncThunk(
  "organization/getStudents",
  async (schoolOptions) => {
    const response = await axios
    .post(`/organisation/SchoolUser/GetSchoolUsers`, schoolOptions, {showLoader: true})
    return response
  }
)

export const addUser = createAsyncThunk(
  "organisation/SchoolUser/addUser",
  async (model) => {
    const response = await axios
      .post(`/organisation/SchoolUser/addSchoolUser`, model, {showLoader: true, showToast: true})
    return response
  }
)

export const updateUser = createAsyncThunk(
  "organisation/SchoolUser/updateUser",
  async (model) => {
    const response = await axios
      .put(`/organisation/SchoolUser/updateSchoolUser`, model, {showLoader: true, showToast: true})
    return response
  }
)

export const addSchool = createAsyncThunk(
  "organization/addSchool",
  async (model) => {
    const response = await axios
    .post(`/organisation/School/AddSchool`, model, {showLoader: true, showToast: true})
    return response
  }
)

export const updateSchool = createAsyncThunk(
  "organization/updateSchool",
  async (model) => {
    const response = await axios
    .put(`/organisation/School/UpdateSchool`, model, {showLoader: true, showToast: true})
    return response
  }
)

//SchoolUser
export const getSchoolManager = createAsyncThunk(
  "organization/getSchoolUsers",
  async (options) => {
    const response = await axios
    .post('/organisation/SchoolUser/GetSchoolUsers', options, {showLoader: true})
    return response
  }
)

export const getUsersForSchool = createAsyncThunk(
  "organization/getUsersForSchool",
  async (options) => {
    const response = await axios
    .post(`/organisation/SchoolUser/GetPagedUsersForSchool`, options, {showLoader: true, showToast: true})
    return response
  }
)

export const addSchoolUser = createAsyncThunk(
  "organization/addSchoolUser",
  async (model) => {
    const response = await axios
    .post(`/organisation/SchoolUser/AddSchoolUser`, model, {showLoader: true, showToast: true})
    return response
  }
)

export const removeSchoolUser = createAsyncThunk(
  "organization/removeSchoolUser",
  async (id) => {
    await axios
    .delete(`/organisation/SchoolUser/RemoveSchoolUser/${id}`)
    return id
  }
)
export const BulkDeleteSelectedSchoolUser = createAsyncThunk(
  "organization/BulkDeleteSchoolUser",
  async (schoolUserdIds) => {
    await axios
    .post(`/organisation/SchoolUser/BulkDeleteSelectedSchoolUser/`, schoolUserdIds, {showToast: true})
  }
)
export const BulkDeleteAllSchoolUser = createAsyncThunk(
  "organization/BulkDeleteAllSchoolUser",
  async (model) => {
    const { schoolId, schoolUserType } = model
    await axios
    .post(`/organisation/SchoolUser/BulkDeleteAllSchoolUser?schoolId=${schoolId}&schoolUserType=${schoolUserType}`, {showToast: true})
  }
)
//ClassRoom
export const getClassRooms = createAsyncThunk(
  "organization/getClassRooms",
  async (options) => {
    const response = await axios.post('/organisation/ClassRoom/GetPagedClassRooms', options, {showLoader: true})
    return response
  }
)

export const addClassRoom = createAsyncThunk(
  "organization/addClassRoom",
  async (model) => {
    const response = await axios
    .post(`/organisation/ClassRoom/AddClassRoom`, model, {showLoader: true, showToast: true})
    return response
  }
)

export const updateClassRoom = createAsyncThunk(
  "organization/updateClassRoom",
  async (model) => {
    const response = await axios
    .put(`/organisation/ClassRoom/UpdateClassRoom`, model, {showLoader: true, showToast: true})
    return response
  }
)

export const removeClassRoom = createAsyncThunk(
  "organization/removeClassRoom",
  async () => {
    await axios
    .delete(`/organisation/ClassRoom/DeleteClassRoom/${id}`)
    return id
  }
)

//ClassRoomUsers
export const getClassRoomUsers = createAsyncThunk(
  "organization/getClassRoomUsers",
  async (options) => {
    const response = await axios.post(
      `/organisation/ClassRoomUser/GetPagedClassRoomUsers`,
      options
    )
    return response
  }
)

export const getUsersForClassRoom = createAsyncThunk(
  "organization/getUsersForClassRoom",
  async (options) => {
    const response = await axios.post(
      `/organisation/ClassRoomUser/GetPagedUsersForClassRoom`,
      options
    )
    return response
  }
)

export const addClassRoomUser = createAsyncThunk(
  "organization/addClassRoomUser",
  async (models) => {
    const response = await axios.post(
      `/organisation/ClassRoomUser/AddClassRoomUser`,
      models
    )
    return response
  }
)

export const removeClassRoomUser = createAsyncThunk(
  "common/removeClassRoomUsers",
  async (id) => {
    await axios.delete(`/organisation/ClassRoomUser/RemoveClassRoomUser/${id}`)
    return id
  }
)

// School Setting
export const getSchoolSettings = createAsyncThunk(
  "organization/getSchoolSettings",
  async (options) => {
    const response = await axios.post(
      `/organisation/SchoolSetting/GetPagedSchoolSettings`,
      options
    )
    return response
  }
)

export const getSchoolSetting = createAsyncThunk(
  "organization/getSchoolSetting",
  async (id) => {
    const response = await axios.post(
      `/organisation/SchoolSetting/GetSchoolSetting/${id}`
    )
    return response
  }
)

export const addSchoolSetting = createAsyncThunk(
  "organization/addSchoolSetting",
  async (model) => {
    const response = await axios.post(
      `/organisation/SchoolSetting/AddSchoolSetting`,
      model
    )
    return response
  }
)

export const updateSchoolSetting = createAsyncThunk(
  "organization/updateSchoolSetting",
  async (model) => {
    const response = await axios.put(
      `/organisation/SchoolSetting/UpdateSchoolSetting`,
      model
    )
    return response
  }
)

export const removeSchoolSetting = createAsyncThunk(
  "organization/removeSchoolSetting",
  async (id) => {
    await axios.delete(`/organisation/SchoolSetting/DeleteSchoolSetting/${id}`)
    return id
  }
)
const userSpec = {
  schoolId: 0,
  userType: 0,
  firstName: "",
  lastName: "",
  Email: "",
  isFilter: true,
  classIds: [],
  branchIds: [],
  classRoomIds: [],
  fullName: ""
}
export const schoolDetailSlice = createSlice({
  name: "schoolDetailStore",
  initialState: {
    school: {},
    managers: {},
    selectedManager: {},
    teachers: {},
    selectedTeacher: {},
    students: {},
    selectedStudent: {},
    managerOptions: {
      pagingOptions,
      spec: { ...userSpec, userType: 3 }
    },
    teacherOptions: {
      pagingOptions,
      spec: { ...userSpec, userType: 2 }
    },
    studentOptions: {
      pagingOptions,
      spec: { ...userSpec, userType: 1 }
    },
    classRooms: [],
    classRoomUsers: [],
    selectedClassRoom: {},
    schoolSettings: [],
    selectedSchoolSetting: {},
    classRoomOptions: {
      pagingOptions,
      spec: {
        id: null,
        classId: null,
        title: ""
      }
    },
    classRoomUserOptions: {
      pagingOptions,
      spec: {
        classRoomId: null,
        schoolUserId: null,
        userId: null
      }
    },
    schoolSettingOptions: {
      pagingOptions,
      spec: {
        educationYearName: "",
        schoolId: null
      }
    },
    schoolUserOptions: {
      pagingOptions,
      spec: {
        name: ""
      }
    },
    userForAddingListOptions: {
      pagingOptions,
      userSpec: {
        provinceId: null,
        districtId: null,
        schoolId: null,
        classRoomId: null,
        firstName: "",
        lastName: "",
        email: ""
      }
    }
  },
  reducers: {
    setSelectedSchool: (state, action) => {
      state.selectedSchool = action.payload
    },
    setSelectedClassRoom: (state, action) => {
      state.selectedClassRoom = action.payload
    },
    setManagerOptions: (state, action) => {
      state.managerOptions = action.payload
    },
    setSchoolOptions: (state, action) => {
      state.schoolOptions = action.payload
    },
    setClassRoomOptions: (state, action) => {
      state.classRoomOptions = action.payload
    },
    setClassRoomUserOptions: (state, action) => {
      state.classRoomUserOptions = action.payload
    },
    setSelectedSchoolSetting: (state, action) => {
      state.selectedSchoolSetting = action.payload
    },
    setSchoolSettingOptions: (state, action) => {
      state.schoolSettingOptions = action.payload
    },
    setSchoolUserOptions: (state, action) => {
      state.schoolUserOptions = action.payload
    },
    setSelectedManager: (state, action) => {
      state.selectedManager = action.payload
    },
    setSelectedTeacher: (state, action) => {
      state.selectedTeacher = action.payload
    },
    setSelectedStudent: (state, action) => {
      state.selectedStudent = action.payload
    },
    setStudentOptions: (state, action) => {
      state.studentOptions = action.payload
    },
    setTeacherOptions: (state, action) => {
      state.teacherOptions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSchool.fulfilled, (state, action) => {
        state.school = action.payload.data
      })
      .addCase(getManagers.fulfilled, (state, action) => {
        state.managers = action.payload.data
      })
      .addCase(getTeachers.fulfilled, (state, action) => {
        state.teachers = action.payload.data
      })
      .addCase(getStudents.fulfilled, (state, action) => {
        state.students = action.payload.data
      })
      .addCase(getClassRooms.fulfilled, (state, action) => {
        state.classRooms = action.payload.data
      })
  }
})
export const {
  setSelectedSchool,
  setSelectedClassRoom,
  setSchoolOptions,
  setManagerOptions,
  setClassRoomOptions,
  setSelectedSchoolSetting,
  setSchoolSettingOptions,
  setSchoolUserOptions,
  setClassRoomUserOptions,
  setUserForAddingListOptions,
  setSelectedManager,
  setSelectedTeacher,
  setSelectedStudent,
  setStudentOptions,
  setTeacherOptions
} = schoolDetailSlice.actions

export default schoolDetailSlice.reducer
