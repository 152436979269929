// * redux and axios imports
import axios from "@axios"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import moment from 'moment'
import 'moment/locale/tr'
import { dayOfWeekNum } from '../dayOfWeekStyleEnum'

// * schedule
export const getEducationYears = createAsyncThunk('EducationYear/GetEducationYears', async () => {
  const res = await axios.get('common/EducationYear/GetEducationYears')
  return res
})
export const getSchoolList = createAsyncThunk('School/GetSchoolSelectList', async () => {
  const res = await axios.post('/common/School/GetSchoolSelectList', {})
  return res
})
export const getScheduleData = createAsyncThunk('TimeTable/GetPaged', async (schedulesOptions) => {
  const res = await axios.post('/Curriculum/TimeTable/GetPaged', schedulesOptions, {showLoader: true})
  return res
})

// * details  
export const getClassRoomSelectList = createAsyncThunk('ClassRoom/GetGroupedClassRoomSelectList', async () => {
  const res = await axios.get('/organisation/ClassRoom/GetGroupedClassRoomSelectList')
  return res
})
export const getLessonSelectList = createAsyncThunk('ClassRoom/GetClassRoomLessonsSelectList', async (classRoomId) => {
  const res = await axios.get(`/organisation/ClassRoom/GetClassRoomLessonsSelectList?classRoomId=${classRoomId}`)
  return res
})
export const getUsersSelectList = createAsyncThunk('SchoolUser/GetSchoolUserSelectList', async (specs) => {
  const res = await axios.post('/organisation/SchoolUser/GetSchoolUserSelectList', specs)
  return res
})
export const getWeekData = createAsyncThunk('TimeTableLesson/GetTimeTableLessons', async (specs) => {
  const res = await axios.post('/Curriculum/TimeTableLesson/GetTimeTableLessons', specs, {showLoader: true})
  return res
})

// * widget
export const getWidgetData = createAsyncThunk('TimeTable/GetHomePageLessonProgram', async () => {
  const res = await axios.get('/Curriculum/TimeTable/GetHomePageLessonProgram', {showLoader: true})
  return res
})

export const weekSchedulePortalSlice = createSlice({
  name: 'weekSchedule',
  initialState: {
    // * schedule
    educationYearList: [],
    schoolList: [],
    schedules: {},
    selectedSchedule: {},
    schedulesOptions: {
      pagingOptions: {
        sortClause: "id",
        orderBy: "desc",
        pageIndex: 0,
        pageSize: 0
      },
      spec: {
        schoolId: null,
        educationYearId: null
      }
    },
    // * details
    classRoomList: [],
    selectedClassRoomId: null,
    lessonSelectList: [],
    lessonList: [],
    userList: [],
    weekData: [],
    weekSchedule: {},
    dayOfWeek: dayOfWeekNum[moment(new Date()).locale('tr').format('dddd')],
    defaultItemCount: 6,
    // * widget 
    widgetData: []
  },
  reducers: {
    setSelectedSchedule: (state, action) => {
      state.selectedSchedule = action.payload
    },
    setSchedulesOptions: (state, action) => {
      state.schedulesOptions = action.payload
    },
    setSelectedClassRoomId: (state, action) => {
      state.selectedClassRoomId = action.payload
    },
    setDayOfWeek: (state, action) => {
      state.dayOfWeek = action.payload
    },
    setWeekData: (state, action) => {
      state.weekData = action.payload
    },
    setDefaultItemCount: (state, action) => {
      state.defaultItemCount = action.payload
    },
    handleWeekData: (state, action) => {
      // * set data 
      const copyData = JSON.parse(JSON.stringify(state.weekData))
      const copyItem = copyData[action.payload.index]
      copyItem[`${action.payload.name}`] = action.payload.data
      // * set selected classRoom
      copyItem['classRoomId'] = state.selectedClassRoomId
      // * set state
      copyData.splice(action.payload.index, 1, copyItem)
      state.weekData = copyData
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEducationYears.fulfilled, (state, action) => {
        state.educationYearList = action.payload.data
      })
      .addCase(getSchoolList.fulfilled, (state, action) => {
        state.schoolList = action.payload.data
      })
      .addCase(getScheduleData.fulfilled, (state, action) => {
        state.schedules = action.payload.data
      })
      .addCase(getClassRoomSelectList.fulfilled, (state, action) => {
        state.classRoomList = action.payload.data
        const item = JSON.parse(JSON.stringify(state.weekData)).find(x => x.classRoomId !== null)
        if (item) {
          state.selectedClassRoomId = item.classRoomId
        }
      })
      .addCase(getLessonSelectList.fulfilled, (state, action) => {
        state.lessonSelectList = action.payload.data.lessonSelectList
        state.lessonList = action.payload.data.lessonList
      })
      .addCase(getUsersSelectList.fulfilled, (state, action) => {
        state.userList = action.payload.data
      })
      .addCase(getWeekData.fulfilled, (state, action) => {
        state.weekData = action.payload.data
        if (action.payload.data.length > 0) {
          state.defaultItemCount = action.payload.data.length
          state.selectedClassRoomId = action.payload.data[0].classRoomId
        } else {
          state.defaultItemCount = 6
          state.selectedClassRoomId = null
        } 
      })
      .addCase(getWidgetData.fulfilled, (state, action) => {
        state.widgetData = action.payload.data
      })
  }
})
export const { 
  setSelectedSchedule,
  setSchedulesOptions,
  setSelectedClass, 
  setSelectedClassRoomId, 
  setDayOfWeek,
  setWeekData,
  setDefaultItemCount,
  handleWeekData
} = weekSchedulePortalSlice.actions
export default weekSchedulePortalSlice.reducer