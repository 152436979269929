import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from '@axios'

//

export const getPagedSolutionCommentsView = createAsyncThunk(
  'solutionManager/GetPagedSolutionCommentsView',
  async (params) => {
    
    const response = await axios.post(
      `/solutionManager/solutionComment/GetPagedSolutionCommentsView`,
      params, {showLoader: true}
    )
    return response
  }
)
export const getSolutionComment = createAsyncThunk(
  'solutionmanager/GetSolutionCommentDetail',
  async (Id) => {
    
    const response = await axios.get(
      `/solutionmanager/SolutionComment/Get/${Id}`, {showLoader: true}
    )
    return response
  }
)
export const solutionNotificationsSlice = createSlice({
  name:"solutionNotifications",
  initialState:{
    solutionNotifications:[],
    selectedCommentDetail:{},
    solutionNotificationOptions : {
        pagingOptions : {
          sortClause: "isSolved Asc ,updatedOn Desc, Id",
          orderBy: "desc",
          pageIndex: 0,
          pageSize: 10
        },
         spec:{
          id:null,
          userId:null,
          questionId:null,
          bookIds:[],
          commentTypeIds:[],
          commentStatusIds:[],
          comment:null
         }
      }
  },
  reducers :{
    setSolutionNotificationOptions: (state, action) => {
      state.solutionNotificationOptions = action.payload
    }
  },
  extraReducers :(builder) => {
    builder.addCase(getPagedSolutionCommentsView.fulfilled, (state, action) => {
      state.solutionNotifications = action.payload.data
    })
    builder.addCase(getSolutionComment.fulfilled, (state, action) => {
      state.selectedCommentDetail = action.payload.data
    })
  }
})
export const { 
    setSolutionNotificationOptions
 } = solutionNotificationsSlice.actions

export default solutionNotificationsSlice.reducer