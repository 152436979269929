// ** Redux Imports
import rootReducer from './rootReducer'
import { configureStore } from '@reduxjs/toolkit'
// * signalR middleware

const appStore = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: getDefaultMiddleware => { 
    return getDefaultMiddleware({
      serializableCheck: false
    })
   
  }
})

export { appStore }