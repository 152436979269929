// ** React Imports
import {Suspense, lazy } from "react"
import ReactDOM from "react-dom"

// ** Redux Imports
import { Provider } from "react-redux"
import { appStore } from "./redux/store"
// ** Service Worker
import * as serviceWorker from "./serviceWorker"
import "@vuexy/core/components/ripple-button"
import Spinner from "@obrocomponents/spinner/Fallback-spinner"
// ** Lazy load app
const LazyApp = lazy(() => import("./App"))
ReactDOM.render(
  <Provider store={appStore}>
    <Suspense fallback={<Spinner />}>
      <LazyApp />
    </Suspense>
  </Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
