import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from '@axios'
//

export const getPagedSolutionReports = createAsyncThunk(
  'solutionManager/GetSolutionReports',
  async (params) => {
    const response = await axios.post(
      `/solutionManager/solutionBook/GetSolutionReports`,
      params, {showLoader: true}
    )
    return response
  }
)
export const getPagedSolutionReportDetails = createAsyncThunk(
  'solutionManager/GetSolutionReportDetails',
  async (params) => {
    
    const response = await axios.post(
      `/solutionManager/solutionBook/GetSolutionReportDetails`,
      params, {showLoader: true}
    )
    return response
  }
)
export const solutionReportSlice = createSlice({
  name:"solutionReport",
  initialState:{
    solutionReports:[],
    solutionReportDetails:[],
    solutionReportDetailOptions: {
      pagingOptions: {
        sortClause: "Id",
        orderBy: "desc",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        bookIds: null,
        userIds: null,
        startDate: null,
        endDate: null
      }
    },
    solutionReportOptions: {
      pagingOptions: {
        sortClause: "Id",
        orderBy: "desc",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        bookIds: null,
        userIds: null,
        startDate: null,
        endDate: null
      }
    }
  },
  reducers :{
    setSolutionReportOptions: (state, action) => {
      state.solutionReportOptions = action.payload
    },
    setSolutionReportDetailOptions: (state, action) => {
      state.solutionReportDetailOptions = action.payload
    }
  },
  extraReducers :(builder) => {
    builder.addCase(getPagedSolutionReports.fulfilled, (state, action) => {
      state.solutionReports = action.payload.data
    })
    builder.addCase(getPagedSolutionReportDetails.fulfilled, (state, action) => {
      state.solutionReportDetails = action.payload.data
    })
  }
})
export const { 
setSolutionReportOptions,
setSolutionReportDetailOptions
 } = solutionReportSlice.actions

export default solutionReportSlice.reducer