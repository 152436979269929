// * redux imports
import { combineReducers } from 'redux'
// ** Reducers Imports
import authStore from './authStore'
import layout from './layout'
import navbar from './navbar'
import adminReducer from '../Obro_Admin/redux/rootReducer'
import portalReducer from '../Obro_Portal/redux/rootReducer'
import publicReducer from '../Obro_Public/redux/rootReducer'
import solutionReducer from '../Obro_SolutionApp/redux/rootReducer'

const rootReducer = combineReducers({
  authStore,
  layout,
  navbar,
  adminReducer,
  portalReducer,
  publicReducer,
  solutionReducer
})
export default rootReducer
