// * redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// * axios Imports
import axios from '@axios'

export const getPagedStudentReports = createAsyncThunk('AssignmentReport/GetPagedStudents', async (options) => {
  const response = await axios.post('/assignments/AssignmentReport/GetPagedStudents', options)
  return response 
})

export const getAssignmentReport = createAsyncThunk('AssignmentReport/GetContentId', async (params) => {
  const response = await axios.get(`/assignments/AssignmentReport/Get?contentId=${params.assignmentContentId}&userId=${params.userId}`)
  return response
})

export const getBraches = createAsyncThunk('Branch/GetBranches', async () => {
  const response = await axios.get('/common/Branch/GetSelectableBranches')
  return response
})

export const getAssignmentTypes = createAsyncThunk('AssignmentContent/GetTypes', async () => {
  const response = await axios.get('/assignments/AssignmentContent/GetContentTypes')
  return response
})

export const getAssignmentClassRooms = createAsyncThunk('', async(assignmentId) => {
  const response = await axios.get(`/assignments/Assignment/GetAssignmentClassRooms?assignmentId=${assignmentId}`)
  return response
})

const assignmentContentReportsSlice = createSlice({
  name: 'assignmentContentReports',
  initialState: {
    userReports: {},
    assignmentReportsData: {},
    branchList: [],
    assignmentTypes: [],
    assignmentClassRooms: [],
    options: {
      pagingOptions: {
        sortClause: "desc",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        assignmentId: null,
        assignemntContentId: null,
        userTitle: "",
        classRoomId: null,
        lowCompleteDate: null,
        highCompleteDate: null
      }
    }
  },
  reducers: {
    setOptions: (state, action) => {
      state.options = action.payload
    }
  },
  extraReducers: builder => {
    builder
    .addCase(getPagedStudentReports.fulfilled, (state, action) => {
      state.userReports = action.payload.data
    })
    .addCase(getAssignmentReport.fulfilled, (state, action) => {
      state.assignmentReportsData = action.payload.data
    })
    .addCase(getBraches.fulfilled, (state, action) => {
      state.branchList = action.payload.data.branchList
    })
    .addCase(getAssignmentTypes.fulfilled, (state, action) => {
      state.assignmentTypes = action.payload.data
    })
    .addCase(getAssignmentClassRooms.fulfilled, (state, action) => {
      state.assignmentClassRooms = action.payload.data
    })
  }
})
export const { setOptions } = assignmentContentReportsSlice.actions
export default assignmentContentReportsSlice.reducer