import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from '@axios'
export const getBookQuestionComments = createAsyncThunk(
  'quizmanager/getPagedBookQuestionComments',
  async (params) => {
    const response = await axios.post(
      `/quizmanager/QuestionComment/GetPagedBookQuestionComments`,
      params, {showLoader: true}
    )
    return response
  }
)
export const getQuestionComment = createAsyncThunk(
  'quizmanager/GetQuestionCommentDetail',
  async (Id) => {
    const response = await axios.get(
      `/quizmanager/QuestionComment/GetQuestionCommentDetail?commentId=${Id}`, {showLoader: true}
    )
    return response
  }
)
export const solutionNotificationsSlice = createSlice({
  name:"solutionNotifications",
  initialState:{
    questionComments:[],
    selectedCommentDetail:{},
    questionCommentOptions : {
      pagingOptions : {
        sortClause: "isSolved Asc ,updatedOn Desc, Id",
        orderBy: "desc",
        pageIndex: 0,
        pageSize: 10
      },
       spec:{
        id:null,
        userId:null,
        questionId:null,
        isSolved:null,
        bookIds:[],
        commentTypeIds:[],
        commentStatusIds:[]
       }
    }
  },
  reducers :{
    setQuestionCommentOptions :(state, action) => {     
      state.questionCommentOptions = action.payload
    }
  },
  extraReducers :(builder) => {
    builder.addCase(getBookQuestionComments.fulfilled, (state, action) => {
      state.questionComments = action.payload.data
    })
    builder.addCase(getQuestionComment.fulfilled, (state, action) => {
      state.selectedCommentDetail = action.payload.data
    })
  }
})
export const { 
setQuestionCommentOptions
 } = solutionNotificationsSlice.actions

export default solutionNotificationsSlice.reducer