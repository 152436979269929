// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from '@axios'

export const getPagedBookFiles = createAsyncThunk('BookFile/GetPagedBookFiles', async (bookFileOptions) => {
  const response = await axios.post(`/bookmanager/BookFile/GetPagedBookFiles`, bookFileOptions)
  return response
})

export const addBookFile = createAsyncThunk('BookFile/AddBookFile', async (model) => {
  const response = await axios.post(`/bookmanager/BookFile/AddBookFile`, model, {showToast : true})
  return response
})

export const updateBookFile = createAsyncThunk('BookFile/UpdateBookFile', async (model) => {
  const response = await axios.put(`/bookmanager/BookFile/UpdateBookFile`, model, {showToast : true})
  return response
})

export const deleteBookFile = createAsyncThunk('BookFile/Delete', async (id) => {
  await axios
    .delete(`/bookmanager/BookFile/Delete/${id}`, {
      crossdomain: true
    }, {showToast : true})
  return id
})

export const pdfToImage = createAsyncThunk('BookFile/PdfToImage', async (id) => {
  const response = await axios.get(`/bookmanager/BookFile/PdfToImage`, { params: { id } }, {showToast : true})
  return response
})

export const importWordXmlAsync = createAsyncThunk('BookManager/ImportWordXml', async (bookId) => {
  
  const response = await axios.get(`/bookmanager/Books/ImportWordXml/Importwordxml`, { params: { bookId } }, {showToast : true})
  return response
})

export const cropImagesFromPdf = createAsyncThunk('BookFile/CropImagesFromPdf', async (model) => {
  const response = await axios
    .get(`/bookmanager/BookFile/CropImagesFromPdf?id=${model.id}&questions=${model.convertQuestion}&subjects=${model.convertSubject}&options=${model.convertOption}`)
  return response
})

export const getBookFileTypes = createAsyncThunk('BookFile/getBookFileTypes', async () => {
  const response = await axios.get(`/bookmanager/BookFile/GetBookFileTypes`)
  return response
})

export const appBookFileSlice = createSlice({
  name: 'bookFileManager',
  initialState: {
    bookFiles: [],
    selectedFile: {},
    bookFileTypes: [],
    bookFileOptions: {
      pagingOptions: {
        sortClause: "decs",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        bookId: null,
        fileId: null,
        fileName: null,
        fileType: null
      }
    }
  },
  reducers: {
    setBookFileOptions: (state, action) => {
      state.bookFileOptions = action.payload
    },
    setSelectedFile: (state, action) => {
      state.selectedFile = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getPagedBookFiles.fulfilled, (state, action) => {
        state.bookFiles = action.payload.data
      })
    builder
      .addCase(getBookFileTypes.fulfilled, (state, action) => {
        state.bookFileTypes = []
        Object.entries(action.payload.data).map(([key, value]) => {
          state.bookFileTypes = [...state.bookFileTypes, { value: key, label: value }]
        })
      })
  }
})
export const { setBookFileOptions, setSelectedFile } = appBookFileSlice.actions
export default appBookFileSlice.reducer
