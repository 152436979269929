import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "@axios"

export const getExam = createAsyncThunk("exams/getExam", async (examId) => {
  const response = await axios.get(`/onlineExams/OnlineExamPortal/Get?id=${examId}`)
  return response.data
})

const solveExamSlice = createSlice({
  name: "solveExamStore",
  initialState: {
    exam: [],
    currentExam: {},
    currentQuestion: null,
    totalQuestionCount: 0
  },
  reducers: {
    setCurrentQuestion: (state, action) => {
      const questionList = []
      state.currentExam.sections.map((aa) => aa.branches.map((b) => questionList.push(b.questions)))
      const merged = [].concat.apply([], questionList)
      state.currentQuestion = merged.find((x) => x.id === action.payload.id)
    },
    setUserAnswer: (state, action) => {
      const questionList = []
      state.currentExam.sections.map((aa) => aa.branches.map((b) => questionList.push(b.questions))
      )
      const merged = [].concat.apply([], questionList)
      const question = merged.find((x) => x.id === action.payload.questionId)
      if (question.userAnswer === action.payload.userAnswer) {
        question.userAnswer = null
      } else {
        question.userAnswer = action.payload.userAnswer
      }
      state.currentQuestion = question
    },
    nextQuestion: (state) => {
      const questionList = []
      state.currentExam.sections.map((aa) => aa.branches.map((b) => questionList.push(b.questions))
      )
      const merged = [].concat.apply([], questionList)
      const currentIndex = merged.findIndex(
        (x) => x.id === state.currentQuestion.id
      )
      state.currentQuestion = merged[currentIndex + 1]
    },
    prevQuestion: (state) => {
      const questionList = []
      state.currentExam.sections.map((aa) => aa.branches.map((b) => questionList.push(b.questions)))
      const merged = [].concat.apply([], questionList)
      const currentIndex = merged.findIndex(
        (x) => x.id === state.currentQuestion.id
      )
      state.currentQuestion = merged[currentIndex - 1]
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getExam.fulfilled, (state, action) => {
      state.currentExam = action.payload
      // * sets first question 
      state.currentQuestion =
        action.payload.sections[0].branches[0].questions[0]
      // * sets total question count 
      let totalQuestionCount = 0
      action.payload.sections.map(y => {
        y.branches.map(z => {
          totalQuestionCount += z.questions.length
        })
      })
      state.totalQuestionCount = totalQuestionCount
    })
  }
})

export const { prevQuestion, nextQuestion, setCurrentQuestion, setUserAnswer } =
  solveExamSlice.actions
export default solveExamSlice.reducer
