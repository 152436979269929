// * redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// * axios Imports
import axios from '@axios'

// * assignment thunks
export const getPagedAssignment = createAsyncThunk('Assignment/GetPaged', async (assignmentOptions) => {
  const response = await axios.post('/assignments/Assignment/GetPaged', assignmentOptions)
  return response
})

export const getAssignmentResults = createAsyncThunk('Assignment/GetAssignmentResults', async (assignmentId) => {
  const response = await axios.get(`/assignments/Assignment/GetAssignmentResults?assignmentId=${assignmentId}`)
  return response
})

const assignmentReadSlice = createSlice({
  name: 'assignmentRead',
  initialState: {
    assignments: {},
    assignmentOptions: {
      pagingOptions: {
        sortClause: "Id",
        orderBy: "desc",
        pageIndex: 0,
        pageSize: 7
      },
      spec: {
        title: "",
        lowStartDate: null,
        highStartDate: null,
        lowEndDate: null,
        highEndDate: null,
        creatorUserId: null,
        classRoomIds: [],
        branchIds: [],
        assignmentStatus: null
      }      
    },
    assignmentResults : {}
  },
  reducers: {
    setAssignmentOptions: (state, action) => {
      state.assignmentOptions = action.payload
    }
  },
  extraReducers: builder => {
    builder
    .addCase(getPagedAssignment.fulfilled, (state, action) => {
      state.assignments = action.payload.data
    })
    builder
    .addCase(getAssignmentResults.fulfilled, (state, action) => {
      state.assignmentResults = action.payload.data
    })
  }
})
export const { 
  setAssignmentOptions
} = assignmentReadSlice.actions
export default assignmentReadSlice.reducer