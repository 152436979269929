// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from '@axios'


// * Thunks for course
export const getCourse = createAsyncThunk('quizmanager/GetCourse', async (id) => {
  const response = await axios.get(`/quizmanager/Course/GetCourse?id=${id}`)
  return response
})

export const getCourseContent = createAsyncThunk('quizmanager/GetCourseContents', async (id) => {
  const response = await axios.get(`/quizmanager/Course/GetCourseContents?id=${id}`)
  return response
})


// * Thunks for Quiz
export const getQuiz = createAsyncThunk('quizmanager/GetQuiz', async (id) => {
  const response = await axios.get(`/quizmanager/Quiz/GetQuiz?id=${id}`)
  return response
})

// * Thunks for questionSolution ********
export const getQuestionSolution = createAsyncThunk('quizmanager/GetQuestionSolution', async (solutionId) => {
  const response = await axios.get(`/quizmanager/QuestionSolution/GetQuestionSolution?id=${solutionId}`)
  return response
})

export const getQuestionPool = createAsyncThunk('questionPool/getQuestionPool', async (id) => {
  const response = await axios.get(`/questionPools/QuestionPool/GetQuestionPool/${id}`)
  return response
})


const appContentManagerSlice = createSlice({
  name: 'contentManager',
  initialState: {
    content: {},
    selectedContent: {},
    quizRootIds: [],
    courseRootIds: [],
    draggableItems: [],
    courseContent: {},
    selectedCourse: {}
  },
  reducers: {
    setSelectedContent: (state, action) => {
      state.selectedContent = action.payload
    },
    setContent: (state, action) => {
      const content = state.content
      if (content.courseContents) {
        content.courseContents.map((item, index) => {
          if (item.question !== null) {
            content.courseContents[index].question = item.question.id === action.payload.id ? action.payload : item.question
          } else if (item.subject !== null) {
            content.courseContents[index].subject = item.subject.id === action.payload.id ? action.payload : item.subject
          }
        })
      } else {
        content.questions = content.questions.map(item => ((item.id === action.payload.id ? action.payload : item)))
      }
      state.content = content
    },
    setDraggableItems: (state, action) => {
      state.draggableItems = action.payload
    }
  },
  extraReducers: builder => {
    builder
    .addCase(getCourse.fulfilled, (state, action) => {
      state.content = action.payload.data
      if (action.payload.data.courseContents.length > 0) {
        if (action.payload.data.courseContents[0].question) {
          state.selectedContent = action.payload.data.courseContents[0].question
        } else if (action.payload.data.courseContents[0].subject) {
          state.selectedContent = action.payload.data.courseContents[0].subject
        }
      }
    })
    builder
    .addCase(getQuiz.fulfilled, (state, action) => {
      state.content = action.payload.data
      state.selectedContent = action.payload.data.questions[0]
    })
    builder
    .addCase(getQuestionPool.fulfilled, (state, action) => {
      state.content = {...action.payload.data, startPage:1}
      state.selectedContent = action.payload.data.questions[0]
    })
    builder
    .addCase(getCourseContent.fulfilled, (state, action) => {
      state.courseContent = action.payload.data
    })
  }
})
export const { setSelectedContent, setContent, setDraggableItems } = appContentManagerSlice.actions
export default appContentManagerSlice.reducer