import axios from "@axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

export const getUsersClassRooms = createAsyncThunk(
  `classRoomResults/getUsersClassRooms`,
  async (spec) => {
    return await axios.post(
      `examStatistics/classRoomExams/getClassRoomsWithResults`,
      spec,
      { showLoader: true }
    )
  }
)

const classRoomExamResultSlice = createSlice({
  name: "classRoomExamResultSlice",
  initialState: {
    classRooms: [],
    spec: {
      classRoomIds: [],
      classRoomTitle: ""
    }
  },
  reducers: {
    setSpec: (state, action) => {
      state.spec = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUsersClassRooms.fulfilled, (state, action) => {
      state.classRooms = action.payload.data
    })
  }
})

export const { setSpec } = classRoomExamResultSlice.actions
export default classRoomExamResultSlice.reducer
