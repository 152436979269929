// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import axios from "@axios"

//* dealers
export const getPagedDealers = createAsyncThunk(
    "dealerManager/getPagedDealers",
    async (params) => {
        const response = await axios.post(
            `/dealer/Dealer/GetPaged`,
            params,
            { showLoader: true }
        )
        return response
    }
)

export const getDealers = createAsyncThunk(
    "dealerManager/getDealers",
    async (params) => {
        const response = await axios.post(
            `/dealer/Dealer/GetAll`,
            params, { showLoader: true }
        )
        return response
    }
)

export const getDealer = createAsyncThunk(
    "dealerManager/getDealer",
    async (params) => {
        const response = await axios.get(
            `/dealer/Dealer/Get?dealerId=${params}`
        )
        return response
    }
)

export const addDealer = createAsyncThunk(
    "dealerManager/addDealer",
    async (params) => {
        const response = await axios.post(
            `dealer/Dealer/Create`,
            params, { showToast: true }
        )
        return response
    }
)

export const updateDealer = createAsyncThunk(
    "dealerManager/updateDealer",
    async (params) => {
        const response = await axios.put(
            `delaer/Dealer/Update`,
            params, { showToast: true }
        )
        return response
    }
)

export const deleteDealer = createAsyncThunk(
    "dealerManager/deleteDealer",
    async (id) => {
        const response = await axios.delete(
            `dealer/Dealer/Delete/${id}`
        )
        return response
    }
)

//* dealerSchool
export const getPagedDealerSchools = createAsyncThunk(
    "dealerManager/getPagedDealerSchools",
    async (params) => {
        await new Promise(resolve => setTimeout(resolve, 500))

        const response = await axios.post(
            `dealer/DealerSchool/GetPaged`,
            params, { showLoader: true }
        )
        return response
    }
)

export const addDealerSchool = createAsyncThunk(
    "dealerManager/addDealerSchool",
    async (params) => {
        const response = await axios.post(
            `dealer/DealerSchool/AddDealerSchool`,
            params, {showToast: true}
        )
        return response
    }
)

export const deleteDealerSchool = createAsyncThunk(
    "dealerManager/deleteDealerSchool",
    async (id) => {
        const response = await axios.delete(
            `dealer/DealerSchool/Delete/${id}`,
            {showToast: true}
        )
        return response
    }
)

//* dealerUser
export const getPagedDealerUsers = createAsyncThunk(
    "dealerManager/getPagedDealerUsers",
     async (params) => {
        const response = await axios.post(
            `dealer/DealerUser/GetPaged`,
            params, {showLoader: true}
        )
        return response
     }
)

export const getDealerUsers = createAsyncThunk(
    "dealerManager/getDealerUsers",
    async (params) => {
        const response = await axios.post(
            `dealer/DealerUser/GetAll`,
            params, {showLoader: true}
        )
        return response
    }
)

export const addDealerUser = createAsyncThunk(
    "dealerManager/addDealerUser",
     async (params) => {
        const response = await axios.post(
            `dealer/DealerUser/AddDealerUser`,
            params, {showToast: true}
        )
        return response
     }
)

export const getSelectableDealerUsers = createAsyncThunk(
    "dealerManager/getSelectableDealerUsers",
    async (dealerId) => {
        const response = await axios.get(
            `dealer/DealerUser/GetSelectableDealerUsers/${dealerId}`, {showLoader: true}
        )
        return response
    }
)

export const addSelectedDealerUsers = createAsyncThunk(
    "dealerManager/addSelectedDealerUsers",
    async (params) => {
        const response = await axios.post(
            `dealer/DealerUser/AddSelectedDealerUsers`,
            params, {showToast: true}
        )
        return response
    }
)

export const deleteDealerUser = createAsyncThunk(
    "dealerManager/deleteDealerUser",
    async (id) => {
        const response = await axios.delete(
            `dealer/DealerUser/Delete/${id}`
        )
        return response
    }
)

export const appDealerSlice = createSlice({
    name: "dealerStore",
    initialState: {
        // dealers
        dealers: [],
        selectedDealer: {},
        dealerOptions: {
            pagingOptions: {
                sortClause: "asc",
                orderBy: "Id",
                pageIndex: 0,
                pageSize: 10
            },
            spec: {
                name: "",
                address: "",
                phone: "",
                districtIds: [],
                provinceIds: [],
                provinceId: null
            }
        },
        // dealerUsers
        dealerUsers: [],
        selectableDealerUsers: [],
        dealerUserOptions: {
            pagingOptions: {
                sortClause: "asc",
                orderBy: "Id",
                pageIndex: 0,
                pageSize: 10
            },
            spec: {
                dealerIds: {},
                userIds: {},
                dealerId: 0,
                userId: null,
                userName: "",
                dealerName: ""
            }
        },
        // dealerSchools
        dealerSchools: [],
        dealerSchoolOptions: {
            pagingOptions: {
                sortClause: "asc",
                orderBy: "Id",
                pageIndex: 0,
                pageSize: 10
            },
            spec: {
                dealerIds: {},
                dealerId: 0,
                schoolIds: {},
                schoolId: 0,
                dealerName: "",
                schoolName: ""
            }
        }
    },
    reducers: {
        setSelectedDealer: (state, action) => {
            state.selectedDealer = action.payload
        },
        setDealerOptions: (state, action) => {
            state.dealerOptions = action.payload
        },
        setDealerUserOptions: (state, action) => {
            state.dealerUserOptions = action.payload
        },
        setDealerSchoolOptions: (state, action) => {
            state.dealerSchoolOptions = action.payload
        },
        setSelectableDealerUsers: (state, action) => {
            state.selectableDealerUsers = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPagedDealers.fulfilled, (state, action) => {
                state.dealers = action.payload.data.data
            })
            .addCase(getPagedDealerUsers.fulfilled, (state, action) => {
                state.dealerUsers = action.payload.data
            })
            .addCase(getPagedDealerSchools.fulfilled, (state, action) => {
                
                state.dealerSchools = action.payload.data
            })
            .addCase(getDealer.fulfilled, (state, action) => {
                state.selectedDealer = action.payload.data
            })
            .addCase(getSelectableDealerUsers.fulfilled, (state, action) => {
                state.selectableDealerUsers = action.payload.data
            })
    }
})

export const {setDealerOptions, setDealerSchoolOptions, setDealerUserOptions, setSelectableDealerUsers, setSelectedDealer } = appDealerSlice.actions

export default appDealerSlice.reducer