import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import axios from "@axios"

export const getBranches = createAsyncThunk(
  "common/getBranches",
  async (pagingOptions) => {
    const response = await axios.post(
      `/common/Branch/GetPagedBranches`,
      pagingOptions
    )
    return response
  }
)

export const branchStoreSlice = createSlice({
  name: "branchStore",
  initialState: {
    branches: []
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getBranches.fulfilled, (state, action) => {
      state.branches = action.payload.data
    })
  }
})
export const {} =
  branchStoreSlice.actions

export default branchStoreSlice.reducer
