// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** axios Imports
import axios from '@axios'

export const getPagedAssignmentContentFile = createAsyncThunk('AssignmentContentFile/GetPaged', async(assignmentContentFileOptions) => {
  const response = await axios.post('/assignments/AssignmentContentFile/GetPaged', assignmentContentFileOptions)
  return response
})

const appAssignmentContentFileSlice = createSlice({
  name: 'assignmentContentFile',
  initialState: {
    assignmentContentFiles: [],
    assignmentContentFileOptions : {
      pagingOptions: {
        sortClause: "desc",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        title: "",
        assignmentContentId: null,
        fileEntryId: null
      }
    }
  }, 
  reducers: {
    setAssignmentContentFileOptions: (state, action) => {
      state.assignmentContentFileOptions = action.payload
    }
  },
  extraReducers: builder => {
    builder
    .addCase(getPagedAssignmentContentFile.fulfilled, (state, action) => {
      state.assignmentContentFiles = action.payload.data
    })
  }
})
export const { setAssignmentContentFileOptions } = appAssignmentContentFileSlice.actions
export default appAssignmentContentFileSlice.reducer