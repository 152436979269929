import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "@axios"
import { pagingOptions } from "../../../../../../configs/appConstants"

// * meeting providers
export const getMeetingProviders = createAsyncThunk('lecturemanager/getMeetingProviders', async (options) => {
    const response = await axios
        .post(`/lecturemanager/MeetingProvider/GetPaged`, options)
        return response
})

// * meeting accounts
export const getMeetingAccounts = createAsyncThunk('lecturemanager/getMeetingAccounts', async (options) => {
    const response = await axios
            .post(`/lecturemanager/MeetingAccount/GetPaged`, options)
    return response
})

export const getAllSchools = createAsyncThunk('common/GetPagedSchools', async (schoolOptions) => {
    const response = await axios
        .post(`/common/School/GetPagedSchools`, schoolOptions)
    return response
})

export const getUserSchools = createAsyncThunk('organization/GetPagedUserSchools', async (schoolOptions) => {
    const response = await axios
        .post(`/organisation/SchoolUser/GetPagedUserSchools`, schoolOptions)
    return response
})

export const appMeetingSlice = createSlice({
    name: 'meeting',
    initialState: {
        meetingProviders: [],
        meetingAccounts: [],
        schools: [],
        options: {
            pagingOptions,
            spec: {
                title: null,
                clientId: null,
                clientSecret: null,
                executiveEmailAccount: null,
                isCommon: true,
                meetingProviderType: 1
            }
        },
        meetingAccountOptions: {
            pagingOptions,
            spec: {
                email: null,
                meetingUserId: null,
                userId: null,
                meetingProviderId: null
            }
        },
        schoolOptions: {
            pagingOptions,
            spec: {
                districtId: null,
                Id: null,
                isMainSchool: null
            }
        }
    },
    reducer: {

    },
    extraReducers: builder => {
        builder
            .addCase(getMeetingProviders.fulfilled, (state, action) => {
                state.meetingProviders = action.payload.data
            })
        builder
            .addCase(getMeetingAccounts.fulfilled, (state, action) => {
                state.meetingAccounts = action.payload.data
            })
        builder
            .addCase(getAllSchools.fulfilled, (state, action) => {
                state.schools = action.payload.data
            })
        builder
            .addCase(getUserSchools.fulfilled, (state, action) => {
                state.schools = action.payload.data
            })
    }
})
export const { } = appMeetingSlice.actions
export default appMeetingSlice.reducer