import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "@axios"

export const getPagedSchoolOnlineExams = createAsyncThunk('onlineExams/GetPagedSchoolOnlineExams', async (options) => {
    const response = await axios.post(`/onlineExams/SchoolOnlineExam/GetPagedSchoolOnlineExams`, options)
    return response
})

export const getPagedOnlineExams = createAsyncThunk('onlineExams/GetPagedOnlineExams', async ({options, id}) => {
    const response = await axios.post(`/onlineExams/SchoolOnlineExam/GetPagedOnlineExams/${id}`, options)
    return response
})

export const appSchoolExamsSlice = createSlice({
    name: 'schoolExams',
    initialState: {
        schoolExams: {},
        onlineExams: {},
        selectedSchoolExam: {},
        schoolExamsOptions: {
            pagingOptions: {
                sortClause: "id",
                orderBy: "desc",
                pageIndex: 0,
                pageSize: 10
            },
            spec: {
                title: '',
                schoolId: null,
                classIds: [],
                examCategoryId: null
            }
        },
        onlineExamOptions: {
            pagingOptions: {
                sortClause: "id",
                orderBy: "desc",
                pageIndex: 0,
                pageSize: 10
            },
            spec: {
                title: '',
                classIds: [],
                examCategoryId: null
            }
        }
    },
    reducers: {
        setSchoolOnlineExamOptions: (state, action) => {
            state.schoolExamsOptions = action.payload
        },
        setSelectedSchoolExam: (state, action) => {
            state.selectedSchoolExam = action.payload
        },
        setOnlineExamOptions: (state, action) => {
            state.onlineExamOptions = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getPagedSchoolOnlineExams.fulfilled, (state, action) => {
                state.schoolExams = action.payload.data
            })
            .addCase(getPagedOnlineExams.fulfilled, (state, action) => {
                state.onlineExams = action.payload.data
            })
    }
})
export const 
{
    setSchoolOnlineExamOptions,
    setSelectedSchoolExam,
    setOnlineExamOptions
} = appSchoolExamsSlice.actions

export default appSchoolExamsSlice.reducer