// * redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// * axios Imports
import axios from '@axios'

// * assignment thunks
export const getPagedAssignment = createAsyncThunk('Assignment/GetPaged', async (assignmentOptions) => {
  const response = await axios.post('/assignments/Assignment/GetPaged', assignmentOptions)
  return response
})

// * assignment content thunks
export const getPagedAssignmentContent = createAsyncThunk('AssignmentContent/GetPaged', async (assignmentContentOptions) => {
  const response = await axios.post('/assignments/AssignmentContent/GetPaged', assignmentContentOptions)
  return response
})

export const getAssignmentTypes = createAsyncThunk('AssignmentContent/GetTypes', async () => {
  const response = await axios.get('/assignments/AssignmentContent/GetContentTypes')
  return response
})

export const getSelectedStudentList = createAsyncThunk('', async (assignmentId) => {
  const response = await axios.post('/assignments/AssignmentUser/GetPaged', 
  {
    pagingOptions: {
      sortClause: "desc",
      orderBy: "id",
      pageIndex: 0,
      pageSize: 1000000
    },
    spec: {
      assignmentId
    }
  })
  return response
})

const appAssignmentPortalSlice = createSlice({
  name: 'assignmentPortal',
  initialState: {
    // * assignment states
    assignments: {},
    selectedAssignment: {},
    updateAssignment:{},
    assignmentOptions: {
      pagingOptions: {
        sortClause: "createOn",
        orderBy: "desc",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        title: "",
        lowStartDate: null,
        highStartDate: null,
        lowEndDate: null,
        highEndDate: null,
        creatorUserId: null,
        classRoomIds: [],
        branchIds: [],
        assignmetStatus: null
      }      
    },
    // *assignment content states
    assignmentContents: {},
    selectedAssignmentContent: {},
    assignmentContentOptions: {
      pagingOptions: {
        sortClause: "desc",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 100000
      },
      spec: {
        title: "",
        assignmentId: null,
        entityId: null,
        assignmentType: null,
        branchId: null,
        userId: ""
      }
    },
    assignmentTypes: [],
    selectedStudentList: {}
  },
  reducers: {
    setAssignmentOptions: (state, action) => {
      state.assignmentOptions = action.payload
    },
    setSelectedAssignment: (state, action) => {
      state.selectedAssignment = action.payload
      state.selectedStudentList = {}
    },
    setUpdateAssignment: (state, action) => {
      state.updateAssignment = action.payload
    },
    setAssignmentContentOptions: (state, action) => {
      state.assignmentContentOptions = action.payload
    },
    setSelectedAssignmentContent: (state, action) => {
      state.selectedAssignmentContent = action.payload
    },
    setClearAssignmentContents: (state) => {
      state.assignmentContents = {}
    }
  },
  extraReducers: builder => {
    builder
    .addCase(getPagedAssignment.fulfilled, (state, action) => {
      state.assignments = action.payload.data
    })
    .addCase(getPagedAssignmentContent.fulfilled, (state, action) => {
      state.assignmentContents = action.payload.data
    })
    .addCase(getAssignmentTypes.fulfilled, (state, action) => {
      state.assignmentTypes = action.payload.data
    })
    .addCase(getSelectedStudentList.fulfilled, (state, action) => {
      state.selectedStudentList = action.payload.data
    })
  }
})
export const { 
  setAssignmentOptions, 
  setSelectedAssignment,
  setUpdateAssignment,
  setAssignmentContentOptions,
  setSelectedAssignmentContent,
  setClearAssignmentContents
} = appAssignmentPortalSlice.actions
export default appAssignmentPortalSlice.reducer