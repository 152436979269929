// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** axios Imports
import axios from '@axios'

export const getPagedAssignmentContent = createAsyncThunk('AssignmentContent/GetPaged', async(assignmentContentOptions) => {
  const response = await axios.post('/assignments/AssignmentContent/GetPaged', assignmentContentOptions)
  return response
})

export const getContentTypes = createAsyncThunk('AssignmentContent/GetContentTypes', async () => {
  const response = await axios.get('/assignments/AssignmentContent/GetContentTypes')
  return response
})

const appAssignmentContentSlice = createSlice({
  name: 'assignmentContent',
  initialState: {
    assignmentContents: [],
    selectedAssignmentContent: {},
    contentTypes: [],
    assignmentContentOptions : {
      pagingOptions: {
        sortClause: "desc",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        title: "",
        assignmentId: null,
        entityId: null,
        assignmentType: null,
        branchId: null
      }
    }
  }, 
  reducers: {
    setAssignmentContentOptions: (state, action) => {
      state.assignmentContentOptions = action.payload
    },
    setSelectedAssignmentContent: (state, action) => {
      state.selectedAssignmentContent = action.payload
    }
  },
  extraReducers: builder => {
    builder
    .addCase(getPagedAssignmentContent.fulfilled, (state, action) => {
      state.assignmentContents = action.payload.data
    })
    .addCase(getContentTypes.fulfilled, (state, action) => {
      state.contentTypes = action.payload.data
    })
  }
})
export const { setAssignmentContentOptions, setSelectedAssignmentContent } = appAssignmentContentSlice.actions
export default appAssignmentContentSlice.reducer