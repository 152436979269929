import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import axios from "@axios"

export const getPagedLessons = createAsyncThunk('common/getPagedLessons', async (options) => {
  const response = await axios.post(`/common/lesson/getPagedLessons`, options)

  return response
})

export const appLessonSlice = createSlice({
  name: 'lesson',
  initialState: {
    lessons: []
    
  },
  reducers: {
    
  },
  extraReducers: builder => {
    builder
      .addCase(getPagedLessons.fulfilled, (state, action) => {
        state.lessons = action.payload.data
      })
  }
})

export const { } = appLessonSlice.actions
export default appLessonSlice.reducer