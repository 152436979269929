import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import axios from '@axios'


export const getPage = createAsyncThunk("bookManager/getPage", async (params) => {
    const response = await axios.get(`/bookmanager/BookPage/GetContentStartPage`, 
    {
      params: { courseId: params.courseId, quizId:params.quizId, pageNumber: params.pageNumber }
    })
    return response
  })

  export const getPage2 = createAsyncThunk("bookManager/getPage2", async (params) => {
    const response = await axios.get(`/bookmanager/BookPage/GetBookPage`, 
    {
      params: { bookId: 2, PageNo: params.pageNumber, includePageImages:true }
    })
    return response
  })
 
  export const bookPageStoreSlice = createSlice({
    name: "bookPageStore",
    initialState: {
      bookId: {},
      course: {},
      isCropImageActive: true,
      currentPage:null
    },
    reducers: {
     
    },
    extraReducers: (builder) => {
      builder
        .addCase(getPage.fulfilled, (state, action) => {
          state.currentPage = action.payload.data
        }) 
        .addCase(getPage2.fulfilled, (state, action) => {
          state.currentPage = action.payload.data
        })
    }
  })

  export default bookPageStoreSlice.reducer
