/* eslint-disable no-unused-vars */
// * redux Imports
import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit"
// * axios Imports
import axios from "@axios"
import { isObjEmpty } from "@utils"

// * chat message thunks
export const getChatMessages = createAsyncThunk(
  "ChatMessage/GetPaged",
  async (chatMessageOptions) => {
    const response = await axios.post(
      "/communication/ChatMessage/GetPaged",
      chatMessageOptions, {showLoader:true}
    )
    return response
  }
)

// * chat room thunks
export const getChatRooms = createAsyncThunk(
  "ChatRoom/GetPaged",
  async (chatRoomOptions) => {
    const response = await axios.post(
      "/communication/ChatRoom/GetPaged",
      chatRoomOptions
    )
    return response
  }
)
export const updateChatRoom = createAsyncThunk(
  "ChatRoom/Update",
  async (chatRoomOptions) => {
    const response = await axios.put(
      "/communication/ChatRoom/Update",
      chatRoomOptions
    )
    return response
  }
)

// * chat participant thunks
export const getAllParticipantInRoom = createAsyncThunk(
  "ChatParticipant/GetPaged",
  async (chatRoomId) => {
    const response = await axios.post(
      "/communication/ChatParticipant/GetPaged",
      {
        pagingOptions: {
          sortClause: "Id",
          orderBy: "desc",
          pageIndex: 1,
          pageSize: 99
        },
        spec: { chatRoomId }
      },
      {
        showLoader: true
      }
    )
    return response
  }
)
export const chatParticipantCreate = createAsyncThunk(
  "ChatParticipant/Create",
  async (data) => {
    const response = await axios.post(
      "/communication/ChatParticipant/Create",
      data
    )
    return response
  }
)

const messageChannelSlice = createSlice({
  name: "communicationStore",
  initialState: {
    // * hub states *//
    changedParticipant: {},
    chatNotification: {},
    // * communication states *//
    // * chat message states
    chatMessages: {},
    chatMessageOptions: {
      pagingOptions: {
        sortClause: "CreatedOn",
        orderBy: "desc",
        pageIndex: 1,
        pageSize: 10
      },
      spec: {
        content: "",
        attachment: null,
        relatedMessageId: null,
        chatRoomId: null,
        createdOn: null
      }
    },
    // * chat room states
    chatRooms: {},
    selectedChatRoom: {},
    selectedChatRoomById: null,
    chatRoomOptions: {
      pagingOptions: {
        sortClause: "UpdatedOn desc, CreatedOn",
        orderBy: "Desc",
        pageIndex: 1,
        pageSize: 15
      },
      spec: {
        title: "",
        getOnlyMyRooms: true,
        type: null
      }
    },
    // * chat participant
    chatParticipant: {},
    // * set scroll position
    onScrollMsgId: null
  },
  reducers: {
    setReceivedMessage: (state, action) => {
      // * copy of exist msg

      const roomIndex = state.chatRooms.items.findIndex(
        (x) => x.id === action.payload.chatRoomId
      )
      const existingChatMessages = current(state.chatMessages)
      const selectedRoom = current(state.selectedChatRoom)
      const chatRoomId = selectedRoom.id
      if (chatRoomId === action.payload.chatRoomId) {
        if (
          !isObjEmpty(existingChatMessages) &&
          existingChatMessages.items.length > 0
        ) {
          const isInclude = existingChatMessages.items.find(
            (x) => x.id === action.payload.id
          )
          if (!isInclude) {
            state.chatMessages = {
              ...existingChatMessages,
              totalCount: existingChatMessages.totalCount + 1,
              pageSize: existingChatMessages.pageSize + 1,
              items: existingChatMessages.items.concat(action.payload)
            }
            // * set scroll on last msg
            state.onScrollMsgId = action.payload.id
          }
        } else {
          state.chatMessages = {
            hasNextPage: false,
            hasPreviousPage: false,
            isFirstPage: true,
            isLastPage: true,
            nextPage: 1,
            pageIndex: 1,
            pageSize: 10,
            previousPage: 1,
            totalCount: 1,
            totalPages: 1,
            items: [action.payload]
          }
        }
      } else {
        if (roomIndex !== -1) {
          state.chatRooms.items[roomIndex] = {
            ...state.chatRooms.items[roomIndex],
            unreadMessageCount:
              parseInt(state.chatRooms.items[roomIndex].unreadMessageCount) + 1
          }
        }
      }
      const item = state.chatRooms.items.splice(roomIndex, 1)[0]
      state.chatRooms.items.unshift(item)
    },
    setChangedRoom: (state, action) => {
      
      const { status, room } = action.payload
      const chatRoomsCopy = JSON.parse(JSON.stringify(state.chatRooms))
      const roomIndex = chatRoomsCopy.items.findIndex(
        (x) => x.id === room.id
      )
      switch (status) {
        case 0: // New chat room created
          if (roomIndex === -1) {
            // Ensure the room doesn't already exist
            state.chatRooms.items.unshift(room) // Add to the beginning of the array
            state.chatRooms.totalCount++
            state.chatRooms.pageSize++
          }
          break
        case 1: // Existing chat room updated
          if (roomIndex !== -1) {
            state.chatRooms.items[roomIndex] = room
          }
          break
        case 2: // Existing chat room deleted
          if (roomIndex !== -1) {
            state.chatRooms.items.splice(roomIndex, 1)
            state.chatRooms.totalCount--
            state.chatRooms.pageSize--
            state.selectedChatRoom = {}
          }
          break
        default:
          break
      }
      // // Reset unread message count for the selected chat room
    },
    setChangedParticipant: (state, action) => {
      // * get exist participant
      const exisgtingParticipant = JSON.parse(
        JSON.stringify(state.chatParticipant)
      )
      // * if had old one, delete
      const oldparticipantIndex = exisgtingParticipant.items
        .map((x) => x.userId)
        .indexOf(action.payload.participant.userId)
      if (oldparticipantIndex !== -1) exisgtingParticipant.items.splice(oldparticipantIndex, 1)

      switch (action.payload.status) {
        // * added new participant
        case 0:
          state.chatParticipant = {
            ...exisgtingParticipant,
            totalCount: exisgtingParticipant.totalCount + 1,
            items: [action.payload.participant].concat(
              exisgtingParticipant.items
            )
          }
          break
        // * updated participant
        case 1:
          state.chatParticipant = {
            ...exisgtingParticipant,
            items: [action.payload.participant].concat(
              exisgtingParticipant.items
            )
          }
          break
        default:
          console.log("undefined status type")
          break
      }
    },

    // * communication reducers *//
    setChatMessageOptions: (state, action) => {
      state.chatMessageOptions = action.payload
    },
    setChatRoomOptions: (state, action) => {
      state.chatRoomOptions = action.payload
    },
    setSelectedChatRoom: (state, action) => {
      // * find selected in a exist rooms
      // * then set selected rooms unreadMessageCount = 0
      const index = state.chatRooms.items
        .map((x) => x.id)
        .indexOf(action.payload.id)
      if (index !== -1) {
        state.chatRooms.items[index].unreadMessageCount = 0
      }
      if (!isObjEmpty(action.payload)) {
        state.selectedChatRoom = { ...action.payload, unreadMessageCount: 0 }
      }

      // * selected room changed, set messages clear
      state.chatMessages = {}
      // * selectedChatRoom and selectedChatRoomById should be same
      state.selectedChatRoomById = state.selectedChatRoom.id
    },
    setSelectedChatRoomById: (state, action) => {
      state.selectedChatRoomById = action.payload
    },
    setClearChatRooms: (state) => {
      state.chatRooms = {}
      state.selectedChatRoom = {}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChatMessages.fulfilled, (state, action) => {
        // * copy of exist msg
        const existingChatMessages = JSON.parse(
          JSON.stringify(state.chatMessages)
        )
        if (
          !isObjEmpty(existingChatMessages) &&
          existingChatMessages.items.length > 0
        ) {
          if (
            !isObjEmpty(action.payload.data) &&
            action.payload.data.items.length > 0
          ) {
            if (
              existingChatMessages.items[0].chatRoomId ===
              action.payload.data.items[0].chatRoomId
            ) {
              state.chatMessages = {
                ...action.payload.data,
                items: action.payload.data.items
                  .reverse()
                  .concat(existingChatMessages.items)
              }
            } else state.chatMessages = {
                ...action.payload.data,
                items: action.payload.data.items.reverse()
              }
          }
        } else if (action.payload.data.items) {
          state.chatMessages = {
            ...action.payload.data,
            items: action.payload.data.items.reverse()
          }
        }
        // * set scroll on last msg
        if (
          !isObjEmpty(action.payload.data) &&
          action.payload.data.items.length > 0
        ) {
          state.onScrollMsgId =
            action.payload.data.items[action.payload.data.items.length - 1].id
        } else if (
          !isObjEmpty(existingChatMessages) &&
          existingChatMessages.items.length > 0
        ) {
          state.onScrollMsgId =
            existingChatMessages.items[
              existingChatMessages.items.length - 1
            ].id
        } else state.onScrollMsgId = null
      })
      .addCase(getAllParticipantInRoom.fulfilled, (state, action) => {
        state.chatParticipant = action.payload.data
      })
      .addCase(getChatRooms.fulfilled, (state, action) => {
        // * copy of exist rooms and options
        const exisgtingChatRooms = JSON.parse(JSON.stringify(state.chatRooms))
        const exisgtingOptions = JSON.parse(
          JSON.stringify(state.chatRoomOptions)
        )

        switch (exisgtingOptions.spec.type) {
          case "filter":
            state.chatRooms = action.payload.data
            break
          case "scroll":
            state.chatRooms = {
              ...action.payload.data,
              items: exisgtingChatRooms.items.concat(action.payload.data.items)
            }
            break
          default:
            state.chatRooms = action.payload.data
            break
        }
      })
  }
})
export const {
  setReceivedMessage,
  setChangedRoom,
  setChangedParticipant,
  setUnreadMessageChanged,
  setChatMessageOptions,
  setChatRoomOptions,
  setSelectedChatRoom,
  setSelectedChatRoomById,
  setChatRooms,
  setChatMessages,
  setClearChatRooms
} = messageChannelSlice.actions
export default messageChannelSlice.reducer
