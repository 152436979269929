import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "@axios"
//classes
export const getClasses = createAsyncThunk(
  "common/getClasses",
  async (pagingOptions) => {
    const response = await axios.post(
      `/common/Class/GetPagedClasses`,
      pagingOptions, {showLoader: true}
    )
    return response
  }
)

export const classStoreSlice = createSlice({
  name: "common",
  initialState: {
    classes: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getClasses.fulfilled, (state, action) => {
      state.classes = action.payload.data
    })
  }
})
export const {} = classStoreSlice.actions

export default classStoreSlice.reducer
