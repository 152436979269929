// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "@axios"

export const getExamResult = createAsyncThunk(
  "examResults/get",
  async (id) => {
    const response = await axios.get(`/examStatistics/studentExams/getExamResult?examResultId=${id}`, { showLoader: true })
    return response
  }
)

export const examResultSlice = createSlice({
  name: "examResultStore",
  initialState: {
    examResult: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getExamResult.fulfilled, (state, action) => {
      state.examResult = action.payload.data
    })
  }
})

export default examResultSlice.reducer
