// adminAbility.js
import { AbilityBuilder, Ability } from "@casl/ability"
import { isObjEmpty } from "@utils"

function defineAbility(permissions) {
  const { can, cannot, build } = new AbilityBuilder(Ability)
  // Varsayılan olarak her şeyi engelle
  cannot("manage", "all")

  // Eğer yetkiler mevcutsa, yetkileri döngüye alarak tanımla
  if (permissions && !isObjEmpty(permissions)) {
    permissions.forEach(({ action, subject }) => {
      can(action, subject)
    })
  }

  // Adminlerin kendi yetkilerini düzenlemesini engelle
  cannot("update", "permissions")

  // Diğer admin özel yetkilerini burada tanımlayabilirsiniz

  return build({
    detectSubjectType: (item) => item.constructor.modelName
  })
}

export default defineAbility