// * third party imports
import moment from 'moment'
import 'moment/locale/tr'
import jwt from '../auth/jwt/jwt'
// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'numeric', day: 'numeric', year: 'numeric', hour:"numeric", minute:"numeric" }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('tr-TR', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true, checkIsToday = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && checkIsToday && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('tr-TR', formatting).format(new Date(value))
}

export const formatDateToYearShort = (value) => {
  const formatting = { year: 'numeric' }

  return new Intl.DateTimeFormat('tr-TR', formatting).format(new Date(value))
}

export const formatDateToTimeShort = (value) => {
  const formatting = { hour: 'numeric', minute: 'numeric' }

  return new Intl.DateTimeFormat('tr-TR', formatting).format(new Date(value))
}


/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => true
export const getUserData = () => jwt.getUser().user
export const getUserRole = () => JSON.parse(localStorage.getItem('userData')["roles"])

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = () => {
 const user = getUserData()
 if (user && user.roles) {
  // if (user.roles.includes("Administrator")) return '/dashboard/anasayfa'
  // if (user.roles.includes("Teacher")) return '/portal/anasayfa'
  // if (user.roles.includes('Student')) return '/portal/anasayfa'
  // else  { return '/portal/anasayfa' }
  return '/'
 }

}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

// convert object to selectOption
export const convertToSelectOption = (data) => {
  let options = []
  Object.entries(data).map(([key, value]) => {
    options = [...options, { value: parseInt(key), label: value }]
  })
  return options
}


export const pagingOptions = () => {
  return {
    sortClause: "desc",
    orderBy: "id",
    pageIndex: 0,
    pageSize: 10
  }
}

//auto download response files
export const autoDownloader = (response, fileName) => {
  const url = window.URL.createObjectURL(response.data)
  const element = document.createElement('a')
  element.href = url
  element.download = `${fileName}`
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}
export const removeObjectProperties = (obj, props) => {

  for (let i = 0; i < props.length; i++) {
    if (obj.hasOwnProperty(props[i])) {
      delete obj[props[i]]
    }
  }

}

// * return all file extension for sended type
export const getExtensions = (type) => {
  const imageExtensions = ['.jpeg', '.jpg', '.png', '.gif', '.bmp', '.svg']
  const videoExtensions = ['.mp4', '.avi', '.mov', '.mkv', '.wmv']
  const audioExtensions = ['.mp3', '.wav', '.ogg']
  switch (type) {
    case 'image':
      return imageExtensions
    case 'video':
      return videoExtensions
    case 'audio':
      return audioExtensions

    // * some cases type one of the extensions
    default:
      // * if types one of the extensions, return all extensions
      if (imageExtensions.includes(type)) return imageExtensions
      if (videoExtensions.includes(type)) return videoExtensions
      if (audioExtensions.includes(type)) return audioExtensions
      return []
  }
}

// * returns date with wanted format
export const dateFormatter = (date, includeTime) => {
  if (includeTime) {
    return  moment(date).locale('tr').format('LLL')
  } else return  moment(date).locale('tr').format('L')
}

export const convertDateTimeToSecond = (dateString) => {
  const parts = dateString.split(/:/)
  const timePeriods =
    (parseInt(parts[0], 10) * 60 * 60) +
    (parseInt(parts[1], 10) * 60) +
    parseInt(parts[2], 10)

    return timePeriods
}

export const makeArrUnique = (arr) => {
  const onlyUnique = (value, index, array) => array.indexOf(value) === index
  return arr.filter(onlyUnique)
}

export const toLowerCaseObject = (obj) => {
  const lowerCaseObj = {}

  Object.keys(obj).forEach(key => {
    // Değeri string kontrolü yaparak sadece string değerleri küçük harfe çeviriyoruz.
    if (typeof obj[key] === 'string') {
      lowerCaseObj[key] = obj[key].toLowerCase()
    } else {
      // String olmayan değerler doğrudan kopyalanır
      lowerCaseObj[key] = obj[key]
    }
  })

  return lowerCaseObj
}

