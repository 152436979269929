// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** axios Imports
import axios from '@axios'

export const getPagedPublishers = createAsyncThunk('PublisherManager/GetPagedPublishers', async (publisherOptions) => {
  const response = await axios.post(`/common/Publisher/GetPagedPublishers`, publisherOptions)
  return response
})

const appPublisherSlice = createSlice({
  name: 'publisherManager',
  initialState: {
    publishers: []
  }, 
  reducers: {
  },
  extraReducers: builder => {
    builder
    .addCase(getPagedPublishers.fulfilled, (state, action) => {
      state.publishers = action.payload.data
    })
  }
})
export const {} = appPublisherSlice.actions
export default appPublisherSlice.reducer