// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from '@axios'


export const getComments = createAsyncThunk("VideoLessonComment/GetComments", async (videoLessonCommentOptions) => {
  const response = await axios.post(`/videolessons/VideoLessonComment/GetComments`, videoLessonCommentOptions.spec)
  return response
})
export const getPagedComments = createAsyncThunk("VideoLessonComment/GetPagedComments", async (videoLessonCommentOptions) => {
  const response = await axios.post(`/videolessons/VideoLessonComment/GetPagedComments`, videoLessonCommentOptions)
  return response
})
export const createComment = createAsyncThunk("VideoLessonComment/Create", async (model) => {
  const response = await axios.post(`/videolessons/VideoLessonComment/Create`, model, {showToast : true})
  return response
})
export const updateComment = createAsyncThunk("VideoLessonComment/Update", async (model) => {
  const response = await axios.put(`/videolessons/VideoLessonComment/Update`, model, {showToast : true})
  return response
})
export const deleteComment = createAsyncThunk("", async (id) => {
  const response = await axios.delete(`/videolessons/VideoLessonComment/Delete/${id}`, {showToast : true})
  return response
})

export const appVideoLessonCommentSlice = createSlice({
  name: "videoLessonComment",
  initialState: {
    videoLessonComment: [],
    selectedvideoLessonComment : {},
    videoLessonCommentOptions: {
      pagingOptions: {
        sortClause: "desc",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        lowRating: null,
        highRating: null,
        comment: "",
        videoLessonsId: null   
      }
    }
  },
  reducers: {
    setSelectedvideoLessonComment: (state, action) => {
      state.selectedvideoLessonComment = action.payload
    },
    setVideoLessonCommentOptions: (state, action) => {
      state.videoLessonCommentOptions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getPagedComments.fulfilled, (state, action) => {
      state.videoLessonComment = action.payload.data
    })  
  }
})
export const { 
  setSelectedvideoLessonComment,
  setVideoLessonCommentOptions
 } = appVideoLessonCommentSlice.actions

export default appVideoLessonCommentSlice.reducer
