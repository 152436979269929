/* eslint-disable no-unused-vars */
// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "@axios"

export const getLessonList = createAsyncThunk(
  "appTodo/getLessonList",
  async (params) => {
    const parameters = {}
    if (params.studentId) parameters.userId = params.studentId
        const response = await axios.get(
      `/ExamStatistics/StudentStats/GetUserLessonStats`, {params: parameters}
    )
    return response
  }
)

export const getLessonSubjects = createAsyncThunk(
  "appTodo/getLessonSubjects",
  async (params) => {
    const response = await axios.post(
      `/ExamStatistics/StudentStats/GetLessonSubjects`,
      { lessonId: params.lessonId, userId: params.studentId}
    )
    return response
  }
)

export const appTodoSlice = createSlice({
  name: "appTodo",
  initialState: {
    lessons: null,
    lessonSubjects: []
  },
  reducers: {
    setLessons: (state, action) => {
    },
    reOrderTasks: (state, action) => {
      state.tasks = action.payload
    },
    selectTask: (state, action) => {
      state.selectedTask = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getLessonList.fulfilled, (state, action) => {
      state.lessons = action.payload.data
    })
    builder.addCase(getLessonSubjects.fulfilled, (state, action) => {
      state.lessonSubjects = action.payload.data
    })
  }
})

export const { setLessons, selectTask } = appTodoSlice.actions

export default appTodoSlice.reducer
