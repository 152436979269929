import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import axios from '@axios'

//classes

export const getRoles = createAsyncThunk("roleManager/getRoles", async (params) => {
  const response = await axios.post(`/identity/Roles/GetPagedRoles`, params)
  return response
})

export const removeRole = createAsyncThunk(
  "roleManager/removeRole",
  async (id) => {
    await axios.delete(`/identity/Roles/Delete`, {
      params: { id },
      crossdomain: true,
      showToast: true
    })
  }
)

export const getRolePermissions = createAsyncThunk(
  "roleManager/removeRole",
  async (id) => {
    await axios.delete(`/identity/Roles/Delete`, {
      params: { id },
      crossdomain: true,
      showToast : true

    })
  }
)

export const roleManagerSlice = createSlice({
  name: "roleManager",
  initialState: {
    roles: [],
    selectedRole: {},
    roleOptions: {
      pagingOptions: {
        sortClause: "decs",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        description: ""
      }
    }
  },
  reducers: {
    setSelectedRole: (state, action) => {
      state.selectedRole = action.payload
    },
    setRoleOptions: (state, action) => {
      state.roleOptions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.roles = action.payload.data
    })
  }
})
export const { setSelectedRole, setRoleOptions } = roleManagerSlice.actions

export default roleManagerSlice.reducer
