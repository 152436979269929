import userBooksStore from "@obroportal/views/UserBooks/store"

// * portal
import userSelect from "@obroportal/components/UserSelect/store"
import assignmentContentReports from "@obroportal/views/Assignment/AssignmentContents/AssignmentContentReports/store"
import assignmentPortal from "@obroportal/views/Assignment/store"
import assignmentManageStore from "@obroportal/views/Assignment/Manage/store"
import assignmentReadStore from "@obroportal/views/Assignment/Read/store"
import examPlan from "@obroportal/views/ExamPlan/store"
import createUserQuestionPoolStore from "@obroportal/views/QuestionPool/CreateUserQuestionPool/store"
import poolPreviewStore from "@obroportal/views/QuestionPool/UserQuestionPoolPreview/store"
import questionPoolPortalStore from "@obroportal/views/QuestionPool/store"
import personalBookStore from "@obroportal/views/PersonalBook/store"
import videoLessonPortal from "@obroportal/views/VideoLessons/store"
import weekSchedule from "@obroportal/views/WeekSchedule/store"
import reportCratorStore from "@obroportal/views/ReportCreator/store"

// * exam statistics
import classRoomExamResultSlice from "@obroportal/views/ExamStatistics/ClassRoomResults/store"
import examResultStore from "@obroportal/views/ExamStatistics/ExamResult/store"
import schoolExamResultDetailsSlice from "@obroportal/views/ExamStatistics/SchoolExamResults/SchoolResultDetails/SchoolResultDetails/store"
import schoolResultSlice from "@obroportal/views/ExamStatistics/SchoolExamResults/store"
import studentExamResultSlice from "@obroportal/views/ExamStatistics/StudentExamResults/store"

//Lecture
// import calendar from '@obroportal/views/Lecture/AddUpdateLecture/Calendar/store'
import meeting from '@obroportal/views/Lecture/AddUpdateLecture/Meeting/store'
import lectureStore from '@obroportal/views/Lecture/AddUpdateLecture/store'

//SchoolManager
import announcementStore from "@obroportal/views/AnnouncementManager/store"
import classRoomManagerStore from "@obroportal/views/SchoolManagement/ClassRoomDetail/store"
import schoolBooksStore from "@obroportal/views/SchoolManagement/SchoolBooks/store"
import schoolDetailStore from "@obroportal/views/SchoolManagement/SchoolDetail/store"
import organisationStore from "@obroportal/views/SchoolManagement/store"

//UserExam
import curriculumStore from "@obroportal/views/Curriculum/store"
import examEvaluationStore from "@obroportal/views/OnlineExams/ExamEvaluation/store"
import solveExamStore from "@obroportal/views/OnlineExams/SolveExam/store"
import onlineExamStore from "@obroportal/views/OnlineExams/store"
import todo from "@obroportal/views/StudentReport/store"

//PersonalStorage
import personalStorageStore from "@obroportal/views/PersonalStorage/store"

//DealerManager
import dealerManagerStore from "@obroportal/views/DealerManager/store"

//Comunication
import communicationStore from "@obroportal/views/Communication/store"
//Notification
import notificationStore from "@obroportal/views/Notification/store"

import questionSolutionStore from "@obroportal/views/QuestionSolutions/store"
import { combineReducers } from "redux"

const portalReducer = combineReducers({
  announcementStore,
  assignmentContentReports,
  assignmentManageStore,
  assignmentPortal,
  assignmentReadStore,
  classRoomExamResultSlice,
  classRoomManagerStore,
  communicationStore,
  curriculumStore,
  createUserQuestionPoolStore,
  dealerManagerStore,
  examEvaluationStore,
  examPlan,
  examResultStore,
  meeting,
  notificationStore,
  onlineExamStore,
  organisationStore,
  personalBookStore,
  personalStorageStore,
  poolPreviewStore,
  questionPoolPortalStore,
  reportCratorStore,
  schoolBooksStore,
  schoolDetailStore,
  schoolExamResultDetailsSlice,
  schoolResultSlice,
  classRoomExamResultSlice,
  meeting,
  solveExamStore,
  studentExamResultSlice,
  todo,
  userBooksStore,
  userSelect,
  videoLessonPortal,
  weekSchedule,
  questionSolutionStore,
  lectureStore
})
export default portalReducer
