// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** axios Imports
import axios from '@axios'

export const getTypeOfSchools = createAsyncThunk("common/getTypeOfSchools", async (model) => {
    const response = await axios.post(`/common/TypeOfSchool/GetPagedTypeOfSchools`, model)
    return response
})

const typeOfSchoolSlice = createSlice({
  name: 'typeOfSchools',
  initialState: {
    typeOfSchools: []
  }, 
  reducers: {
  },
  extraReducers: builder => {
    builder
    .addCase(getTypeOfSchools.fulfilled, (state, action) => {
      state.typeOfSchools = action.payload.data
    })
  }
})
export const {} = typeOfSchoolSlice.actions
export default typeOfSchoolSlice.reducer