import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import axios from "@axios"

import { pagingOptions } from "../../../../configs/appConstants"

//schools

export const getChildSchools = createAsyncThunk(
  "common/getSchools",
  async (schoolOptions) => {
    const response = await axios.post(
      `/common/School/GetPagedSchools`,
      schoolOptions
    )
    return response
  }
)
export const getSchool = createAsyncThunk(
  "organization/getSchool",
  async (model) => {
    const response = await axios.post(
      `/common/School/GetSchool`,
      model
    )
    return response
  }
)

export const organisationStoreSlice = createSlice({
  name: "organisation",
  initialState: {
    school: {},
    childSchools: {},
    schoolOptions: {
      pagingOptions,
      spec: {
        districtId: null,
        schoolId: null,
        parentId: null
      }
    }
  },
  reducers: {
    setSchoolOptions: (state, action) => {
      state.schoolOptions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getChildSchools.fulfilled, (state, action) => {
      state.childSchools = action.payload.data
    })
    builder.addCase(getSchool.fulfilled, (state, action) => {
        state.school = action.payload.data
      })
  }
})
export const {setSchoolOptions} = organisationStoreSlice.actions

export default organisationStoreSlice.reducer
