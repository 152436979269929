import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "@axios"

export const getPagedExams = createAsyncThunk("examEvaluation/getExams", async (options) => {
  const response = await axios.post(`/onlineExams/examEvaluation/getPagedExams`, options)
  return response
})

export const getPagedTemplates = createAsyncThunk("examEvaluation/getTemplates", async (options) => {
  const response = await axios.post(`/onlineExams/examEvaluation/getPagedTemplates`, options)
  return response
})

export const getTemplatesByCategory = createAsyncThunk('ExamEvaluation/GetSelectListTemplates', async (examCategoryId) => {
  const res = await axios.get(`/onlineExams/ExamEvaluation/GetSelectListTemplates/${examCategoryId}`)
  return res
})
export const getExam = createAsyncThunk('OnlineExam/GetExam', async (examId) => {
  const res = await axios.get(`/onlineExams/OnlineExam/GetOnlineExam/${examId}`)
  return res
})
const examEvaluationSlice = createSlice({
  name: "examEvaluation",
  initialState: {
    exams: [],
    selectedExam: {},
    templates: {},
    templatesByCategory: [],
    examResults: [],
    selectedExamResults: [],
    savedStudents: [],
    options: {
      pagingOptions: {
        sortClause: "Id",
        orderBy: "DESC",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        title: '',
        examId:null,
        schoolId:null,
        resultDate:null,
        examCategoryId: null,
        opticalFormTemplateName: ""
      }
    }
  },
  reducers: {
    setOptions: (state, action) => {
      state.options = action.payload
    },
    setSelectedExam: (state, action) => {
      state.selectedExam = action.payload
    },
    setExamResults: (state, action) => {
      state.examResults = action.payload
    },
    setSelectedExamResults: (state, action) => {
      state.selectedExamResults = action.payload
    },
    setSavedStudents: (state, action) => {
      state.savedStudents = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPagedExams.fulfilled, (state, action) => {
        state.exams = action.payload.data
      })
      .addCase(getPagedTemplates.fulfilled, (state, action) => {
        state.templates = action.payload.data
      })
      .addCase(getTemplatesByCategory.fulfilled, (state, action) => {
        state.templatesByCategory = action.payload.data
      })
  }
})

export const { 
  setOptions, 
  setSelectedExam, 
  setExamResults, 
  setSelectedExamResults,
  setSavedStudents
} = examEvaluationSlice.actions
export default examEvaluationSlice.reducer
