export const dayOfWeekStyleEnum = {
  1: 'bg-light-primary',
  2: 'bg-light-secondary',
  3: 'bg-light-danger',
  4: 'bg-light-info',
  5: 'bg-light-warning',
  6: 'bg-light-secondary',
  7: 'bg-light-success'
}

export const dayOfWeekString = {
  1: 'Pazartesi',
  2: 'Salı',
  3: 'Çarşamba',
  4: 'Perşembe',
  5: 'Cuma',
  6: 'Cumartesi',
  7: 'Pazar'
}

export const dayOfWeekNum = {
  Pazartesi: 1,
  Salı: 2,
  Çarşamba: 3,
  Perşembe: 4,
  Cuma: 5,
  Cumartesi: 6,
  Pazar: 7
}