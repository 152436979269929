import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import axios from "@axios"

export const getAnnouncements = createAsyncThunk(
  "announcements/getAnnouncements",
  async (pagingOptions) => {
    const response = await axios.post(
      `/announcements/Announcement/GetPaged`,
      pagingOptions
    )
    return response
  }
)

export const announcementStoreSlice = createSlice({
  name: "announcementStore",
  initialState: {
    announcements: []
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getAnnouncements.fulfilled, (state, action) => {
      state.announcements = action.payload.data
    })
  }
})
export const {} =
announcementStoreSlice.actions

export default announcementStoreSlice.reducer
