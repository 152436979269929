import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "@axios"

export const getPagedSchoolBooks = createAsyncThunk('organisation/getPagedSchoolBooks', async (options) => {
  const response = await axios.post(`/organisation/schoolBook/getPagedSchoolBooks`, options)
  return response
})

export const getPagedBooks = createAsyncThunk('organisation/GetPagedBooks', async ({ options, id }) => {
  const response = await axios.post(`/organisation/schoolBook/getPagedBooks/${id}`, options)
  return response
})

export const appSchoolBooksSlice = createSlice({
  name: 'schoolBooks',
  initialState: {
    schoolBooks: {},
    books: {},
    selectedSchoolBook: {},
    schoolBooksOptions: {
      pagingOptions: {
        sortClause: "id",
        orderBy: "desc",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        title: '',
        schoolId: null,
        branchIds: [],
        classIds: []
      }
    },
    bookOptions: {
      pagingOptions: {
        sortClause: "id",
        orderBy: "desc",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        title: '',
        classIds: [],
        branchIds: []
      }
    }
  },
  reducers: {
    setSchoolBookOptions: (state, action) => {
      state.schoolBooksOptions = action.payload
    },
    setSelectedSchoolBook: (state, action) => {
      state.selectedSchoolBook = action.payload
    },
    setBookOptions: (state, action) => {
      state.bookOptions = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getPagedSchoolBooks.fulfilled, (state, action) => {
        state.schoolBooks = action.payload.data
      })
      .addCase(getPagedBooks.fulfilled, (state, action) => {
        state.books = action.payload.data
      })
  }
})

export const
  {
    setSchoolBookOptions,
    setSelectedSchoolBook,
    setBookOptions
  } = appSchoolBooksSlice.actions

export default appSchoolBooksSlice.reducer

