// * redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// * axios Imports
import axios from '@axios'

// * thunks 
export const getPagedNotifications = createAsyncThunk('Notification/GetPaged', async (notOptions) => {
  const response = await axios.post('/notifications/Notification/GetPaged', notOptions)
  return response
})
export const getPagedUserNotifications = createAsyncThunk('NotificationUser/GetPagedUserNotifications', async (notUserOptions) => {
  const response = await axios.post('/notifications/NotificationUser/GetPagedUserNotifications', notUserOptions)
  return response
})
export const getUnreadNotification = createAsyncThunk('NotificationUser/GetUnreadNotification', async (unreadOptions) => {
  const response = await axios.post('/notifications/NotificationUser/GetPagedUserNotifications', unreadOptions)
  return response
})
export const changeIsRead = createAsyncThunk('NotificationUser/ChangeIsRead', async (params) => {
  const response = await axios.put(`/notifications/NotificationUser/ChangeIsRead/${params.itemId}?isRead=${params.isRead}`)
  return response
})

const notificationSlice = createSlice({
  name: 'notificationChannelStore',
  initialState: {
    // * hub states *//
    hubStarted: false,
    unreadNotifications: [],
    unreadOptions: {
      pagingOptions: {
        sortClause: "asc",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 100
      },
      spec: { isRead: false }
    },
    // * notification states *//
    notifications: {},
    notOptions: {
      pagingOptions: {
        sortClause: "asc",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        title: "",
        message: "",
        url: "",
        imageUrl: "",
        senderId: null,
        publishArea: null,
        notificationType: null,
        notifyDate: null,
        expiredDateTime: null,
        sentDateTime: null
      }
    },
    selectedNotification: {},
    // * user notification states *//
    userNotifications: {},
    notUserOptions: {
      pagingOptions: {
        sortClause: "asc",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 100
      },
      spec: {
        notificationId: null,
        userId: null,
        notificationType: null,
        title: '',
        isRead: null
      }
    },
    selectedUserNotification: {},
    checkedUserNotifications: []
  },
  reducers: {
    // * hub reducers *//
    setNotificationHubStarted: (state, action) => {
      if (action.payload) state.hubStarted = action.payload
    },
    setUnreadNotifications: (state, action) => {
      state.unreadNotifications = action.payload
    },
    // * notification reducers *//
    setNotOptions: (state, action) => {
      state.notOptions = action.payload
    },
    setNotUserOptions: (state, action) => {
      state.notUserOptions = action.payload
    },
    setSelectedNotification: (state, action) => {
      state.selectedNotification = action.payload
    },
    setSelectedUserNotification: (state, action) => {
      state.selectedUserNotification = action.payload
    },
    setCheckedUserNotifications: (state, action) => {
      const existingChecked = JSON.parse(JSON.stringify(state.checkedUserNotifications))
      if (!existingChecked.includes(action.payload)) {
        existingChecked.push(action.payload)
        state.checkedUserNotifications = existingChecked
      } else {
        const selectedItemIndex = existingChecked.indexOf(action.payload)
        existingChecked.splice(selectedItemIndex, 1)
        state.checkedUserNotifications = existingChecked
      }
    },
    setCheckAll: (state, action) => {
      state.checkedUserNotifications = action.payload
    }
  },
  extraReducers: builder => {
    builder
    .addCase(getPagedNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload.data
    })
    .addCase(getPagedUserNotifications.fulfilled, (state, action) => {
      state.userNotifications = action.payload.data
    })
    .addCase(getUnreadNotification.fulfilled, (state, action) => {
      state.unreadNotifications = action.payload.data.items
    })
  }
})
export const { 
  setNotificationHubStarted,
  setUnreadNotifications,
  setNotOptions,
  setNotUserOptions,
  setSelectedNotification,
  setSelectedUserNotification,
  setCheckedUserNotifications,
  setCheckAll
 } = notificationSlice.actions
export default notificationSlice.reducer