import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "@axios"


export const getSettings = createAsyncThunk('configurations/Get', async (spec) => {
    const response = await axios.post(`/configuration/Settings/Get`, spec)
    return response
})

export const appConfigurationSlice = createSlice({
    name: 'configurationStorage',
    initialState: {
        configurations: [],
        options: {
            spec: {
                group: ''
            }
        }
    },
    reducers: {
        setOptions: (state, action) => {
            state.options = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSettings.fulfilled, (state, action) => {
                state.configurations = action.payload.data
            })
    }
})

export const {setOptions} = appConfigurationSlice.actions
export default appConfigurationSlice.reducer