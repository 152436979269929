// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from '@axios'

// * folder thunks
export const getPersonalFolders = createAsyncThunk('personalStorage/GetAll', async (folderSpec) => {
    const response = await axios.post('/personalStorage/PersonalStorage/GetAll', folderSpec, {showLoader : true})
    return response
})

export const getPersonalFolder = createAsyncThunk('personalStorage/Get', async (folderSpec) => {
    const response = await axios.post('/personalStorage/PersonalStorage/Get', folderSpec, {showLoader: true})
    return response
})

// * file thunks
export const getPersonalFiles = createAsyncThunk('storage/Get', async (fileSpec) => {
    const response = await axios.post(`/storage/Files/Get`, fileSpec)
    return response
})

export const getAllPersonalFolders = createAsyncThunk('personalStorage/GetFolders', async (folderSpec) => {
    const response = await axios.post('/personalStorage/PersonalStorage/GetFolders', folderSpec, {showLoader : true})
    return response
})

export const removeFolder = createAsyncThunk('personalStorage/removeFolders', async (folder) => {
    await axios
    .delete(`/personalstorage/PersonalStorage/Delete/${folder.id}`, {
        params: { folderEntryType: folder.folderEntryType}
    })
    return id
})

export const addPersonalFile = createAsyncThunk('storage/addPersonalFiles', async (model) => {
    const response = await axios
        .post('/personalstorage/PersonalStorage/Upload', model, { showToast: true, showLoader: true })
    return response
})

export const updatePersonalFile = createAsyncThunk('storage/updatePersonalFiles', async (model) => {
    const response = await axios
        .put(`/storage/Files/Put/${model.id}`, model)
    return response
})

export const removeFile = createAsyncThunk('storage/removeFiles', async (id) => {
    await axios
        .delete(`/storage/Files/Delete/${id}`, { showToast: true })
    return id
})

export const downloadFile = createAsyncThunk('storage/downloadFiles', async (params) => {
    await axios.get(`/storage/Files/Download/${params.id}/download`, {
        responseType: 'blob'
    })
        .then((response) => {
            const url = URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', params.fileName
            )
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        })
    return id
})

export const appPersonalStorageSlice = createSlice({
    name: 'personalStorage',
    initialState: {
        personalBaseFolderData: [],
        personalFolderData: [],
        filteredFolderData: [],
        personalChildFolderData: [],
        folderTreeData: {},
        selectedFolder: {},
        parentFolder: {},
        folderSpec: {
            folderTitle: "",
            folderDescription: "",
            parentId: null,
            isHidden: null,
            entityId: null,
            folderEntryId: null,
            folderEntryTypes: null,
            isLink: false
        },
        fileData: [],
        filteredFileData: [],
        selectedFile: {},
        selectedFilesData: [], 
        schoolFoldersTotallySize: 0,
        publisherFoldersTotallySize: 0,
        filesTotallySize: 0,
        fileSpec: {
            fileName: "",
            description: "",
            minSize: 0,
            maxSize: null,
            fileExtension: "",
            folderId: null,
            encrypted: null
        },
        action: '',
        breadCrumbsList: []
    },
    reducers: {
        setSelectedPersonalFolder: (state, action) => {
            state.selectedFolder = action.payload
        },
        setParentFolder: (state, action) => {
            state.parentFolder = action.payload
        },
        setAction: (state, action) => {
            state.action = action.payload
        },
        setPersonalFolderSpec: (state, action) => {
            state.folderSpec = action.payload
        },
        setSelectedFile: (state, action) => {
            state.selectedFile = action.payload
        },
        setPersonalFileData: (state, action) => {
            state.fileData = action.payload
        },
        setBreadcrumbList: (state, action) => {
            state.breadCrumbsList = action.payload
        },
        addSelectedFilesData: (state, action) => {
            state.selectedFilesData.push(action.payload)
        },
        removeSelectedFilesData: (state, action) => {
            state.selectedFilesData = state.selectedFilesData.filter(file => file.id !== action.payload)
        },
        setSelectedFilesData: (state, action) => {
            state.selectedFilesData = action.payload
        },
        filterFileData: (state, action) => {
            if (action.payload !== "") {
                state.filteredFileData = state.fileData.filter(item => item.title.toLowerCase().includes(action.payload.toLowerCase()))
            } else {
                state.filteredFileData = state.fileData
            }
        },
        filterFolderData: (state, action) => {
            if (action.payload !== "") {
                state.filteredFolderData = state.personalFolderData.filter(item => item.title.toLowerCase().includes(action.payload.toLowerCase()))
            } else {
                state.filteredFolderData = state.personalFolderData
            }
        },
        addBreadcrumb: (state, action) => {
            state.breadCrumbsList.push(action.payload)
        },
        updateBreadcrumb: (state, action) => {
            const { id, title } = action.payload
            const folderToUpdate = state.breadCrumbsList.find(folder => folder.id === id)
            if (folderToUpdate) {
                folderToUpdate.title = title
            }
        },
        removeBreadcrumb: (state, action) => {
            const idToRemove = action.payload
            state.breadCrumbsList = state.breadCrumbsList.filter(folder => folder.id !== idToRemove)
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getPersonalFolders.fulfilled, (state, action) => {
                state.personalFolderData = action.payload.data
                state.filteredFolderData = action.payload.data
                state.fileData = []
                state.filteredFileData = []
                state.selectedFolder = {}

            })
        builder
            .addCase(getPersonalFolder.fulfilled, (state, action) => {
                state.selectedFolder = action.payload.data
            })
        builder
            .addCase(getPersonalFiles.fulfilled, (state, action) => {
                state.fileData = action.payload.data

            })
        builder
            .addCase(getAllPersonalFolders.fulfilled, (state, action) => {
                    if (action.payload.data.length > 0) {
                        state.personalFolderData = action.payload.data[0].childFolders
                        state.filteredFolderData = action.payload.data[0].childFolders
                        state.fileData =  action.payload.data[0].fileEntries
                        state.filteredFileData = action.payload.data[0].fileEntries
                        state.selectedFolder = action.payload.data[0]
                        if (action.payload.data[0].parents !== null && action.payload.data[0].parents.length > 0) {
                            state.breadCrumbsList = {}
                            state.breadCrumbsList = action.payload.data[0].parents.map((parent) => ({
                                id: parent.id,
                                title: parent.title,
                                folderEntryTypes: action.payload.data[0].folderEntryType
                              }))
                        }
                    }
            })
    }
})
export const {
    setSelectedPersonalFolder,
    setAction,
    setPersonalFolderSpec,
    setSelectedFile,
    setPersonalFileData,
    setParentFolder,
    addSelectedFilesData,
    removeSelectedFilesData,
    addBreadcrumb,
    updateBreadcrumb,
    removeBreadcrumb,
    setBreadcrumbList,
    filterFileData,
    filterFolderData,
    setSelectedFilesData
} = appPersonalStorageSlice.actions
export default appPersonalStorageSlice.reducer