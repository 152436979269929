// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from '@axios'

export const getStatistics = createAsyncThunk("VideoStatistics/GetStatistics", async (videoStatisticsOptions) => {
  const response = await axios.post(`/videolessons/VideoStatistics/GetStatistics`, videoStatisticsOptions.spec)
  return response
})
export const getPagedStatistics = createAsyncThunk("VideoStatistics/GetPagedStatistics", async (videoStatisticsOptions) => {
  const response = await axios.post(`/videolessons/VideoStatistics/GetPagedStatistics`, videoStatisticsOptions)
  return response
})
export const createVideoStatistics = createAsyncThunk("VideoStatistics/Create", async (model) => {
  const response = await axios.post(`/videolessons/VideoStatistics/Create`, model, {showToast : true})
  return response
})
export const updateVideoStatistics = createAsyncThunk("VideoStatistics/Update", async (model) => {
  const response = await axios.put(`/videolessons/VideoStatistics/Update`, model, {showToast : true})
  return response
})
export const deleteVideoStatistics = createAsyncThunk("VideoStatistics/Delete", async (id) => {
  await axios.delete(`/videolessons/VideoStatistics/Delete/${id}`, {showToast : true})
  return id
})

export const appVideoStatisticsSlice = createSlice({
  name: "videoStatistics",
  initialState: {
    videoStatistics: [],
    selectedVideoStatistics : {},
    videoStatisticsOptions: {
      pagingOptions: {
        sortClause: "desc",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        lowViewDuration: null,
        highViewDuration: null,
        lowViewCount: null,
        highViewCount: null,
        isCompleted: null,
        sectionVideoId: null
      }
    }
  },
  reducers: {
    setSelectedVideoStatistics: (state, action) => {
      state.selectedVideoStatistics = action.payload
    },
    setVideoStatisticsOptions: (state, action) => {
      state.videoStatisticsOptions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getPagedStatistics.fulfilled, (state, action) => {
      state.videoStatistics = action.payload.data
    })  
  }
})
export const { 
  setSelectedVideoStatistics,
  setVideoStatisticsOptions
 } = appVideoStatisticsSlice.actions

export default appVideoStatisticsSlice.reducer
