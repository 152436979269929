
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from '@axios'
//import { pagingOptions } from "../../../../configs/appConstants"
export const getUsers = createAsyncThunk("users/getUsers", async (params) => {
    const response = await axios.post(`/identity/Users/GetPagedUsers`, params, {showLoader: true})
    return response
  })
  export const getBooks = createAsyncThunk("bookManager/getBooks", async (params) => {
    
    const response = await axios.post(`/bookManager/books/getpagedbooks`, params, {showLoader: true})
    return response
  })
  export const getUserBookRelations = createAsyncThunk("bookManager/getPagedUserBookRelations", async (params) => {
    const response = await axios.post(`/bookManager/userBookRelations/GetPagedUserBookRelations`, params, {showLoader: true})
    return response
  })
  export const getBookUnits = createAsyncThunk("bookManager/getBookUnits", async (bookId) => {
    const response = await axios.get(`/bookmanager/Books/GetBookContentsTree?bookId=${bookId} &includeQuestions=${true}`, {showLoader: true})
    return response
  })
  export const saveBookContents = createAsyncThunk("UserRelations/Post", async (model) => {
    const response = await axios.post(`/bookmanager/UserBookRelations/Post`, model, {showLoader: true})
    
    return response
  })

  export const userSlice = createSlice({
    name: "users",
    initialState: {
      users: [],
      selectedUser :{},
      books: [],
      bookUnits :[],
      userBookRelations:[],
      selectedBooks: [],
      saveBookContents:[],
      userOptions: {
        pagingOptions : {
          sortClause: "desc",
          orderBy: "Id",
          pageIndex: 0,
          pageSize: 5
        },
        spec: {
         permissions:[
            {
                feature:"solutionmanager",
                action:"create"
            }
         ]
        }
      },
      bookOptions: {
        pagingOptions : {
          sortClause: "desc",
          orderBy: "Id",
          pageIndex: 0,
          pageSize: 5
        },
        spec: {
          title: "",
          isbn: "",
          classIds: [],
          branchIds: []
        }
      },
      userBookRelationOptions: {
        pagingOptions : {
          sortClause: "desc",
          orderBy: "Id",
          pageIndex: 0,
          pageSize: 5
        },
        spec: {
          bookName: "",
          bookId: null,
          userId: null
        }
      }
    },
    reducers: {
      setSelectedUser: (state, action) => {
        state.selectedUser = action.payload
      },
      setSelectedBooks: (state, action) => {      
        let sBooks = [...state.selectedBooks]
        const previousSelectedContents = [... action.payload.previousSelectedContents],
        checkedKeys = action.payload.checkedKeys
        if (action.payload.checked) sBooks.push({...action.payload.book, halfCheckedNodes:[], selectedContents : previousSelectedContents, previousSelectedContents, checkedKeys})
        else sBooks = sBooks.filter((item) => item.id !== action.payload.book.id)
        state.selectedBooks = sBooks
      },
      setSelectedBookContents: (state, action) => {      
        const sBooks = [...state.selectedBooks],
        sBook = action.payload.selectedBook      
        const newBook = {...sBook, selectedContents:action.payload.selectedContents, checkedKeys:action.payload.checkedKeys, halfCheckedNodes:action.payload.halfCheckedNodes}
        const updatedBooks = sBooks.map((book) => {
          if (book.id === sBook.id) return newBook
          return book
        })
        state.selectedBooks = updatedBooks
      },
      setSaveBookContents:(state, action) => {
       state.saveBookContents = action.payload
      },
      setDeleteBookContents: (state, action) => {
        state.selectedBooks = action.payload
      },
      setUserOptions: (state, action) => {
        state.userOptions = action.payload
      },
      setBookOptions: (state, action) => {
        
        state.bookOptions = action.payload
      },
      setUserBookRelationOptions :(state, action) => {
        state.userBookRelationOptions = action.payload
      },
      // eslint-disable-next-line no-unused-vars
      setClearStore: (state, action) => {
        state.selectedUser = {}
        state.selectedBooks = []
        state.userOptions = {
          pagingOptions : {
            sortClause: "desc",
            orderBy: "Id",
            pageIndex: 0,
            pageSize: 5
          },
          spec: {
           permissions:[
              {
                  feature:"solutionmanager",
                  action:"create"
              }
           ]
          }
        }
        state.bookOptions = {
          pagingOptions : {
            sortClause: "desc",
            orderBy: "Id",
            pageIndex: 0,
            pageSize: 5
          },
          spec: {
            title: "",
            isbn: "",
            classIds: [],
            branchIds: []
          }
        }
        state.books = []
        state.bookUnits = []
      }
    },
    extraReducers: (builder) => {
      builder.addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload.data
      })
      builder.addCase(getBooks.fulfilled, (state, action) => {
        state.books = action.payload.data
      })
      builder.addCase(getBookUnits.fulfilled, (state, action) => {
        state.bookUnits = action.payload.data.children
      })
      builder.addCase(getUserBookRelations.fulfilled, (state, action) => {
        state.userBookRelations = action.payload.data
      })

    }
  })
  export const { 
    setSelectedUser,
    setUserOptions,
    setBookOptions,
    setUserBookRelationOptions,
    setSelectedBooks,
    setSelectedBookContents,
    setDeleteBookContents,
    setSaveBookContents,
    setClearStore
   } = userSlice.actions
  
  export default userSlice.reducer
  