import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from '@axios'
import moment from 'moment'
import { autoDownloader } from "@utils"

export const getCodes = createAsyncThunk("activationCode/getPagedCodes", async (options) => {
    const response = await axios.post(`/activationCode/activationCodes/getPagedCodes`, options)
     
    return response
})

export const getCodeTypes = createAsyncThunk("activationCode/getCodeTypes", async () => {
    const response = await axios.get(`/activationCode/activationCodes/getActivationCodeTypes`)
    return response
})

export const addCodes = createAsyncThunk("activationCode/addCodes", async (model) => {
     
    const response = await axios.post(`/activationCode/activationCodes/createCodes`, model, {
        responseType: 'blob',
        showToast: true
    })
        .then((response) => autoDownloader(response, `Aktivasyon_Kodlari_${moment(Date.now()).format('DD.MM.YYYY')}.xlsx`))
    return response
})

export const updateCode = createAsyncThunk("activationCode/codePut", async (model) => {
    const response = await axios.put(`/activationCode/activationCodes/codePut`, model)
    return response
})

export const deleteCode = createAsyncThunk("activationCode/deleteCode", async (id) => {
    const response = await axios.delete(`/activationCode/activationCodes/codeDelete/${id}`)
    return response
})

export const updateBatch = createAsyncThunk("activationCode/updateBatch", async (model) => {

    const response = await axios.post(`/activationCode/activationCodes/updateBatch`, model.batchCodes)
    return response
})

export const deleteBatch = createAsyncThunk("activationCode/batchDelete", async (model) => {
     
    const response = await axios.post(`/activationCode/activationCodes/batchDelete`, model)
    return response
})

export const exportExcel = createAsyncThunk("activationCode/exportAsExcel", async (options) => {
    const response = await axios.post(`/activationCode/activationCodes/exportAsExcel`, options, {
        responseType: 'blob'
    })
        .then((response) => autoDownloader(response, `Tum_Aktivasyon_Kodlari_${moment(Date.now()).format('DD.MM.YYYY')}.xlsx`))
    return response
})

export const exportTemplate = createAsyncThunk("activationCode/exportTemplate", async () => {

    const response = await axios.get(`/activationCode/activationCodes/exportTemplateExcel`, {
        responseType: 'blob'
    })
        .then((response) => autoDownloader(response, "Ornek_Aktivasyon_Kod_Şablonu.xlsx"))
    return response
})

export const importExcel = createAsyncThunk("activationCode/importExcel", async (model) => {
    const response = await axios.post(`/activationCode/activationCodes/importExcel`, model)
    return response
})

export const updateExistingCodes = createAsyncThunk('activationCode/updateExistingCodes', async (model) => {
    const response = await axios.post(`/activationCode/activationCodes/updateExistingCodes`, model)
    return response
})

export const getSelectableBooks = createAsyncThunk('bookManager/getSelectableBooks', async () => {
    const response = await axios.get(`/bookManager/books/getSelectableBooks`)
    return response
})

export const getSelectableLessons = createAsyncThunk('lesson/getSelectableLessons', async () => {
    const response = await axios.get(`$/common/branch/GetSelectableBranches`)
    return response
})

export const getSelectableVideoLessons = createAsyncThunk('videoLesson/getSelectableVideoLessons', async () => {
    const response = await axios.get(`/videoLesson/videoLessons/getSelectableVideoLessons`)
    return response
})

export const appActivationCodesSlice = createSlice({
    name: 'storage',
    initialState: {
        selectedCode: {},
        pagedCodes: [],
        options: {
            pagingOptions: {
                sortClause: "desc",
                orderBy: "Id",
                pageIndex: 0,
                pageSize: 10
            },
            spec: {
                code: "",
                isInUse: undefined,
                activationCodeType: undefined,
                entityId: undefined,
                serialStart: null,
                serialEnd: null
            }
        }
    },
    reducers: {
        setSelectedCode: (state, action) => {
            state.selectedCode = action.payload
        },
        setOptions: (state, action) => {
            state.options = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCodes.fulfilled, (state, action) => {
                state.pagedCodes = action.payload.data
            })
    }
})

export const { setOptions, setSelectedCode } = appActivationCodesSlice.actions
export default appActivationCodesSlice.reducer
