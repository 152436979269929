import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from '@axios'

export const getBookUnits = createAsyncThunk(
  'solutionManager/getBookUnits',
  async (params) => {

    const { bookId } = params
    const response = await axios.get(
      `/solutionManager/solutionBook/GetBookContentsTree?bookId=${bookId} &includeQuestions=${false}
      &includeSubjects=${false}&isUnSolved=${false}&isPublished=${true}`, {showLoader:true}
    )
    return response
  }
)
export const getBookUnitContents = createAsyncThunk(
  'solutionManager/GetQuizWithCourseQuestions',
  async (params) => {
   
    const response = await axios.post(`/solutionManager/solutionBook/GetQuizWithCourseQuestions`, params, {showLoader:true})
    return response
  }
)
export const getBook = createAsyncThunk("solutionManager/getBook", async (bookId) => {
  const response = await axios.get(`/solutionManager/solutionBook/getBook/${bookId}`, {showLoader: true})
  return response
})
export const solutionMonitoringScreenSlice = createSlice({
  name: "solutionMonitoringScreen",
  initialState: {
    bookUnits: {},
    selectedBook: {},
    bookUnitContents:[],
    selectedTest: {},
    selectedQuestion:{}
  },
  reducers: {
    setSelectedTest: (state, action) => {
      state.selectedTest = action.payload
    },
    setSelectedQuestion: (state, action) => {
      state.selectedQuestion = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getBookUnits.fulfilled, (state, action) => {    
      const data = action.payload.data
      state.bookUnits = data
      state.selectedTest = {}
    })
    builder.addCase(getBookUnitContents.fulfilled, (state, action) => {  
      const data = action.payload.data    
      state.bookUnitContents = data
    })
    builder.addCase(getBook.fulfilled, (state, action) => {  
      state.selectedBook = action.payload.data    
    })
  }
})
export const {
  setSelectedTest,
  setSelectedQuestion
} = solutionMonitoringScreenSlice.actions

export default solutionMonitoringScreenSlice.reducer