import axios from "@axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

export const getPagedQuestionPools = createAsyncThunk(
  "questionPool/getPagedQuestionPools",
  async (options) => {
    const response = await axios.post(
      `/questionPools/QuestionPool/GetPagedQuestionPools`,
      options
    )
    return response
  }
)

export const getQuestionPool = createAsyncThunk(
  "questionPool/getQuestionPool",
  async (id) => {
    const result = await axios.get(
      `/questionPools/questionPool/getQuestionPool/${id}`, {showLoader: true}
    )
    return result
  }
)
export const getQpQuestions = createAsyncThunk(
  "questionPool/getPagedQuestions",
  async (options) => {
    const response = await axios.post(
      `/questionPools/questionPoolQuestions/getPagedQuestions`,
      options
    )
    return response
  }
)

export const appQuestionPoolSlice = createSlice({
  name: "questionPoolStorage",
  initialState: {
    questionPools: [],
    selectedQuestionPool: {},
    options: {
      spec: {
        title: ""
      }
    },
    questions: {},
    selectedQuestion: {},
    questionOptions: {
      pagingOptions: {
        sortClause: 'desc',
        orderBy: 'id',
        pageIndex: 0,
        pageSize: 12
      },
      spec: {
        achievementIds: [],
        questionPoolId: 0,
        difficultyLevel: 0
      }
    }
  },
  reducers: {
    setSelectedQuestionPool: (state, action) => {
      state.selectedQuestionPool = action.payload
    },
    setOptions: (state, action) => {
      state.options = action.payload
    },
    setQuestionOptions: (state, action) => {
      state.questionOptions = action.payload
    },
    setSelectedQuestion: (state, action) => {
      state.selectedQuestion = action.payload
    },
    updateSelectedQuestion: (state, action) => {
      const questionPool = state.selectedQuestionPool

      let selectedQuestion = state.selectedQuestion
      if (questionPool.questions) {
        const index = questionPool.questions.findIndex(
          (x) => x.id === selectedQuestion.id
        )
        selectedQuestion = action.payload
        questionPool.questions[index] = selectedQuestion
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPagedQuestionPools.fulfilled, (state, action) => {
        state.questionPools = action.payload.data
      })
      .addCase(getQuestionPool.fulfilled, (state, action) => {
        state.selectedQuestionPool = action.payload.data
        // * sets selected question
        const existingSelectedQuestion = JSON.parse(JSON.stringify(state.selectedQuestion))
        const index = action.payload.data.questions.map(x => x.id).indexOf(existingSelectedQuestion.id)
        if (index === -1) {
          state.selectedQuestion = action.payload.data.questions[0]
        } else {
          state.selectedQuestion = action.payload.data.questions[index]
        }
      })
      .addCase(getQpQuestions.fulfilled, (state, action) => {
        state.questions = action.payload.data
      })
  }
})

export const { 
  setOptions, 
  setQuestionOptions, 
  setFileOptions, 
  setSelectedQuestionPool, 
  setSelectedQuestionPoolFile, 
  setSelectedQuestion, 
  updateSelectedQuestion 
} = appQuestionPoolSlice.actions
export default appQuestionPoolSlice.reducer
