import axios from "@axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { autoDownloader } from "@utils"

export const getPagedPersonalBooks = createAsyncThunk('personalBook/getPagedPersonalBooks', async (options) => {
  const response = await axios.post(`/questionPools/PersonalBook/GetPagedPersonalBooks`, options, {showLoader: true})
  return response
})

export const printPdf = (id, title) => {
  axios.get(`/questionPools/PersonalBook/printPdf/${id}`, { responseType: 'blob' }).then((response) => {
    autoDownloader(response, `${title}.pdf`)
  })
}

export const personalBookSlice = createSlice({
  name: 'personalBookStore',
  initialState: {
    personalBooks: [],
    personalBooksOptions: {
      pagingOptions: {
        sortClause: "Id",
        orderBy: "desc",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        title: ""
      }      
    }
  },
  reducers: {
    setPersonalBooksOptions: (state, action) => {
      state.personalBooksOptions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPagedPersonalBooks.fulfilled, (state, action) => {
      state.personalBooks = action.payload.data
    })

  }
})

export const { setPersonalBooksOptions} = personalBookSlice.actions

export default personalBookSlice.reducer