// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "@axios"
export const getPublicMenus = createAsyncThunk(
  "publiclayout/getPublicMenus",
  async () => {
    const response = await axios.post("/common/systemMenuPublic/getAppMenu", {
      DisplayPlace: 50
    })
    return response
  }
)
export const layoutSlice = createSlice({
  name: "publiclayout",
  initialState: {
    menuItems: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPublicMenus.fulfilled, (state, action) => {
      state.menuItems = action.payload.data
    })
  }
})

export const {} = layoutSlice.actions

export default layoutSlice.reducer
