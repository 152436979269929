// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Axios Imports
import axios from "@axios"


// * Thunks for course
export const getCourse = createAsyncThunk(
  "quizmanager/GetCourse",
  async (id) => {
    const response = await axios.get(
      `/quizmanager/Course/GetCourse?id=${id}`
    )
    return response
  }
)

export const getCourseContent = createAsyncThunk(
  "quizmanager/GetCourseContents",
  async (id) => {
    const response = await axios.get(
      `/quizmanager/Course/GetCourseContents?id=${id}`
    )
    return response
  }
)

// * Thunks for Quiz
export const getQuiz = createAsyncThunk("quizmanager/GetQuiz", async (id) => {
  const response = await axios.get(
    `/quizmanager/Quiz/GetQuiz?id=${id}`
  )
  return response
})

// * Thunks for questionSolution ********
export const getQuestionSolution = createAsyncThunk(
  "quizmanager/GetQuestionSolution",
  async (solutionId) => {
    const response = await axios.get(
      `/quizmanager/QuestionSolution/GetQuestionSolution?id=${solutionId}`
    )
    return response
  }
)

export const getQuestionPool = createAsyncThunk(
  "questionPool/getQuestionPool",
  async (id) => {
    const response = await axios.get(
      `/questionPools/QuestionPool/GetQuestionPool/${id}`
    )
    return response
  }
)

const courseStoreSlice = createSlice({
  name: "courseManager",
  initialState: {
    selectedCourse: {},
    selectedCourseContent: {},
    selectedQuiz: {},
    selectedQuestion: {},
    quizRootIds: [],
    courseRootIds: [],
    draggableItems: []
  },
  reducers: {
    setSelectedContent: (state, action) => {
      state.selectedContent = action.payload
    },
    updateSelectedContent: (state, action) => {
      const course = state.selectedCourse
      const selectedContent = state.selectedContent
      if (course.courseContents) {
        const index = course.courseContents.findIndex(
          (x) => x.id === selectedContent.id
        )
        if (selectedContent.subject) {
          selectedContent.subject = action.payload
        }
        if (selectedContent.question) {
          selectedContent.question = action.payload
        }
        course.courseContents[index] = selectedContent
      }
    },
    setSelectedQuestion: (state, action) => {
      state.selectedQuestion = action.payload
    },
    updateSelectedQuestion: (state, action) => {
      const quiz = state.selectedQuiz
      const selectedQuestion = action.payload
      if (quiz.questions) {
        const index = quiz.questions.findIndex(
          (x) => x.id === selectedQuestion.id
        )
        quiz.questions[index] = selectedQuestion
      }
    },
    setDraggableItems: (state, action) => {
      state.draggableItems = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCourse.fulfilled, (state, action) => {
      state.selectedCourse = action.payload.data
      if (action.payload.data.courseContents.length > 0) {
        state.selectedContent = action.payload.data.courseContents[0]
      }
    })
    builder.addCase(getQuiz.fulfilled, (state, action) => {
      state.selectedQuiz = action.payload.data
      state.selectedQuestion = action.payload.data.questions[0]
    })
    builder.addCase(getQuestionPool.fulfilled, (state, action) => {
      state.content = { ...action.payload.data, startPage: 1 }
      state.selectedContent = action.payload.data.questions[0]
    })
    builder.addCase(getCourseContent.fulfilled, (state, action) => {
      state.courseContent = action.payload.data
    })
  }
})
export const {
  setSelectedContent,
  updateSelectedContent,
  setSelectedQuestion,
  updateSelectedQuestion,
  setDraggableItems
} = courseStoreSlice.actions
export default courseStoreSlice.reducer
