import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@axios'
import { pagingOptions } from '../../../../configs/appConstants'
export const getCurrentUserAssignedTasks = createAsyncThunk(
  'bookManager/GetPagedCurrentUserAssignedTasks',
  async (params) => {
    const response = await axios.post(
      `/bookManager/userBookRelations/GetPagedCurrentUserAssignedTasks`,
      params,
      {showLoader: true}
    )
    return response
  }
)
export const getBookUnits = createAsyncThunk(
  'bookManager/getBookUnits',
  async (bookId) => {
    const response = await axios.get(
      `/bookmanager/Books/GetBookContentsTree?bookId=${bookId} &includeQuestions=${true}`,
      {showLoader: true}
    )
    return response
  }
)
export const getSelectedBookUnits = createAsyncThunk(
  'bookManager/getSelectedBookUnits',
  async (params) => {
    const response = await axios.post(
      `/bookmanager/userBookRelations/GetSelectedUnitContents`,
      params,
      {showLoader: true}
    )
    return response
  }
)

export const getUserBookRelation = createAsyncThunk(
  'bookManager/GetUserBookRelation',
  async (params) => {
    const response = await axios.post(
      `/bookManager/userBookRelations/GetUserBookRelation`,
      params,
      {showLoader: true}
    )
    return response
  }
)
export const getQuestionSolutions = createAsyncThunk(
  'QuestionSolution/GetQuestionSolutions',
  async (questionId) => {
    const response = await axios.get(
      `/quizmanager/QuestionSolution/GetQuestionSolutions?questionId=${questionId}`,
      {showLoader: true}
    )
    return response
  }
)
export const getQuestionComments = createAsyncThunk(
  'quizmanager/getPagedQuestionComments',
  async (params) => {
    
    const response = await axios.post(
      `/quizmanager/QuestionComment/GetPagedQuestionComments`,
      params,
      {showLoader: true}
    )
    return response
  }
)
export const getSolutionComments = createAsyncThunk(
  'solutionManager/getPagedSolutionComments',
  async (params) => {
    
    const response = await axios.post(
      `/solutionmanager/SolutionComment/GetPagedSolutionComments`,
      params,
      {showLoader: true}
    )
    return response
  }
)
export const saveSolutionComment = createAsyncThunk(
  'solutionManager/PostSolutionComments',
  async (params) => {
    
    const response = await axios.post(
      `/solutionmanager/SolutionComment/Post`,
      params,
      {showLoader: true}
    )
    return response
  }
)
export const updateSolutionComment = createAsyncThunk(
  'solutionManager/PutSolutionComments',
  async (params) => {
    
    const response = await axios.put(
      `/solutionmanager/SolutionComment/Put`,
      params,
      {showLoader: true}
    )
    return response
  }
)
export const currentUserTasksSlice = createSlice({
  name: 'currentUserTasks',
  initialState: {
    tasks: [],
    bookUnits: [],
    selectedBookUnits: [],
    selectedUser: {},
    selectedBook: {},
    currentQuestion: {},
    questionSolutions:[],
    selectedSolution:{},
    videoBytes:[],
    IsNewRecord:false,
    unitFlatList: [],
    questionComments:[],
    solutionComments:[],
    taskOptions: {
      pagingOptions,
      spec: {
        bookName: '',
        bookId: null,
        userId: null
      }
    },
    questionCommentOptions : {
      pagingOptions : {
        sortClause: "questionCommentStatus asc, updatedOn desc, Id",
        orderBy: "desc",
        pageIndex: 0,
        pageSize: 10
      },
       spec:{
        id:null,
        userId:null,
        questionId:null,
        commentType:null,
        commentStatus:null
       }
    },
    solutionCommentOptions: {
      pagingOptions : {
        sortClause: "desc",
        orderBy: "Id",
        pageIndex: 0,
        pageSize: 5
      },
      spec: {
        Id: null,
        commentType: 20, // hata raporu,
        commentStatus:null,
        lastEditorUserId:null,
        solutionId:null
      }
    },
    unitOptions:{
      bookId:null,
      userId:null,
      isUnSolved:false
    },
    notificationCounts:0
  },
  reducers: {
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload
    },
    setSelectedBook: (state, action) => {
      state.selectedBook = action.payload
    },
    setTaskOptions: (state, action) => {
      state.taskOptions = action.payload
    },
    setCurrentQuestion: (state, action) => {
      state.currentQuestion = action.payload
      state.IsNewRecord = false
    },
    nextQuestion: (state) => {
      
      if (Object.keys(state.currentQuestion).length <= 0) return
      const currentQuestionParentList = [...state.unitFlatList].filter(x => x.parentId === state.currentQuestion.parentId && x.type === "question")
      const findCurrentIndex = [...currentQuestionParentList].findIndex(x => x.id === state.currentQuestion.id)
      const nextIndex = findCurrentIndex + 1
       state.IsNewRecord = false
       state.currentQuestion = currentQuestionParentList.length <= 0 ? state.currentQuestion : currentQuestionParentList[nextIndex > (currentQuestionParentList.length - 1) ? 0 : nextIndex]
    },
    prevQuestion: (state) => {
      if (Object.keys(state.currentQuestion).length <= 0) return
      const currentQuestionParentList = [...state.unitFlatList].filter(x => x.parentId === state.currentQuestion.parentId && x.type === "question")
      const findCurrentIndex = [...currentQuestionParentList].findIndex(x => x.id === state.currentQuestion.id)
      const prevIndex = findCurrentIndex - 1
      state.IsNewRecord = false
       state.currentQuestion = currentQuestionParentList.length <= 0 ? state.currentQuestion :  currentQuestionParentList[prevIndex < 0 ? (currentQuestionParentList.length - 1) : prevIndex]
    },
    setUnitFlatList: (state, action) => {
      state.unitFlatList = action.payload
    },
    setClearItems :(state) => {
      state.currentQuestion = {}
      state.unitFlatList = []
      state.selectedBookUnits = []
      state.selectedBook = {}

    },
    setVideoBytes : (state, action) => {
      state.videoBytes = action.payload
    },
    setIsNewRecord : (state, action) => {
      state.IsNewRecord = action.payload
    },
    setSelectedSolution: (state, action) => {
      state.selectedSolution = action.payload
    },
    setQuestionCommentOptions :(state, action) => {   
      state.questionCommentOptions = action.payload
    },
    setSolutionCommentOptions: (state, action) => {    
      state.solutionCommentOptions = action.payload
    },
    setNotificationCounts : (state, action) => {
      state.notificationCounts = action.payload
    },
    setUnitOptions : (state, action) => {
      state.unitOptions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentUserAssignedTasks.fulfilled, (state, action) => {
      state.tasks = action.payload.data
    })
    builder.addCase(getBookUnits.fulfilled, (state, action) => {
      state.bookUnits = action.payload.data.children
    })
    builder.addCase(getUserBookRelation.fulfilled, (state, action) => {
      state.selectedBook = action.payload.data
    })
    builder.addCase(getSelectedBookUnits.fulfilled, (state, action) => {    
      
      const data = action.payload.data.children
      state.selectedBookUnits = data
      const currentQuestion = data[0]?.children[0]?.children[0]
      if (Object.keys(state.currentQuestion).length === 0) state.currentQuestion =  currentQuestion ?? {}
      const getFlattenTree = (treeList, flattened = []) => {
        treeList.forEach((node) => {
          flattened.push(node)
          if (node.children) {
            getFlattenTree(node.children, flattened)
          }
        })
        return flattened
      }
     state.unitFlatList = getFlattenTree(data)
    })
    builder.addCase(getQuestionSolutions.fulfilled, (state, action) => {
      state.questionSolutions = action.payload.data
      if (action.payload.data.length <= 0) state.IsNewRecord = true
    })
    builder.addCase(getQuestionComments.fulfilled, (state, action) => {
      state.questionComments = action.payload.data
    })
    builder.addCase(getSolutionComments.fulfilled, (state, action) => {
      state.solutionComments = action.payload.data
    })
  }
})
export const {
  setSelectedUser,
  setSelectedBook,
  setTaskOptions,
  prevQuestion,
  nextQuestion,
  setCurrentQuestion,
  setUnitFlatList,
  setClearItems,
  setVideoBytes,
  setIsNewRecord,
  setSelectedSolution,
  setQuestionCommentOptions,
  setSolutionCommentOptions,
  setNotificationCounts,
  setUnitOptions
} = currentUserTasksSlice.actions

export default currentUserTasksSlice.reducer
