// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** axios Imports
import axios from '@axios'

export const getPagedAssignmentUser = createAsyncThunk('AssignmentUser/GetPaged', async (assignmentUserOptions) => {
  const response = await axios.post('/assignments/AssignmentUser/GetPaged', assignmentUserOptions)
  return response
})

const appAssignmentUserSlice = createSlice({
  name: 'assignmentUser',
  initialState: {
    assignmentUsers: [],
    selectedAssignmentUser: {},
    assignmentUserOptions : {
      pagingOptions: {
        sortClause: "desc",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        assignmentId: null,
        lowCompleteDate: null,
        highCompleteDate: null
      }
    }
  }, 
  reducers: {
    setAssignmentUserOptions: (state, action) => {
      state.assignmentUserOptions = action.payload
    },
    setSelectedAssignmentUser: (state, action) => {
      state.selectedAssignmentUser = action.payload
    }
  },
  extraReducers: builder => {
    builder
    .addCase(getPagedAssignmentUser.fulfilled, (state, action) => {
      state.assignmentUsers = action.payload.data
    })
  }
})
export const { setAssignmentUserOptions, setSelectedAssignmentUser } = appAssignmentUserSlice.actions
export default appAssignmentUserSlice.reducer