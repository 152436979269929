
// Common
import classStore from '@obroadmin/views/Common/Classes/store'
import branchStore from '@obroadmin/views/Common/Branches/store'
import educatationYearStore from '@obroadmin/views/Common/EducationYear/store'
import achievementStore from '@obroadmin/views/Common/Achievements/store'
import provinceStore from '@obroadmin/views/Common/Provinces/store'
import schoolManageStore from '@obroadmin/views/Common/Schools/store'
import typeOfSchoolStore from '@obroadmin/views/Common/TypeOfSchool/store'
import sectionManager from '@obroadmin/views/Common/Sections/store'
import questionDistribution from '@obroadmin/views/Common/QuestionDistributions/store'
import systemMenuStore from '@obroadmin/views/Common/SystemMenu/store'
import scoreTypeStore from '@obroadmin/views/Common/ScoreTypes/store'
import examCategory from '@obroadmin/views/Common/ExamCategoryManager/store'
import publisherManager from '@obroadmin/views/Common/Publishers/store'
import lessonManager from '@obroadmin/views/Common/Lessons/store'
import subjectStore from '@obroadmin/views/Common/Lessons/Subject/store'
//
import bookDetailStore from '@obroadmin/views/BookManager/BookDetail/BookTree/store'
import bookManager from '@obroadmin/views/BookManager/store'
import bookPageStore from '@obroadmin/views/BookManager/BookPages/store'
import bookFileManager from '@obroadmin/views/BookManager/BookDetail/BookFile/store'
import bookQrCodes from '@obroadmin/views/BookManager/BookDetail/BookQRCode/store'
import contentManager from '@obroadmin/views/ContentManager/store'

import organisationStore from '@obroadmin/views/Organisation/store'
import schoolDetailStore from '@obroadmin/views/Organisation/SchoolDetail/store'
import announcementStore from '@obroadmin/views/Organisation/AnnouncementManager/store'
import schoolBooksStore from '@obroadmin/views/Organisation/SchoolBookExamManager/SchoolBooks/store'
import schoolExamStore from '@obroadmin/views/Organisation/SchoolBookExamManager/SchoolExam/store'
import classRoomManagerStore from '@obroadmin/views/Organisation/ClassRoomsManager/store'

import editorStore from '@obroadmin/views/EditorManager/store'
import storageStore from '@obroadmin/views/Storage/store'

import qrCodes from '@obroadmin/views/QRCodeManager/store'
import activationCode from '@obroadmin/views/ActivationCode/store'

import userManagerStore from '@obroadmin/views/IdentityManager/Users/store'
import roleManagerStore from '@obroadmin/views/IdentityManager/Roles/store'

import onlineExamManager from '@obroadmin/views/OnlineExam/store'
import onlineExamFile from '@obroadmin/views/OnlineExam/ExamDetail/ExamFile/store'

import videoLesson from '@obroadmin/views/VideoLessons/VideoLesson/store'
import videoLessonSection from '@obroadmin/views/VideoLessons/Section/VideoLessonSection/store'
import sectionVideo from '@obroadmin/views/VideoLessons/Section/SectionVideo/store'
import videoInstructor from '@obroadmin/views/VideoLessons/VideoInstructor/store'
import videoItem from '@obroadmin/views/VideoLessons/VideoItems/store'
import videoErrorReport from '@obroadmin/views/VideoLessons/VideoErrorReport/store'
import videoLessonComment from '@obroadmin/views/VideoLessons/VideoLessonComment/store'
import videoStatistics from '@obroadmin/views/VideoLessons/VideoStatistics/store'

//Configuration Manager
import configurationStore from '@obroadmin/views/ConfigurationsManager/store'

import assignmentManager from '@obroadmin/views/AssignmentManager/Assignment/store'
import assignmentUser from '@obroadmin/views/AssignmentManager/AssignmentUser/store'
import assignmentContent from '@obroadmin/views/AssignmentManager/AssignmentContent/store'
import assignmentContentFile from '@obroadmin/views/AssignmentManager/AssignmentContentFile/store'
//Quiz Manamger
import courseStore from '@obroadmin/views/QuizManager/store'
import questionPoolStore from '@obroadmin/views/QuestionPool/store'
import questionPoolFileStore from '@obroadmin/views/QuestionPool/QuestionPoolDetail/QuestionPoolFiles/store'
import { combineReducers } from 'redux'

const adminReducer = combineReducers({
  classStore,
  branchStore,
  educatationYearStore,
  achievementStore,
  systemMenuStore,
  scoreTypeStore,
  bookManager,
  bookDetailStore,
  videoLesson,
  bookPageStore,
  schoolManageStore,
  typeOfSchoolStore,
  organisationStore,
  schoolDetailStore,
  classRoomManagerStore,
  provinceStore,
  storageStore,
  editorStore,
  bookFileManager,
  examCategory,
  activationCode,
  qrCodes,
  userManagerStore,
  roleManagerStore,
  onlineExamManager,
  onlineExamFile,
  bookQrCodes,
  publisherManager,
  contentManager,
  videoLessonSection,
  sectionVideo,
  videoInstructor,
  videoItem,
  videoErrorReport,
  videoLessonComment,
  videoStatistics,
  sectionManager,
  lessonManager,
  subjectStore,
  questionDistribution,
  questionPoolStore,
  questionPoolFileStore,
  configurationStore,
  assignmentManager,
  assignmentUser,
  assignmentContent,
  assignmentContentFile,
  schoolBooksStore,
  schoolExamStore,
  courseStore,
  announcementStore
})

export default adminReducer
