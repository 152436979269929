import solutionManagerStore from '@obrosolutionapp/views/Users/store/index.js'
import solutionManagerTasksStore from '@obrosolutionapp/views/AssignedMyTasks/store/index.js'
import solutionManagerBookStore from '@obrosolutionapp/views/Books/store/index.js'
import solutionManagerNotificationStore from '@obrosolutionapp/views/Notifications/store/index.js'
import solutionManagerSolutionReportStore from '@obrosolutionapp/views/SolutionReports/store/index.js'
import solutionManagerSolutionNotificationStore from '@obrosolutionapp/views/SolutionNotifications/store/index.js'
import { combineReducers } from "redux"
const SolutionReducer = combineReducers({
  solutionManagerStore,
  solutionManagerTasksStore,
  solutionManagerBookStore,
  solutionManagerNotificationStore,
  solutionManagerSolutionReportStore,
  solutionManagerSolutionNotificationStore
})

export default SolutionReducer
