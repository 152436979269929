import axios from '@axios'
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

export const getPoolQuestions = createAsyncThunk('poolPreviewSlice/getPoolQuestions', async (poolId) => {
  const response = await axios.get(`/questionPools/questionPoolPortal/getPoolQuestions/${poolId}`)
  return response
})

export const poolPreviewSlice = createSlice({
  name: 'poolPreviewSlice',
  initialState: {
    poolQuiz: {},
    pool: {}
  },
  reducers: {
    setPoolQuiz: (state, action) => {
      state.poolQuiz = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPoolQuestions.fulfilled, (state, action) => {
      state.poolQuiz = action.payload.data.quizModel
      state.pool = action.payload.data
    })
  }
})

export const { setPoolQuiz } = poolPreviewSlice.actions

export default poolPreviewSlice.reducer
