// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Axios Imports
import axios from "@axios"
// ** immer import
import { setAutoFreeze } from "immer"
setAutoFreeze(false)

// * folder thunks


export const getFolderTree = createAsyncThunk(
  "document/getFolderTree",
  async (treeOptions) => {
    const response = await axios.get(`/storage/Folders/GetFolderTree`, {
      params: {
        filterKey: treeOptions.filterKey,
        parentId: treeOptions.parentId
      }
    })
    return response
  }
)

export const getChildFolders = createAsyncThunk(
  "document/getChildFolders",
  async (parentId) => {
    const response = await axios.get(`/storage/Folders/GetFolderTree`, {
      params: { parentId }
    })
    return response
  }
)

export const createFolder = createAsyncThunk(
  "storage/createFolder",
  async (model) => {
    const response = await axios.post("/storage/Folders/createFolder", model, {
      showToast: true
    })
    return response
  }
)

export const updateFolder = createAsyncThunk(
  "storage/updateFolders",
  async (params) => {
    const response = await axios.put(
      `/storage/Folders/Put/${params.id}`,
      params,
      { showToast: true }
    )
    return response
  }
)

export const getAuditLogsFolder = createAsyncThunk(
  "storage/getAuditLogsFolder",
  async (params) => {
    const response = await axios.get(
      `/storage/Folders/GetAuditLogs/${params.id}/auditlogs`
    )
    return response
  }
)

// * file thunks
export const getFiles = createAsyncThunk("storage/getFiles", async () => {
  const response = await axios.get("/storage/Files/GetAll")
  return response
})

export const getPagedFiles = createAsyncThunk(
  "storage/getPagedFiles",
  async (fileOptions) => {
    const options = {
      pagingOptions: fileOptions.pagingOptions,
      spec: fileOptions.fileEntrySpec
    }
    const response = await axios.post("/storage/Folders/GetFolderContents", options)
    return response
  }
)

export const addFile = createAsyncThunk("storage/addFiles", async (model) => {
  const response = await axios.post("/storage/Files/Upload", model, {
    showToast: true
  })
  return response
})

export const getFile = createAsyncThunk("storage/getFile", async (id) => {
  const response = await axios.get(`/storage/Files/Get/${id}`)
  return response
})

export const downloadFile = createAsyncThunk(
  "storage/downloadFiles",
  async (params) => {
    await axios
      .get(`/storage/Files/Download/${params.id}/download`, {
        responseType: "blob"
      })
      .then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", params.fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    return id
  }
)

export const updateFile = createAsyncThunk(
  "storage/updateFiles",
  async (model) => {
    const response = await axios.put(`/storage/Files/Put/${model.id}`, model, {
      showToast: true
    })
    return response
  }
)

export const removeFile = createAsyncThunk(
  "storage/removeFiles",
  async (id) => {
    await axios.delete(`/storage/Files/Delete/${id}`, { showToast: true })
    return id
  }
)

export const getAuditLogsFile = createAsyncThunk(
  "storage/getAuditLogsFiles",
  async (params) => {
    const response = await axios.get(
      `/storage/Files/GetAuditLogs/${params.id}/auditlogs`
    )
    return response
  }
)

export const appStorageSlice = createSlice({
  name: "storage",
  initialState: {
    folderData: {},
    folderTreeData: {},
    selectedNode: {},
    folderOptions: {
      pagingOptions: {
        sortClause: "decs",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 10
      },
      filterSpec: {
        title: "",
        description: "",
        parentId: null,
        isHidden: null
      }
    },
    treeOptions: {
      filterKey: "",
      parentId: null
    },
    fileData: [],
    selectedFile: {},
    selectedFiles: [],
    fileOptions: {
      pagingOptions: {
        sortClause: "desc",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 10
      },
      fileEntrySpec: {
        title: "",
        description: "",
        minSize: 0,
        maxSize: 3000000,
        fileExtension: "",
        folderId: null,
        encrypted: null
      }
    },
    action: ""
  },
  reducers: {
    setSelectedNode: (state, action) => {
      state.selectedNode = action.payload
    },
    setAction: (state, action) => {
      state.action = action.payload
    },
    setFolderOptions: (state, action) => {
      state.folderOptions = action.payload
    },
    setSelectedFile: (state, action) => {
      state.selectedFile = action.payload
    },
    setSelectedFiles: (state, action) => {
      state.selectedFiles = action.payload
    },
    setFileOptions: (state, action) => {
      state.fileOptions = action.payload
    }

  },
  extraReducers: (builder) => {
    builder.addCase(getFolderTree.fulfilled, (state, action) => {
      state.folderTreeData = action.payload.data
      const updateSelectedNode = (nodes) => {
        for (const node of nodes) {
          if (node.id === parseInt(state.selectedNode.id)) {
            state.selectedNode = node
            return true
          }
          if (
            node.children &&
            updateSelectedNode(node.children)
          ) {
            return true
          }
        }
        return false
      }
      updateSelectedNode(
        action.payload.data
      )

    })
    builder.addCase(getPagedFiles.fulfilled, (state, action) => {
      state.fileData = action.payload.data

    })
    builder
      .addCase(getChildFolders.fulfilled, (state, action) => {

        const findAndInsertChildren = (nodes, parentId, children) => {
          for (const node of nodes) {
            if (node.id === parseInt(parentId)) {
              node.children = children
              if (node.children && node.children.length > 0) {
                node.isLeaf = false
              } else {
                node.isLeaf = true
              }
              return true
            }
            if (
              node.children &&
              findAndInsertChildren(node.children, parentId, children)
            ) {
              return true
            }
          }
          return false
        }
        if (action.meta.arg) {
          findAndInsertChildren(
            state.folderTreeData,
            action.meta.arg,
            action.payload.data
          )
        } else {
          //kök dizin ise sadece olmayanları ekle
          const newItems = action.payload.data.filter(
            newItem => !state.folderTreeData.some(existingItem => existingItem.id === newItem.id)
          )
    
          // folderTreeData'da olup action.payload.data'da olmayan elemanları çıkaralım
          const updatedItems = state.folderTreeData.filter(
            existingItem => action.payload.data.some(newItem => newItem.id === existingItem.id)
          )

          state.folderTreeData = [...updatedItems, ...newItems]
        }
      })
  }
})
export const {
  setSelectedNode,
  setFolderOptions,
  setSelectedFile,
  setSelectedFiles,
  setFileOptions,
  setAction,
  updateTreeNode
} = appStorageSlice.actions
export default appStorageSlice.reducer
