// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from '@axios'


export const getSections = createAsyncThunk("VideoLessonSection/GetSections", async (videoLessonSectionOptions) => {
  const response = await axios.post(`/videolessons/VideoLessonSection/GetSections`, videoLessonSectionOptions.spec)
  return response
})
export const getPagedSections = createAsyncThunk("VideoLessonSection/GetPagedSections", async (videoLessonSectionOptions) => {
  const response = await axios.post(`/videolessons/VideoLessonSection/GetPagedSections`, videoLessonSectionOptions)
  return response
})
export const createVideoLessonSection = createAsyncThunk("VideoLessonSection/Create", async (model) => {
  const response = await axios.post(`/videolessons/VideoLessonSection/Create`, model, {showToast : true})
  return response
})
export const updateVideoLessonSection = createAsyncThunk("VideoLessonSection/Update", async (model) => {
  const response = await axios.put(`/videolessons/VideoLessonSection/Update`, model, {showToast : true})
  return response
})
export const deleteVideoLessonSection = createAsyncThunk("VideoLessonSection/Delete", async (id) => {
  await axios.delete(`/videolessons/VideoLessonSection/Delete/${id}`, {showToast : true})
  return id
})

export const appVideoLessonSectionSlice = createSlice({
  name: "videoLessonSection",
  initialState: {
    videoLessonSection: [],
    selectedVideoLessonSection: {},
    videoLessonSectionOptions: {
      pagingOptions: {
        sortClause: "desc",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        title: "",
        isActive: null,
        videoLessonId: null
      }
    }
  },
  reducers: {
    setSelectedVideoLessonSection: (state, action) => {
      state.selectedVideoLessonSection = action.payload
    },
    setVideoLessonSectionOptions: (state, action) => {
      state.videoLessonSectionOptions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getPagedSections.fulfilled, (state, action) => {
      state.videoLessonSection = action.payload.data
    })
  }
})
export const {
  setSelectedVideoLessonSection, 
  setVideoLessonSectionOptions
 } = appVideoLessonSectionSlice.actions
export default appVideoLessonSectionSlice.reducer