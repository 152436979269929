import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import axios from '@axios'
//classes
export const getBranches = createAsyncThunk("common/getBranches", async () => {
  const response = await axios.get(`/common/branch/get`, {
    params: { getUseableBranchForAchievement: true }
  })
  return response
})
export const getBranchAchievements = createAsyncThunk("common/getBranchAchievements", async (branchId) => {
  const response = await axios.get(`/common/Achievement/getBranchAchievements`, {
    params: { branchId }
  })
  return response
})

export const addAchievement = createAsyncThunk(
  "common/addAchievement",
  async (model) => {
    const response = await axios
      .post(`/common/Achievement/addAchievement`, model, {showToast : true})
      .then((result) => {
        console.log(result.data)
      })
      .catch(() => { })
    return response
  }
)

export const updateAchievement = createAsyncThunk(
  "common/updateAchievement",
  async (model) => {
    const response = await axios
      .put(`/common/Achievement/UpdateAchievement`, model, {showToast : true})
      .then((result) => {
        console.log(result.data)
      })
      .catch(() => { })
    return response
  }
)

export const removeAchievement = createAsyncThunk(
  "common/removeAchievement",
  async (id) => {
    await axios.delete(`/common/Achievement/Delete`, {
      params: { id },
      crossdomain: true,
      showToast: true
    })
    return id
  }
)


export const achievementStoreSlice = createSlice({
  name: "achievementStore",
  initialState: {
    branches: [],
    selectedBranch: {},
    classes: [],
    selectedClass: {},
    achievements: [],
    selectedAchievement: {}
  },
  reducers: {
    setSelectedClass: (state, action) => {
      state.selectedClass = action.payload
    },
    setSelectedBranch: (state, action) => {
      state.selectedBranch = action.payload
    },
    setSelectedAchievement: (state, action) => {
      state.selectedAchievement = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBranches.fulfilled, (state, action) => {
        state.branches = action.payload.data
      })
      .addCase(getBranchAchievements.fulfilled, (state, action) => {
        state.achievements = action.payload.data
      })
  }
})
export const
  {
    setSelectedClass,
    setSelectedBranch,
    setSelectedAchievement
  }
    = achievementStoreSlice.actions

export default achievementStoreSlice.reducer
