import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import axios from "@axios"

export const getEducationYears = createAsyncThunk(
  "common/getPagedEducationYears",
  async (options) => {
    const response = await axios.post(
      `/common/EducationYear/GetPagedEducationYears`,
      options
    )
    return response
  }
)
export const getEducationYearPeriods = createAsyncThunk(
  "common/getEducationYearPeriods",
  async (options) => {
    const response = await axios.post(
      `/common/EducationYearPeriod/GetPagedEducationYearPeriods`,
      options
    )
    return response
  }
)
export const getPeriodWeeks = createAsyncThunk(
  "common/getPeriodWeeks",
  async (options) => {
    const params = {
      pagingOptions: {
        sortClause: "ASC",
        orderBy: "DisplayOrder",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        ...options.spec
      }
    }
    const response = await axios.post(
      `/common/EducationPeriodWeek/GetPagedEducationPeriodWeeks`,
      params
    )
    return response
  }
)
export const educationYearSlice = createSlice({
  name: "educationYear",
  initialState: {
    educationYears: [],
    educationYearPeriods: [],
    periodWeeks: [],
    selectedPeriod: {},
    periodOptions: {
      pagingOptions: {
        sortClause: "desc",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 5
      },
      spec: {
        educationYearId: null,
        title: null
      }
    },
    periodWeekOptions: {
      pagingOptions: {
        sortClause: "asc",
        orderBy: "displayOrder",
        pageIndex: 0,
        pageSize: 5
      },
      spec: {
        educationPeriodId: null
      }
    }
  },
  reducers: {
    setPeriodOptions: (state, action) => {
      state.periodOptions = action.payload
    },
    setPeriodWeekOptions: (state, action) => {
      state.periodWeekOptions = action.payload
    },
    setSelectedPeriod: (state, action) => {
      state.selectedPeriod = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEducationYears.fulfilled, (state, action) => {

      state.educationYears = action.payload.data
    })
    builder.addCase(getEducationYearPeriods.fulfilled, (state, action) => {

      state.educationYearPeriods = action.payload.data
    })
    builder.addCase(getPeriodWeeks.fulfilled, (state, action) => {

      state.periodWeeks = action.payload.data
    })
  }
})
export const {
  setPeriodOptions,
  setPeriodWeekOptions,
  setSelectedPeriod
} = educationYearSlice.actions

export default educationYearSlice.reducer
