import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import axios from "@axios"
import { autoDownloader } from "../../../../../utility/Utils"

//provinces
export const getProvinces = createAsyncThunk(
  "organization/getProvinces",
  async (params) => {
    const response = await axios.post(
      `/common/Province/GetPagedProvinces`,
      params, {showLoader: true}
    )
    return response
  }
)

export const exportAsExcelProvinces = createAsyncThunk(
  "organization/exportAsExcelProvinces",
  async (spec) => {
    const response = await axios.post(
      `/common/Province/ExportAsExcel`, spec, {responseType: 'blob', showLoader: true})
      .then((response) => autoDownloader(response, "İl_Listesi.xlsx"))
      return response
  }
)

export const exportTemplateExcelProvinces = createAsyncThunk(
  "organization/exportTemplateExcelProvinces",
  async () => {
    const response = await axios.get("/common/Province/ExportTemplateExcel", {
      responseType: 'blob'
    })
      .then((response) => autoDownloader(response, "Ornek_İller_Şablonu.xlsx"))
      return response
  }
)

export const importAsExcelProvinces = createAsyncThunk(
  "organization/importAsExcelProvinces",
  async (model) => {
      const response = await axios.post(`common/Province/ImportExcel`, model, {showToast: true})
      return response
  }
)

//districts

export const getDistricts = createAsyncThunk(
  "organization/getDistricts",
  async (params) => {
    const response = await axios.post(
      `/common/District/GetPagedDistricts`,
      params,
      {showLoader: true}
    )
    return response
  }
)

export const exportAsExcelDistricts = createAsyncThunk(
  "organization/exportAsExcelDistricts",
  async (spec) => {
    const response = await axios.post(
      `/common/District/ExportAsExcel`, spec, {responseType: 'blob', showLoader: true})
      .then((response) => autoDownloader(response, "İlçeler_Listesi.xlsx"))
      return response
  }
)

export const exportTemplateExcelDistricts = createAsyncThunk(
  "organization/exportTemplateExcelDistricts",
  async () => {
    const response = await axios.get("/common/District/ExportTemplateExcel", {
      responseType: 'blob'
    })
      .then((response) => autoDownloader(response, "Ornek_İlçeler_Şablonu.xlsx"))
      return response
  }
)

export const importAsExcelDistricts = createAsyncThunk(
  "organization/importAsExcelDistricts",
  async (model) => {
      const response = await axios.post(`common/District/ImportExcel`, model, {showToast: true})
      return response
  }
)

export const provinceSlice = createSlice({
  name: "provinceStore",
  initialState: {
    provinceOptions: {
      pagingOptions: {
        sortClause: "asc",
        orderBy: "Id",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        title: ""
      }
    },
    districtOptions: {
      pagingOptions: {
        sortClause: "asc",
        orderBy: "Id",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        title: "",
        code: "",
        provinceId: null
      }
    },
    provinces: [],
    selectedProvince : {},
    districts: []
  },
  reducers: {
    setSelectedProvince: (state, action) => {
      state.selectedProvince = action.payload
    },
    setProvinceOptions: (state, action) => {
      state.provinceOptions = action.payload
    },
    setDistrictOptions: (state, action) => {
      state.districtOptions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProvinces.fulfilled, (state, action) => {
        state.provinces = action.payload.data
      })
      .addCase(getDistricts.fulfilled, (state, action) => {
        state.districts = action.payload.data
      })
  }
})
export const {setSelectedProvince, setProvinceOptions} = provinceSlice.actions

export default provinceSlice.reducer
