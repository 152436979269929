// * redux and axios imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "@axios"

export const getPagedVideoLessonPortal = createAsyncThunk("VideoLessonPortal/GetPaged", async (videoLessonPortalOption) => {
  const response = await axios.post(`/videolessons/VideoLessonPortal/GetPaged`, videoLessonPortalOption, {showLoader: true})
  return response
})
export const getDetailVideoLessonPortal = createAsyncThunk("VideoLessonPortal/GetDetail", async (id) => {
  const response = await axios.get(`/videolessons/VideoLessonPortal/GetDetail?id=${id}`, {showLoader: true})
  return response
})
export const getVideoLessonSectionVideo = createAsyncThunk("VideoLessonPortal/GetSectionVideo", async (id) => {
  const response = await axios.get(`/videolessons/SectionVideos/GetSectionVideo?id=${id}`, { showLoader: true})
  return response
})

export const videoLessonPortalSlice = createSlice({
  name: 'videoLessonPortal',
  initialState: {
    // * videoLessonPortal
    videoLessonPortal: {},
    selectedVideoLessonPortal: {},
    videoLessonPortalOption: {
      pagingOptions: { 
        sortClause: "id",
        orderBy: "desc",
        pageIndex: 0,
        pageSize: 9
      },
      spec: {
        title: "",
        description: "",
        isActive: null,
        isPublished: null,
        viewLimit: null,
        publisherId: null
      }
    },
    selectedVideo: {},
    changeVideoNotes: false
  },
  reducers: {
    setVideoLessonPortalOption: (state, action) => {
      state.videoLessonPortalOption = action.payload
    },
    setSelectedVideo: (state, action) => {
      state.selectedVideo = action.payload
    },
    setChangeVideoNotes: (state, action) => {
      state.changeVideoNotes = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPagedVideoLessonPortal.fulfilled, (state, action) => {
        state.videoLessonPortal = action.payload.data
      })
    builder
      .addCase(getDetailVideoLessonPortal.fulfilled, (state, action) => {
        state.selectedVideoLessonPortal = action.payload.data
      })
    builder
      .addCase(getVideoLessonSectionVideo.fulfilled, (state, action) => {
        state.selectedVideo = action.payload.data
      })
  }
})
export const {
  setVideoLessonPortalOption,
  setSelectedVideo,
  setChangeVideoNotes
 } = videoLessonPortalSlice.actions
export default videoLessonPortalSlice.reducer