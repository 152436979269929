import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import axios from '@axios'


export const getCourse = createAsyncThunk("quizManager/getCourse", async (courseId) => {
    const response = await axios.get(`/quizManager/CourseEditor/GetCourseEditorModel`, {params:{ id: courseId}})
    return response
  })

  export const getQuiz = createAsyncThunk(
    "quizManager/getQuiz",
    async (quizId) => {
      const response = await axios.get(
        `/quizManager/QuizEditor/GetQuiz`,
        { params: { id: quizId, includeAchievements:false, includeQuestionCells: true } }
      )
      return response
    })

  export const courseStoreSlice = createSlice({
    name: "courseStore",
    initialState: {
      course: {},
      quiz: {},
      isCropImageActive: true,
      pageImage:"",
      currentPage:1
    },
    reducers: {
      setCourse: (state, action) => {
        state.quiz = {}
        state.course = action.payload.data
      },
      setQuiz: (state, action) => {
        state.course = {}
        state.quiz = action.payload.data
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(getCourse.fulfilled, (state, action) => {
          state.course = action.payload.data
        })
        builder.addCase(getQuiz.fulfilled, (state, action) => {
          state.quiz = action.payload.data
        })
    }
  })
  export const { setCourse, setQuiz, setIsLoading} = courseStoreSlice.actions

  export default courseStoreSlice.reducer
