import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "@axios"

export const getPagedQuestionDistributions = createAsyncThunk('common/getPagedQuestionDistributions', async (options) => {
  const response = await axios.post(`/common/questionDistribution/GetPagedQuestionDistributions`, options)
  return response
})

export const appQuestionDistributionSlice = createSlice({
  name: 'questionDistribution',
  initialState: {
    questionDistributions: {}
  },
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(getPagedQuestionDistributions.fulfilled, (state, action) => {
        state.questionDistributions = action.payload.data
      })
  }
})

export const { } = appQuestionDistributionSlice.actions
export default appQuestionDistributionSlice.reducer