import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@axios'
import { autoDownloader } from "@utils"

export const getReportSelectors = createAsyncThunk('PdfReport/GetPrepareReportModel', 
async () => {
  const res = await axios.get('/examStatistics/PdfReport/GetPrepareReportModel', {showLoader: true})
  return res
})
export const getReportTypes = createAsyncThunk('PdfReport/GetEnumList', 
async (options) => {
  const res = await axios.post('/examStatistics/PdfReport/GetEnumList', options, {showLoader: true})
  return res
})
export const downloadExamReport = createAsyncThunk('PdfReport/DownloadAllExamReport', 
async (options) => {
  await axios.post('/examStatistics/PdfReport/DownloadAllExamReport', 
  options, {showLoader: true})
  .then((link) => {
    // Base64 kodunu ArrayBuffer'a dönüştür
    const byteCharacters = atob(link.data.fileContent)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const pdfBlob = new Blob([byteArray], { type: link.data.contentType })    // Dosyayı indirmek için bir link oluştur
    const obje = {
      data: pdfBlob,
      contentType:link.data.contentType
    }
    autoDownloader(obje, link.data.fileName)
  })
})

export const reportCreSlice = createSlice({
    name: 'reportCratorStore',
    initialState: {
      reportSelectors: [],
      reportTypes: [],
      selectedExamCategory: {}, 
      selectedExam: {},
      selectedReportTypes: [], 
      selectedSchool: {},
      selectedClassRooms: [],
      selectedStudents: [],
      selectedLessons: [],
      selectedOptions: {
        categoryId: null,
        examId: null,
        schoolId: null,
        classRoomIds: [],
        studentIds: [],
        examReportTypes: [],
        lessonReportTypes: [],
        isMerged: false
      }
    },
    reducers: {
      setSelectedExamCategory: (state, action) => {
        state.selectedExamCategory = action.payload
        state.selectedOptions.categoryId = action.payload.categoryId
      },
      setSelectedExam: (state, action) => {
        state.selectedExam = action.payload
        state.selectedOptions.examId = action.payload.examId
      },
      setSelectedReportTypes: (state, action) => {
        state.selectedReportTypes = action.payload
        state.selectedOptions.examReportTypes = action.payload.map(x => x.value)
      },
      setSelectedSchool: (state, action) => {
        state.selectedSchool = action.payload
        state.selectedOptions.schoolId = action.payload.value
      },
      setSelectedClassRooms: (state, action) => {
        state.selectedClassRooms = action.payload
        state.selectedOptions.classRoomIds = action.payload.map(x => x.value)
      },
      setSelectedStudents: (state, action) => {
        state.selectedStudents = action.payload
        state.selectedOptions.studentIds = action.payload.map(x => x.value)
      },
      setSelectedLessons: (state, action) => {
        const temp = action.payload.filter(x => x.lessonIds.length > 0)
        state.selectedLessons = temp
        state.selectedOptions.lessonReportTypes = temp
        const copyReports = JSON.parse(JSON.stringify(state.selectedReportTypes))
        if (temp.length === 0) {
          const updated = copyReports.filter(x => !x.name.includes('Branş'))
          state.selectedReportTypes = updated
          state.selectedOptions.examReportTypes = updated.map(x => x.value)
        }
        action.payload.map(x => {
          if (x.lessonIds.length === 0) {
            const updated = copyReports.filter(y => (y.parentValue !== x.reportType) || !y.name.includes('Branş'))
            state.selectedReportTypes = updated
            state.selectedOptions.examReportTypes = updated.map(x => x.value)
          }
        })
      },
      setSelectedOptions: (state, action) => {
        state.selectedOptions = action.payload
      }
    },
    extraReducers: builder => {
      builder
      .addCase(getReportSelectors.fulfilled, (state, action) => {
        state.reportSelectors = action.payload.data 
      })
      .addCase(getReportTypes.fulfilled, (state, action) => {
        state.reportTypes = action.payload.data
      })
      .addCase(downloadExamReport.fulfilled, (state) => {
        // * clear unused params 
        const copyOptions = JSON.parse(JSON.stringify(state.selectedOptions))
        const repTypes = copyOptions.examReportTypes.map(x => parseInt((x / 10).toFixed(0)))
        if (!repTypes.includes(1)) {
          state.selectedSchool = {}
        } 
        if (!repTypes.includes(2)) {
          state.selectedClassRooms = []
        }
        if (!repTypes.includes(3)) {
          state.selectedStudents = []
        }
      })
    }
})
export const { 
  setSelectedExam,
  setSelectedExamCategory,
  setSelectedReportTypes,
  setSelectedSchool,
  setSelectedClassRooms,
  setSelectedStudents,
  setSelectedLessons,
  setSelectedOptions 
} = reportCreSlice.actions
export default reportCreSlice.reducer