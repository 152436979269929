import axios from "@axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { convertToSelectOption } from "../../../../../../utility/Utils"

export const getPagedQuestionPoolFiles = createAsyncThunk('questionPoolFile/getPagedQuestionPoolFiles', async (options) => {
  const response = await axios.post(`/questionPools/questionPoolFile/getPagedQuestionPoolFiles`, options)
  return response
})

export const getQuestionPoolFile = createAsyncThunk('questionPoolFile/getQuestionPoolFile', async (id) => {
  const response = await axios.get(`/questionPools/questionPoolFile/getQuestionPoolFile/${id}`)
  return response
})

export const getFileTypes = createAsyncThunk('questionPoolFile/getFileTypes', async () => {
  const response = await axios.get(`/questionPools/questionPoolFile/getPoolFileTypes`)
  return response
})

export const appQuestionPoolFilesSlice = createSlice({
  name: 'storage',
  initialState: {
    questionPoolFiles: {},
    selectedQuestionPoolFile: {},
    fileTypes: [],
    options: {
      pagingOptions: {
        sortClause: 'desc',
        orderBy: 'id',
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        questionPoolId: null,
        fileName: '',
        questionPoolFileType: null
      }
    }
  },
  reducers: {
    setOptions: (state, action) => {
      state.options = action.payload
    },
    setSelectedQuestionPoolFile: (state, action) => {
      state.selectedQuestionPoolFile = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPagedQuestionPoolFiles.fulfilled, (state, action) => {
        state.questionPoolFiles = action.payload.data
      })
      .addCase(getQuestionPoolFile.fulfilled, (state, action) => {
        state.selectedQuestionPoolFile = action.payload.data
      })
      .addCase(getFileTypes.fulfilled, (state, action) => {
        state.fileTypes = convertToSelectOption(action.payload.data)
      })
  }
})

export const { setOptions, setSelectedQuestionPoolFile } = appQuestionPoolFilesSlice.actions
export default appQuestionPoolFilesSlice.reducer