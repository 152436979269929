// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from '@axios'

import { autoDownloader } from "@utils"
// ** Immer import
import { setAutoFreeze } from 'immer'
import moment from 'moment'
setAutoFreeze(false)
//* BookQrCode 
export const getPagedBookQrCodes = createAsyncThunk('qrcodes/getPagedBookQrCodes', async (bookQrOptions) => {
  const response = await axios.post(`/bookmanager/BookQRCode/GetPagedBookQrCodes`, bookQrOptions)
  return response
})

export const bulkCreateBookQrCode = createAsyncThunk('qrcodes/bulkCreateBookQrCode', async (data) => {
  const response = await axios.post(`/bookmanager/BookQRCode/CreateBookQRCodes`, data.model, {responseType: "blob", showLoader: true})
    .then((result) => {
      const {id, title} = data.selectedBook
      autoDownloader(result, `${id}-${title}-${moment(Date.now()).format("DD-MM-YYYY HH:mm")}`)
    })
  return response
})

export const downloadQrCode = createAsyncThunk('qrcodes/downloadQrCode', async (data) => {
  const response = await axios.post(`/qrcodes/QRCodeDownload/DownloadQrCode`, data.model, {responseType: "blob", showLoader: true})
  .then((result) => {
    const {qrCodeTypeName, id} = data.selectedQrCode
    autoDownloader(result, `${id}-${qrCodeTypeName}-${moment(Date.now()).format("DD-MM-YYYY HH:mm")}`)
  })
  return response
})
//* QrCode Crud
export const deleteQrCode = createAsyncThunk('qrcodes/deleteQrCode', async (id) => {
  await axios.delete(`/qrcodes/QRCode/Delete/${id}`, {showToast : true})
  return id
})
//* Enum Class
export const getQrCodeType = createAsyncThunk('bookmanager/getQrCodeType', async () => {
  const response = await axios.get(`/qrcodes/QRCode/GetQrCodeType`)
  return response
})

export const getQrCodeDownType = createAsyncThunk('qrcodes/getQrCodeDownType', async () => {
  const response = await axios.get(`/qrcodes/QRCode/GetQrDownloadType`)
  return response
})

export const getQrCodeDownFileFormat = createAsyncThunk('qrcodes/getQrCodeDownFileFormat', async () => {
  const response = await axios.get(`/qrcodes/QRCode/GetQrAllDownType`)
  return response
})
export const getBook = createAsyncThunk("bookManager/getBook", async (bookId) => {
  const response = await axios.get(`/bookManager/books/getBook/${bookId}`)
  return response
})
export const appBookQrCodesSlice = createSlice({
  name: "bookQrCodes",
  initialState: {
    bookQrData: {},
    selectedBookQrCode: {},
    selectedBook: {},
    getQrType: [],
    getQrDownType: [],
    getQrDownFileFormat: [],
    bookQrOptions: {
      pagingOptions: {
        sortClause: "desc",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        qrCodeString: "",
        fromUrl: "",
        redirectUrl: "",
        type: null,
        entityId: null,
        parentId: null,
        isActive: null,
        displayOrder: 0
      }
    }
  },
  reducers: {
    setBookQrCodeOptions: (state, action) => {
      state.bookQrOptions = action.payload
    },
    setSelectedBookQrCode: (state, action) => {
      state.selectedBookQrCode = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPagedBookQrCodes.fulfilled, (state, action) => {
        state.bookQrData = action.payload.data
      })
      .addCase(getQrCodeType.fulfilled, (state, action) => {
        state.getQrType = action.payload.data
      })
      .addCase(getQrCodeDownType.fulfilled, (state, action) => {
        state.getQrDownType = action.payload.data
      })
      .addCase(getQrCodeDownFileFormat.fulfilled, (state, action) => {
        state.getQrDownFileFormat = action.payload.data
      })
      .addCase(getBook.fulfilled, (state, action) => {
        state.selectedBook = action.payload.data
      })
  }
})
export const { setBookQrCodeOptions, setSelectedBookQrCode } = appBookQrCodesSlice.actions
export default appBookQrCodesSlice.reducer