// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from '@axios'

export const getPagedVideos = createAsyncThunk("VideoItem/GetPagedVideos", async (videoItemOptions) => {
  const response = await axios.post('/videolessons/VideoItem/GetPagedVideos', videoItemOptions)
  return response
})
export const createVideoItem = createAsyncThunk("VideoItem/Create", async (model) => {
  const response = await axios.post('/videolessons/VideoItem/Create', model, {showToast : true})
  return response 
})
export const updateVideoItem = createAsyncThunk("VideoItem/Update", async (model) => {
  const response = await axios.put('/videolessons/VideoItem/Update', model, {showToast : true})
  return response
})
export const deleteVideoItem = createAsyncThunk("VideoItem/Delete", async (id) => {
  await axios.delete(`/videolessons/VideoItem/Delete/${id}`, {showToast : true})
  return id
})
// ! readExcel ve importExcel kullanılmıyor
export const readExcel = createAsyncThunk("VideoItem/ReadExcel", async (formFile) => {
  await axios.post('/videolessons/VideoItem/ReadExcel', formFile)
})
export const importExcel = createAsyncThunk("VideoItem/ImportExcel", async (formFile) => {
  await axios.post('/videolessons/VideoItem/ImportExcel', formFile)
})

export const appVideoItemSlice = createSlice({
  name: "videoItem",
  initialState: {
    videoItem: [],
    selectedVideoItem: {},
    videoItemOptions: {
      pagingOptions: {
        sortClause: "desc",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 10
      },
      spec: {
        title: "",
        isActive: null,
        viewCount: null,
        instructorId: null,
        publisherId: null,
        lessonId: null
      }
    }
  },
  reducers: {
    setSelectedVideoItem: (state, action) => {
      state.selectedVideoItem = action.payload
    },
    setVideoItemOptions: (state, action) => {
      state.videoItemOptions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getPagedVideos.fulfilled, (state, action) => {
      state.videoItem = action.payload.data
    })
  }
})
export const {
  setSelectedVideoItem,
  setVideoItemOptions
 } = appVideoItemSlice.actions
export default appVideoItemSlice.reducer