// ** Redux Imports
import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "@axios"
export const getUserClassRoomLessons = createAsyncThunk(
  "appCurriculum/GetUserClassRoomLessons",
  async (params) => {
    
    const response = await axios.post(
      "/organisation/ClassRoom/GetUserClassRoomLessons",
      params
    )
    return {
      params,
      data: response.data
    }
  }
)
export const getUserClassRooms = createAsyncThunk(
  "appCurriculum/GetUserClassRooms",
  async () => {
    const response = await axios.get(
      "/organisation/ClassRoom/GetUserClassRooms"
    )
    return {
      data: response.data
    }
  }
)
export const getCurriculumWithLessons = createAsyncThunk(
  "appCurriculum/getCurriculumWithLessons",
  async (params) => {
    
    const response = await axios.post(
      "/Curriculum/ClassRoomCurriculum/getCurriculumWithLessons",
      params,
      {showLoader: true}
    )
    return response
  }
)
export const appEmailSlice = createSlice({
  name: "appCurriculum",
  initialState: {
    lessons: [],
    userClassRooms: [],
    weeks: [],
    selectedWeek: {},
    params: {},
    isTeacher: false,
    curriculumOptions:{
      lessonIds:[],
      classRoomIds:[]
    }
  },
  reducers: {
    setSelectedWeek: (state, action) => {
      state.selectedWeek = action.payload
    },
    updateWeekSubject: (state, action) => {    
      state.selectedWeek = action.payload
    },   
    updateWeekLectureDates: (state, action) => {
      const week = state.weeks.find(x => x.id === current(state.selectedWeek).id)
      week.lectures = action.payload
      state.selectedWeek = week
    },
    setCurriculumOptions: (state, action) => {
      state.curriculumOptions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurriculumWithLessons.fulfilled, (state, action) => {
        state.lessons = action.payload.data.classRoomLessons
        state.weeks = action.payload.data.curriculums     
      })
      .addCase(getUserClassRooms.fulfilled, (state, action) => {      
        state.userClassRooms = action.payload.data.classRooms
        state.isTeacher = action.payload.data.isTeacher
      })
  }
})

export const {
  setSelectedWeek,
  updateWeekSubject,
  updateWeekLectureDates,
  resetSelectedMail,
  setCurriculumOptions
} = appEmailSlice.actions

export default appEmailSlice.reducer
