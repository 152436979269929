const environment = {
  name: "production",
  OpenIdConnect: {
    Authority: "https://api.dijitaldeyim.com/",
    ClientId: "Obro.React"
  },
  ResourceServer: {
    Endpoint: "https://api.dijitaldeyim.com/api/v1/",
    NotificationEndpoint: "https://api.dijitaldeyim.com/hubs/notification",
    MessageChannel: "https://api.dijitaldeyim.com/hubs/chat",
    ProgressBarEndpoint: "https://api.dijitaldeyim.com/hubs/progressbar"

  },
  CurrentUrl: "https://api.dijitaldeyim.com/"
}
export default environment